<template>
  <div :id="`accordion-${id}`" class="mb-2">
    <div v-for="index in headings.length" :key="index" class="accordion">
      <div class="accordion-item" v-if="hidden.includes(_getHeadingDisplay(index)) == false">
        <div class="d-flex">
          <slot name="pre-heading" :heading="_getHeadingDisplay(index)" />
          <h2 :id="`heading-${index}`" class="accordion-header w-100" role="tab">
            <button
              :class="`btn btn-${color} w-100`"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#collapse-${id}-${index}`"
              aria-expanded="true"
              :aria-controls="`collapse-${id}-${index}`"
              @click.stop="clicked(index)">
              <slot name="heading" :heading="_getHeadingDisplay(index)">
                {{ _getHeadingDisplay(index) }}
              </slot>
            </button>
          </h2>
          <slot name="post-heading" :heading="_getHeadingDisplay(index)" />
        </div>
        <div
          :id="`collapse-${id}-${index}`"
          class="accordion-collapse collapse"
          :class="{ show: index === openIndex }"
          :aria-labelledby="`heading-${index}`"
          :data-bs-parent="`#accordion-${id}`">
          <div class="accordion-body py-3">
            <slot name="body" :heading="_getHeadingDisplay(index)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'BAccordion',
  props: {
    //The parent id of the accordion
    id: { type: String, required: true },
    //The headings for the accordion
    headings: { type: Array, default: () => [] },
    //Accordion names to hide. Can be removed or added dynamically
    hidden: { type: Array, default: () => [] },
    //Set to 0 to start with all closed
    openIndex: { type: Number, default: 1 },
    color: { type: String, default: 'info' },
  },
  setup(props, { emit }) {
    //Internal function
    const _getHeadingDisplay = (index) => props.headings[index - 1];

    //Event emitter for accordion click
    const clicked = (index) => {
      const heading = _getHeadingDisplay(index);
      emit('clicked', heading);
      emit(`clicked-${index}`, heading);
      emit(`clicked-index`, index);
      console.log('Clicked ', heading, ' accordion.');
    };

    //Utility function for dynamic accordion toggling.
    const click = (index) => {
      const element = document.getElementById(`collapse-${index}`);
      element.classList.toggle('show');
      clicked(index);
    };

    return { _getHeadingDisplay, clicked, click };
  },
};
</script>

<style scoped>
.accordion {
  --bs-accordion-bg: transparent;
  --bs-accordion-border-color: transparent;
  padding: 0px;
}
.accordion-body {
  padding: 0px;
}
.accordion .accordion-item .accordion-header .btn.btn-info {
  margin-bottom: 0px;
  margin-top: 0px;
  --bs-accordion-button-padding-y: 0rem;
}
</style>
