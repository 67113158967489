<template>
  <div id="full-page">
    <div id="activities-page" :class="getLayoutClass">
      <div id="header-container">
        <div id="header-image" data-id="headerDrawable" class="editable-item" :style="getHeaderImage" @click="updateItem('headerDrawable')" />
        <div id="header-text" data-id="headerText" :class="getHeadertextClass" @click="updateItem('headerText')">
          {{ printoutData.headerText }}
        </div>
      </div>
      <div id="main-columns">
        <div id="date-columns" :class="getGridColsClass">
          <div class="day-column" v-for="(day, dayKey, dayIndex) in colEvents" :key="dayKey">
            <div v-for="(event, index) in day" :key="index" class="daily-event">
              <div class="date-display" v-show="index == 0 || event.startDay !== day[index - 1].startDay" :style="getDateDisplayStyle">
                {{ event.startDay }}
                <div v-if="continuedText(dayKey, dayIndex, index)">(cont.)</div>
                <hr class="date-hr" :style="getDateHRStyle" />
              </div>
              <div class="event-detail">
                <hr v-show="index != 0 && event.startDay == day[index - 1].startDay && index !== day.length" :style="getEventHRStyle()" />
                <div class="event-header" :style="{ fontSize: headerFontSize + 'px' }">
                  <div class="event-start-date">{{ event.startDate }}</div>
                  <div class="event-title">
                    <i v-if="event.rsvp" class="item-cost material-icons">add_person</i>
                    <i v-if="event.cost" class="item-cost material-icons">attach_money</i>
                    <img v-if="event.character" class="item-character" :src="characterDrawable" />
                    {{ event.title }}
                  </div>
                  <div class="event-location">
                    @{{ locations[event.resortLocationId] ? locations[event.resortLocationId].title : event.location }}
                  </div>
                </div>
                <div v-if="displayDescriptions" class="event-description" v-html="event.description" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="!hideRightColumn && (totalColumns < 7 || calendarType == 'agenda')" id="activities-right-column">
          <div
            id="right-column-title"
            data-id="rightColumnTitle"
            class="editable-item"
            @click="updateItem('rightColumnTitle')"
            :style="getDateDisplayStyle">
            {{ printoutData.rightColumnTitle }}
          </div>
          <hr :style="getDateHRStyle" />
          <div
            id="right-column-content"
            data-id="rightColumnContent"
            class="editable-item"
            v-html="printoutData.rightColumnContent"
            @click="updateItem('rightColumnContent')" />
          <div id="item-key">
            <ul>
              <li v-if="hasRSVP">
                <i class="item-cost material-icons">person_add</i>
                RSVP Requested
              </li>
              <li v-if="hasCost">
                <i class="item-cost material-icons">attach_money</i>
                Additional Cost
              </li>
              <li v-if="hasCharacter">
                <img class="item-character" :src="characterDrawable" />
                Character Sighting
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="section-row">
        <div id="app-section">
          Don't miss any of your favorite activities, download our app
          <br />
          "{{ getAppName }}"
          <br />
          <img src="https://play.google.com/intl/en_us/badges/images/badge_new.png" />
          <br />
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1617580800&h=162d5a71490a3e8a731aa2c6c51c2cad" />
        </div>
        <div
          id="left-section"
          data-id="leftSectionContent"
          class="editable-item"
          v-html="printoutData.leftSectionContent"
          @click="updateItem('leftSectionContent')" />
        <div
          id="mid-section"
          data-id="midSectionContent"
          class="editable-item"
          v-html="printoutData.midSectionContent"
          @click="updateItem('midSectionContent')" />
        <div
          id="right-section"
          data-id="rightSectionContent"
          class="editable-item"
          v-html="printoutData.rightSectionContent"
          @click="updateItem('rightSectionContent')" />
      </div>
      <div id="footer" data-id="footerContent" class="editable-item" v-html="printoutData.footerContent" @click="updateItem('footerContent')" />
    </div>
    <div id="right-column" class="p-2">
      <div class="p-2 form-container">
        <form novalidate>
          <template v-if="rightColumnComponent == null">
            <div class="p-1 w-100" role="tab">
              <button type="button" class="btn btn-info w-100" data-bs-toggle="collapse" data-bs-target="#accordion-1">Required Information</button>
            </div>
            <div class="collapse show" id="accordion-1" data-bs-parent="#my-accordion" role="tabpanel">
              <div class="row">
                <div class="col">
                  <label class="form-label">Calendar</label>
                  <select v-model="calendarId" class="form-select">
                    <option v-for="context in contexts" :key="context.key" :value="context.value">{{ context.key }}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label class="form-label">Start Date</label>
                    <input type="date" class="form-control" v-model="formattedStartDate" />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label class="form-label">End Date</label>
                    <input type="date" class="form-control" v-model="formattedEndDate" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label class="form-label">Calendar Type</label>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="calendarType" name="calendar-type" value="daily" id="daily" />
                      <label class="form-check-label" for="daily">Daily</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="calendarType" name="calendar-type" value="agenda" id="agenda" />
                      <label class="form-check-label" for="agenda">Agenda</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="calendarType" name="calendar-type" value="column" id="column" />
                      <label class="form-check-label" for="column">New Column as Needed</label>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label class="form-label">Uncheck this if your events don't fit to a single page</label>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="singlePage" id="singlePage" />
                      <label class="form-check-label" for="singlePage">Limit to One Page</label>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Hide the right column to allow for more events to display</label>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="hideRightColumn" id="hideRightColumn" />
                      <label class="form-check-label" for="hideRightColumn">Hide Right Column</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="calendarType == 'agenda'">
                <div class="col">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="displayDescriptions" id="displayDescriptions" />
                    <label class="form-check-label" for="displayDescriptions"> Display Descriptions </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label class="form-label">Event Font Size</label>
                    <select class="form-select" v-model="headerFontSize">
                      <option v-for="size in [8, 9, 10, 11, 12, 13, 14, 15, 16]" :key="size" :value="size">{{ size }} px</option>
                    </select>
                  </div>
                </div>
                <div class="col" />
                <div class="col" />
                <div class="col" />
              </div>
              <div class="row" v-if="calendarType == 'column'">
                <div class="col">
                  <div class="mb-3">
                    <label class="form-label">Events/Column</label>
                    <input class="form-control" v-model="eventsPerCol" type="number" />
                  </div>
                </div>
              </div>
            </div>
            <button class="btn m-1 btn-primary" type="submit" @click.prevent="printPage">Print</button>
            <button class="btn m-1 btn-secondary" type="button" @click.prevent="updateTemplate">Save Template</button>
            <button class="btn m-1 btn-secondary" type="button" @click.prevent="cancelPrintout">Exit</button>
          </template>
          <component v-else :is="rightColumnComponent" v-bind="rightColumnProps" @updatePrintout="updatePrintout"></component>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import HeaderImage from './printout/HeaderImage.vue';
import PrintoutText from './printout/PrintoutText.vue';
import PrintoutWYSIWYG from './printout/PrintoutWYSIWYG.vue';

export default {
  name: 'ActivitiesPrintout',
  components: {
    HeaderImage,
    PrintoutText,
    PrintoutWYSIWYG,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      formattedStartDate: null,
      formattedEndDate: null,
      calendarType: 'daily',
      displayDescriptions: true,
      calendarId: '',
      locations: {},
      allEvents: {},
      colEvents: {},
      contexts: [],
      totalColumns: 3,
      numberOfCols: 1,
      eventsPerCol: 12,
      rightColumnComponent: null,
      characterDrawable: '',
      singlePage: true,
      hideRightColumn: false,
      printoutData: {
        footerContent: 'The footer will go here',
        headerDrawable: '',
        headerScrim: true,
        scrimFade: true,
        leftSectionContent: 'Add Content Here',
        midSectionContent: 'Add Content Here',
        rightColumnContent: '<p>Long right hand column</p><br><p>Scroll below for more sections</p>',
        rightColumnTitle: 'Right Column Title',
        rightSectionContent: 'Add Content Here',
        headerText: 'Title Text',
        usingNewQuill: false,
      },
      dbContext: 'event-printout',
      headerFontSize: 12,
    };
  },
  computed: {
    hasRSVP: function () {
      return Object.keys(this.allEvents).some((e) => this.allEvents[e].rsvp);
    },
    hasCost: function () {
      return Object.keys(this.allEvents).some((e) => this.allEvents[e].cost);
    },
    hasCharacter: function () {
      return Object.keys(this.allEvents).some((e) => this.allEvents[e].character);
    },
    getHeaderImage: function () {
      return `background-image: url('${this.printoutData.headerDrawable}');`;
    },
    getHeadertextClass: function () {
      const textClasses = [];
      textClasses.push('editable-item');
      if (this.printoutData.headerScrim) {
        textClasses.push('header-scrim');
      }
      if (this.printoutData.scrimFade) {
        textClasses.push('scrim-fade');
      }
      return textClasses;
    },
    getDateDisplayStyle: function () {
      const color = this.calendarType == 'column' ? this.getPrimaryColor : this.getAccentColor;
      return `color: ${color};`;
    },
    getDateHRStyle: function () {
      const color = this.calendarType == 'column' ? this.getPrimaryColor : this.getAccentColor;
      return `color: ${color}; background-color: ${color}`;
    },
    getLayoutClass: function () {
      return `${this.calendarType}-layout`;
    },
    getGridColsClass: function () {
      const columnClasses = [];
      if (this.totalColumns > 6 || this.hideRightColumn) columnClasses.push('no-right-col');
      if (!this.singlePage) columnClasses.push('multi-page');

      return columnClasses;
    },
  },
  watch: {
    formattedStartDate: function () {
      const currentDuration = moment.duration(this.endDate.diff(this.startDate)).get('days') - 1;

      this.startDate = moment.tz(this.formattedStartDate, 'YYYY-MM-DD', this.getTimeZone).startOf('day');
      if (this.startDate > this.endDate && currentDuration > 0) {
        this.endDate = this.startDate.clone().add(currentDuration, 'days');
        this.formattedEndDate = this.endDate.format('YYYY-MM-DD');
      }
      this.getEvents();
    },
    formattedEndDate: function () {
      this.endDate = moment.tz(this.formattedEndDate, 'YYYY-MM-DD', this.getTimeZone).add(1, 'days');
      this.getEvents();
    },
    calendarId: function () {
      this.getEvents();
    },
    calendarType: function () {
      this.setColEvents();
    },
    eventsPerCol: function () {
      this.numberOfCols = Math.ceil(Object.keys(this.allEvents).length / this.eventsPerCol);
      this.setColEvents();
    },
  },
  created: async function () {
    this.startDate = moment.tz(this.getTimeZone).startOf('day').add(1, 'days');
    this.endDate = this.startDate.clone().add(2, 'days').endOf('day');
    this.formattedStartDate = this.startDate.format('YYYY-MM-DD');
    this.formattedEndDate = moment.tz(this.endDate, this.getTimeZone).format('YYYY-MM-DD');

    const printoutSS = await this.getContextRef().once('value');
    this.printoutData = printoutSS.val() || this.printoutData;
    if (!this.printoutData.usingNewQuill) this.convertTags();

    const locationsSS = await this.getLocationRef().child('locations').once('value');
    this.locations = locationsSS.val() || {};
    const promises = [];
    promises.push(this.getContextRef('events-resort').once('value'));
    promises.push(this.getContextRef('events-local').once('value'));
    promises.push(this.getContextRef('amc-events').child('config').orderByChild('title').once('value'));
    Promise.all(promises).then((snapshots) => {
      const [eventsResortSnap, eventsLocalSnap, amcEventsSnap] = snapshots;
      if (amcEventsSnap.hasChildren()) {
        amcEventsSnap.forEach((childSnap) => {
          this.contexts.push({ key: childSnap.val().title, value: childSnap.key });
        });
      }
      const legacyEventsDescriptor = this.contexts.length === 0 ? 'Activities' : 'Legacy Events';

      if (eventsResortSnap.hasChildren()) {
        this.contexts.push({ key: `${legacyEventsDescriptor} Tab 1`, value: 'events-resort' });
      }
      if (eventsLocalSnap.hasChildren()) {
        this.contexts.push({ key: `${legacyEventsDescriptor} Tab 2`, value: 'events-local' });
      }
    });
    this.calendarId = this.$route.params.calendarId || '';
    if (this.calendarId) this.getEvents();
  },
  methods: {
    getEvents: async function () {
      const legacyEvents = ['events-resort', 'events-local'].includes(this.calendarId);
      const fbPath = legacyEvents ? this.calendarId.replace('.', '/') : `amc-events/calendars/${this.calendarId.replace('.', '/')}/events`;
      let eventsRef = this.getLocationRef().child(fbPath);
      if (this.calendarId.startsWith('events-')) {
        eventsRef = eventsRef.orderByKey().startAt(String(this.startDate.valueOf())).endAt(String(this.endDate.valueOf()));
      } else {
        eventsRef = eventsRef.orderByChild('startDate').startAt(this.startDate.valueOf()).endAt(this.endDate.valueOf());
      }
      const eventsSS = await eventsRef.once('value');
      this.allEvents = eventsSS.val() || {};
      const numEvents = Object.keys(this.allEvents).length;
      this.numberOfCols = Math.ceil(numEvents / 12) > 3 ? Math.ceil(numEvents / 10) : Math.ceil(numEvents / 12);
      this.eventsPerCol = Math.ceil(numEvents / this.numberOfCols);
      this.setColEvents();
    },
    setColEvents: function () {
      const allEventKeys = Object.keys(this.allEvents).sort((a, b) => this.allEvents[a].startDate - this.allEvents[b].startDate);
      this.colEvents = allEventKeys.reduce((accumulator, eventId, index) => {
        const event = this.allEvents[eventId];
        if (this.calendarId.startsWith('events-')) event.startDate = eventId;
        const eventDate = moment.tz(parseInt(event.startDate), this.getTimeZone);
        const day = eventDate.format('dddd M/D');
        const time = eventDate.minutes() != 0 ? eventDate.format('h:mma') : eventDate.format('ha');
        const accIndex = this.calendarType == 'column' ? Math.floor(index / this.eventsPerCol) : day;
        if (!accumulator[accIndex]) {
          accumulator[accIndex] = [];
        }
        const newEvent = { ...event, ...{ startDate: time, startDay: day } };
        accumulator[accIndex].push(newEvent);
        return accumulator;
      }, {});
      this.totalColumns = Object.keys(this.colEvents).length;

      return;
    },
    getEventHRStyle: function () {
      return `background-color: ${this.getAccentColor}; width: 60%;`;
    },
    continuedText: function (dayKey, dayIndex, index) {
      if (dayIndex == 0 || this.colEvents[dayIndex - 1] == undefined) {
        return false;
      }
      const prevKey = Object.keys(this.colEvents)[dayIndex - 1];
      const prevIndex = Object.keys(this.colEvents[prevKey]).length - 1;
      if (this.colEvents[dayKey][index].startDay == this.colEvents[prevKey][prevIndex].startDay) {
        return true;
      }
      return false;
    },
    updateItem: function (itemName) {
      this.rightColumnProps = {
        name: itemName,
        value: this.printoutData[itemName],
      };
      switch (itemName) {
        case 'headerText':
          this.rightColumnProps.headerScrim = this.printoutData.headerScrim;
          this.rightColumnProps.scrimFade = this.printoutData.scrimFade;
          this.rightColumnComponent = 'PrintoutText';
          break;
        case 'rightColumnTitle':
          this.rightColumnComponent = 'PrintoutText';
          break;
        case 'headerDrawable':
          this.rightColumnComponent = 'HeaderImage';
          break;
        default:
          this.rightColumnComponent = 'PrintoutWYSIWYG';
          break;
      }
    },
    updatePrintout: function (item, continueUpdate) {
      this.printoutData[item.name] = item.value;
      if (!continueUpdate) {
        this.rightColumnComponent = null;
      }
    },
    printPage: function () {
      window.print();
    },
    cancelPrintout: function () {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        window.close();
      }
    },
    updateTemplate: function () {
      this.printoutData.usingNewQuill = true;
      this.getContextRef().update(this.printoutData);
    },
    convertTags() {
      const convert = (text) => {
        return text
          .replace(/<div><br><\/div>/g, '<br>')
          .replace(/<div>/g, '<p>')
          .replace(/<\/div>/g, '</p>');
      };

      this.printoutData.leftSectionContent = convert(this.printoutData.leftSectionContent);
      this.printoutData.midSectionContent = convert(this.printoutData.midSectionContent);
      this.printoutData.rightSectionContent = convert(this.printoutData.rightSectionContent);
      this.printoutData.rightColumnContent = convert(this.printoutData.rightColumnContent);
      this.printoutData.footerContent = convert(this.printoutData.footerContent);

      this.printoutData.usingNewQuill = true;
    },
  },
};
</script>

<style lang="scss">
@media print {
  @page {
    size: 8.5in 11in;
    margin: 0;
  }
  body {
    margin: 0.5cm;
    width: 8in !important;
    min-width: 8in !important;
  }
  iframe,
  #right-column {
    display: none;
  }
  #full-page {
    grid-template-columns: 8in;
  }
}

#full-page {
  font: 14px 'Lucida Grande', Helvetica, Arial, sans-serif;
  display: grid;
  grid-template-columns: 8in 1fr;
}
div.day-column,
div#activities-right-column,
#section-row > div {
  vertical-align: top;
}

#section-row > div {
  margin-top: 0.05in;
  display: inline-block;
  width: 2in;
  height: 1.5in;
}
#section-row > div ul {
  margin: 0px;
  text-align: left;
  -webkit-padding-start: 20px;
}
#section-row > div ul > li {
  margin-bottom: 6px;
}
#section-row {
  border-top: 1px solid;
}
.date-columns,
div#activities-right-column {
  height: 7in;
  max-height: 7in;
  overflow: hidden;
}
div#activities-right-column {
  width: 2.5in;
}
div#date-columns {
  width: 5.5in;
  border-right: 1px solid black;
}
div#date-columns.no-right-col {
  border-right: none;
  width: 8in;
}

.daily-layout div#date-columns,
.column-layout div#date-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
}
div.daily-layout .day-column,
div.column-layout .day-column {
  border-left: 1px solid black;
  height: 7in;
}
div.daily-layout .multi-page .day-column,
div.column-layout .multi-page .day-column {
  border-left: 1px solid black;
  height: initial;
}
div.daily-layout .day-column:first-of-type,
div.column-layout .day-column:first-of-type {
  border-left: none;
}

#activities-page {
  text-align: center;
  width: 8in;
  margin: auto;
}
#activities-page.agenda-layout div#date-columns {
  text-align: left;
}
#activities-page.daily-layout .event-description,
#activities-page.column-layout .event-description,
#activities-page .event-description.description-hide {
  display: none;
}
.event-detail {
  margin: 0.1in;
  font-size: 12px;
}
#activities-page .daily-event .event-header {
  display: flex; /* establish flex container */
  flex-wrap: wrap; /* default value; can be omitted */
  justify-content: center;
}
#activities-page.agenda-layout .daily-event .event-header {
  display: flow-root;
  font-weight: bold;
}
#activities-page.agenda-layout .daily-event .event-header div.event-start-date {
  width: 0.75in;
}
#activities-page.agenda-layout .daily-event .event-header div {
  float: left;
  text-align: left;
}
#activities-page.agenda-layout .daily-event .event-header div.event-location {
  float: right;
}
#activities-page.daily-layout .daily-event .event-header div.event-title,
#activities-page.column-layout .daily-event .event-header div.event-title {
  order: 3;
  flex: 0 0 100%;
}
#activities-page.daily-layout .daily-event .event-header div.event-start-date,
#activities-page.column-layout .daily-event .event-header div.event-start-date {
  order: 1;
  margin-right: 4px;
  /*
    border-right: 1px solid black;
    padding-right: 4px;
    */
}
#activities-page.daily-layout .daily-event .event-header div.event-location,
#activities-page.column-layout .daily-event .event-header div.event-location {
  order: 2;
}
#right-column .form-container {
  position: sticky;
  top: 62px;
}
.date-display,
#right-column-title {
  font-weight: bold;
  font-size: 14px;
}
.date-display > hr,
#right-column-title + hr {
  width: 80%;
  margin-top: 0px;
}
#right-column-content {
  height: 6in;
}
#right-column-content p,
#right-section p,
#mid-section p,
#left-section p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.day-column .daily-event:not(:first-child) .date-display {
  padding-top: 40px;
  border-top: #000000 1px solid;
}
#footer {
  height: 0.7in;
}
.event-title {
  font-weight: bold;
}
#header-container {
  height: 1.25in;
  width: 100%;
  position: relative;
}
#header-image {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
}
#header-image {
  height: 1.25in;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-color: rgba(255, 255, 255, 1);
  -webkit-background-size: cover;
  display: inline-flex;
}
#header-text {
  margin: auto;

  color: white;
  font-weight: bold;
  padding: 5px 10px;
  font-size: 30px;
  z-index: 15;
  position: relative;
  display: inline-block;
  top: 0.4in;
}
#header-text.header-scrim {
  background-color: #00000077;
}
#main-columns {
  display: flex;
  justify-content: space-between;
  margin: 0.05in auto auto auto;
}
div.agenda-layout div#date-columns hr:first-of-type {
  width: 100%;
}
div.agenda-layout div#date-columns hr {
  width: 60%;
}
#header-image,
#main-columns,
#section-row {
  width: 100%;
}

#app-section img {
  margin-top: 5px;
  height: 40px;
  width: 135px;
}
hr {
  width: 30%;
  height: 2px;
  border: none;
  margin: 8px auto;
  color: black;
  background-color: black;
}
input,
button {
  margin-bottom: 10px;
}

button.ui-button.ui-corner-all.ui-widget {
  margin-right: 10px;
  float: left;
  font-size: 12px;
}

.editable-item:hover {
  background-color: #fccc9355;
  background-blend-mode: multiply;
  cursor: pointer;
}
#header-image.editable-item:hover {
  background-color: #fccc93;
}
#header-text.editable-item:hover {
  background-color: #fccc93aa;
}
fieldset {
  padding: 0;
  border: 0;
  margin-top: 10px;
}
#ui-datepicker-div {
  z-index: 1000 !important;
}
#description {
  width: 100%;
}
#editor-special-container,
#editor-description-container {
  height: 340px;
}
.ui-dialog .ui-dialog-content {
  padding: 0em 1em;
}
#dropbox {
  width: 454px;
  height: 74px;
  border: 5px dashed #1da9f3;
  background-color: #18344930;
  margin-bottom: 10px;
}
#imageprogress {
  position: relative;
  top: 100px;
  text-align: center;
  margin: 8px;
  display: none;
  background-color: #1da9f3;
  height: 2.55em;
}
#imageprogress .bar {
  height: 2.55em;
  position: absolute;
  top: 0px;
  background-color: #183449;
}
#imageprogress .uploading-text {
  position: absolute;
  top: 0px;
  text-align: center;
  width: 100%;
}
.image-holder .editableform input[type='text'] {
  width: 100%;
  font-size: x-small;
}
.image-holder .editable {
  font-weight: normal;
}
div#preview {
  text-align: center;
}
.image-holder {
  display: none;
}
div#preview .image-holder.reservation-event-holder,
div#preview .image-holder.design-holder,
div#preview .image-holder.reservation-holder {
  position: relative;
  width: 130px;
  border: solid 1px #c0c0c0;
}
div#preview .image-holder.reservation-event-holder,
div#preview .image-holder.design-holder {
  min-height: 175px;
}

div#preview .image-holder {
  display: inline-block;
  text-align: center;
  margin: 2px;
  padding: 2px;
  vertical-align: top;
}

#dropbox.pintable-dropbox img.previewimage {
  max-height: 70px;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.image-holder.reservation-event-holder .image-div,
.image-holder.reservation-holder .image-div {
  width: 125px;
  height: 125px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.ql-size-small {
  font-size: 12px;
}

.ql-size-large {
  font-size: 18px;
}

.item-character {
  /*filter: contrast(0%) brightness(0%);*/
  height: 12px;
  margin-right: 4px;
}
.material-icons.item-cost {
  font-size: inherit;
  font-weight: bolder;
  margin-right: 4px;
}
#item-key {
  text-align: left;
}

#item-key ul {
  padding-left: 10px;
}
#item-key li {
  display: block;
}

#header-text.header-scrim {
  background-color: #00000077;
}
#header-text.header-scrim.scrim-fade {
  -moz-box-shadow: 0px 0px 7px #000000;
  -webkit-box-shadow: 0px 0px 7px #000000;
  box-shadow: 0px 0px 7px #000000;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
</style>
