<template>
  <div class="two-column">
    <div id="center-column">
      <div v-if="tabs" id="tabs">
        <div
          v-for="(tab, index) in tabs"
          v-bind:key="index"
          :class="{ tab: true, selected: index == tabIndex }"
          :style="getTabStyle(index == tabIndex)"
          @click="setIndex(index)">
          {{ tab.title }}
        </div>
      </div>
      <div v-if="['rewards-users', 'users', 'usermgmt'].includes(displayType)">
        <input class="form-control m-2 user-filter" placeholder="Filter Users" v-model="userFilter" type="search" />
      </div>
      <div v-else-if="'switch-community' == context">
        <div class="row">
          <div class="col-8">
            <input class="form-control m-2 user-filter" placeholder="Filter Communities" v-model="communityFilter" type="search" />
          </div>
          <div v-if="$fbProject !== 'studio'">
            <div class="form-check mt-3">
              <input class="form-check-input" type="checkbox" id="fullApps" v-model="fullApps" />
              <label class="form-check-label" for="fullApps">Full Apps Only</label>
            </div>
            <div class="form-check mt-3">
              <input class="form-check-input" type="checkbox" id="listedCommunities" v-model="listedCommunities" />
              <label class="form-check-label" for="listedCommunities">Only Listed</label>
            </div>
          </div>
        </div>
      </div>
      <div id="item-header" :style="getHeaderStyles()">
        <iframe
          v-if="headerInfo.videoURL"
          width="490"
          height="261"
          :src="`https://www.youtube.com/embed/${headerInfo.videoURL}`"
          title="YouTube video player"
          frameborder="0"
          allow="mute; autoplay; allowfullscreen"></iframe>
        <div
          id="listBuilderCarousel"
          class="carousel slide"
          data-bs-ride="carousel"
          v-if="this.headerInfo.images && this.headerInfo.images.length > 0">
          <div class="carousel-inner">
            <div class="carousel-item" v-for="(src, index) in this.headerInfo.images" :class="{ active: index === 0 }" :key="index">
              <img :src="src.imageURL" class="d-block w-100" :alt="'Slide ' + (index + 1)" />
              <div class="carousel-caption d-none d-md-block">
                <p>{{ src.caption }}</p>
              </div>
            </div>
          </div>
          <ol class="carousel-indicators" style="list-style-type: none">
            <li
              v-for="(src, index) in headerInfo.images"
              :key="index"
              data-bs-target="#listBuilderCarousel"
              :data-bs-slide-to="index"
              :class="{ active: index === 0 }" />
          </ol>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#listBuilderCarousel"
            data-bs-slide="prev"
            v-if="this.headerInfo.images && this.headerInfo.images.length > 1">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#listBuilderCarousel"
            data-bs-slide="next"
            v-if="this.headerInfo.images && this.headerInfo.images.length > 1">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <h1 v-show="menuVisibility">{{ menuTitle }}</h1>
      </div>
      <div v-if="itemKeys.length == 0">
        <component v-if="emptyComponent" v-bind:is="emptyComponent" />
        <div v-else-if="emptyMessage" v-html="emptyMessage.replace('%menu_title%', menuTitle)" class="p-2" />
      </div>
      <FolderItemList v-show="displayFolders" ref="folders" :list="list" @filterIds="filterFolder" class="item" />
      <Sortable
        :id="displayType == 'categorygrid' ? 'grid-container' : 'items'"
        :class="{ 'hide-actions': action }"
        :list="listArray"
        :item-key="
          (item) => {
            return getSortKey(item);
          }
        "
        tag="ul"
        :options="sortableOptions"
        @end="(evt) => moveItems(evt)">
        <template #item="{ element, index }">
          <li :class="displayType == 'categorygrid' ? '' : 'item'">
            <component
              v-bind:is="centerComponent"
              :item="element"
              :prevItemId="itemKeys[index - 1]"
              :locations="locations"
              :listIcons="listIcons"
              :itemId="itemKeys[index]"
              :dbContext="dbContext"
              :displayType="displayType"
              :is-system-wide="isSystemWide"
              @deleteItem="deleteItem"
              @action="setAction"
              @editItem="editItem"
              @updateItem="updateItem"
              :selectedItem="getSelectedItem"
              :inProcess="inProcess" />
          </li>
        </template>
      </Sortable>
      <!-- <b-modal ref="deleteItemModal" id="delete-item" title="Delete Item" classes="amc-modal" confirmTitle="Delete">
        <template #body>
          <p class="my-4">Are you certain you want to delete?</p>
        </template>
      </b-modal> -->
    </div>
    <div id="right-column" class="p-2">
      <component
        :is="rightColumnComponent"
        v-bind="rightColumnProps"
        @action="setAction"
        :key="rightColumnKey"
        :rightColumnKey="rightColumnKey"
        :action="action"
        @header-info-update="headerInfoUpdate" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import ButtonNavigation from '../components/ButtonNavigation.vue';
import { Sortable } from 'sortablejs-vue3';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import axios from 'axios';
import moment from 'moment';
import BModal from '@/components/common/BModal.vue';

import { typeMap, newObjectMap } from '../json/listBuilderData.json';

export default {
  name: 'listing',
  components: {
    Sortable,
    ButtonNavigation,
    BModal,
    //EventItems: defineAsyncComponent(() => import('../components/listitems/EventItems.vue')),
    SimpleItems: defineAsyncComponent(() => import('../components/listitems/SimpleItems.vue')),
    ContactItems: defineAsyncComponent(() => import('../components/listitems/ContactItems.vue')),
    MapItems: defineAsyncComponent(() => import('../components/listitems/MapItems.vue')),
    DollarsAccountStatusItems: defineAsyncComponent(() => import('../components/listitems/DollarsAccountStatusItems.vue')),
    BusinessItems: defineAsyncComponent(() => import('../components/listitems/BusinessItems.vue')),
    CampgroundItems: defineAsyncComponent(() => import('../components/listitems/CampgroundItems.vue')),
    EntryItems: defineAsyncComponent(() => import('../components/listitems/EntryItems.vue')),
    GridMenuItems: defineAsyncComponent(() => import('../components/listitems/GridMenuItems.vue')),
    RewardsUserItems: defineAsyncComponent(() => import('../components/listitems/RewardsUserItems.vue')),
    UserAuthItems: defineAsyncComponent(() => import('../components/listitems/UserAuthItems.vue')),
    StoreItems: defineAsyncComponent(() => import('../components/listitems/StoreItems.vue')),
    FileItems: defineAsyncComponent(() => import('../components/listitems/FileItems.vue')),
    SimpleItem: defineAsyncComponent(() => import('../components/forms/SimpleItem.vue')),
    ContactItem: defineAsyncComponent(() => import('../components/forms/ContactItem.vue')),
    MapItem: defineAsyncComponent(() => import('../components/forms/MapItem.vue')),
    EmailTemplate: defineAsyncComponent(() => import('../components/forms/EmailTemplate.vue')),
    RewardsItem: defineAsyncComponent(() => import('../components/forms/RewardsItem.vue')),
    MainMenuItem: defineAsyncComponent(() => import('../components/forms/MainMenuItem.vue')),
    Notification: defineAsyncComponent(() => import('../components/forms/Notification.vue')),
    RewardsUser: defineAsyncComponent(() => import('../components/forms/RewardsUser.vue')),
    Topic: defineAsyncComponent(() => import('../components/forms/Topic.vue')),
    EventLocation: defineAsyncComponent(() => import('../components/forms/EventLocation.vue')),
    EventConfig: defineAsyncComponent(() => import('../components/forms/EventConfig.vue')),
    SplashConfig: defineAsyncComponent(() => import('../components/forms/SplashConfig.vue')),
    DollarsConfig: defineAsyncComponent(() => import('../components/forms/DollarsConfig.vue')),
    ManagePayments: defineAsyncComponent(() => import('../components/forms/ManagePayments.vue')),
    ManageFiles: defineAsyncComponent(() => import('../components/forms/ManageFiles.vue')),
    HeaderImages: defineAsyncComponent(() => import('../components/forms/HeaderImages.vue')),
    FormEntry: defineAsyncComponent(() => import('../components/forms/FormEntry.vue')),
    HuntEntry: defineAsyncComponent(() => import('../components/forms/HuntEntry.vue')),
    StoreOrder: defineAsyncComponent(() => import('../components/forms/StoreOrder.vue')),
    BannerAd: defineAsyncComponent(() => import('../components/forms/BannerAd.vue')),
    UserAuth: defineAsyncComponent(() => import('../components/forms/UserAuth.vue')),
    UserMgmt: defineAsyncComponent(() => import('../components/forms/UserMgmt.vue')),
    StoreListItem: defineAsyncComponent(() => import('../components/forms/StoreListItem.vue')),
    MapListItem: defineAsyncComponent(() => import('../components/forms/MapListItem.vue')),
    LinkAccounts: defineAsyncComponent(() => import('../components/empty/LinkAccounts.vue')),
    Import: defineAsyncComponent(() => import('../components/forms/Import.vue')),
    Export: defineAsyncComponent(() => import('../components/forms/Export.vue')),
    FolderItemList: defineAsyncComponent(() => import('../components/listitems/FolderItemList.vue')),
  },
  data() {
    return {
      action: '',
      context: '',
      dbContext: '',
      displayType: '',
      tabs: {},
      tabIndex: 0,
      isSystemWide: false,
      locations: {},
      list: {},
      centerColumnProps: {},
      rightColumnProps: {},
      rightColumnKey: '',
      itemKeys: [],
      listArray: [],
      type: '',
      locationDataRef: null,
      currentRightColumnComponent: null,
      userFilter: null,
      communityFilter: null,
      fullApps: false,
      listedCommunities: this.$fbProject !== 'studio',
      fullList: false,
      emptyComponent: null,
      emptyMessage: null,
      menuTitle: '',
      viewArchived: false,
      inProcess: false,
      campgroundKey: '',
      listIcons: {},
      headerInfo: [],
      menuVisibility: false,
      exportDialog: {
        visibility: false,
        exporting: 'images',
      },
      filterList: {},
      displayFolders: true,
      errorMessage: '',
      downloadUrl: '',
      sortingKey: 'id',

      headerContextMap: {
        'events-resort': 'events',
        'events-local': 'events',
        'services-resort': 'services',
        'services-local': 'services',
        'recreation-resort': 'recreation',
        'recreation-local': 'recreation',
        'reservations-lodging': 'reservations',
        'reservations-misc': 'reservations',
        'maps-resort': 'maps',
        'maps-local': 'maps',
        'dining-resort': 'dining',
        'dining-local': 'dining',
        'dining-delivery': 'dining',
      },
    };
  },
  computed: {
    getSelectedItem: function () {
      return this.$route.params.itemId || this.rightColumnKey;
    },
    centerComponent: function () {
      return typeMap[this.type][this.tabIndex].listComponent;
    },
    rightColumnComponent: function () {
      return this.currentRightColumnComponent;
    },
    sortableOptions() {
      return {
        animation: 150,
        easing: 'cubic-bezier(1, 0, 0, 1)',
        disabled: this.action !== 'sort-button',
      };
    },
  },
  watch: {
    '$route.params': {
      handler: function () {
        if (
          this.$route.params.prevContext == 'systemwide-notification' &&
          ['category', 'user-group', 'messages-scheduled'].includes(this.$route.params.context)
        ) {
          this.campgroundKey = 'system-data';
          this.locationDataRef = this.getLocationRef(this.campgroundKey);
        }

        this.resetData(this, this.$route);
      },
      deep: true,
    },
    userFilter: function () {
      this.itemKeys = !this.userFilter
        ? Object.keys(this.list)
        : Object.keys(this.list).filter((value) => {
            const item = this.list[value];
            const ftr = (this.userFilter || '').toLowerCase();
            return (
              (item.name || '').toLowerCase().includes(ftr) ||
              (item.email || '').toLowerCase().includes(ftr) ||
              (value || '').toLowerCase().includes(ftr)
            );
          });
      this.getListArray();
    },
    communityFilter: function () {
      this.itemKeys = !this.communityFilter
        ? Object.keys(this.list)
        : Object.keys(this.list).filter((value) => {
            const item = this.list[value];
            const ftr = this.communityFilter.toLowerCase();
            return item.title.toLowerCase().includes(ftr) || (item.appName && item.appName.toLowerCase().includes(ftr));
          });
      this.getListArray();
    },
    fullApps: function () {
      this.getFilteredCommunities();
    },
    listedCommunities: function () {
      this.getFilteredCommunities();
    },
  },
  async mounted() {
    this.isSystemWide =
      this.$route.params.context === 'systemwide-notification' || this.$store.getters.params?.prevContext === 'systemwide-notification';
    this.campgroundKey = this.isSystemWide ? 'system-data' : this.getCampgroundKey;
    this.locationDataRef = this.getLocationRef(this.campgroundKey);
    this.resetData(this, this.$route);
  },
  methods: {
    headerInfoUpdate(item) {
      this.headerInfo = item;
      this.menuVisibility = !item.hideTitle;
    },
    getHeaderStyles: function () {
      const image =
        this.headerInfo && this.headerInfo.images && this.headerInfo.images.length > 0 ? this.headerInfo.images[0].imageURL || null : null;
      if (image) {
        return `height:261px;background-image:url("${image}");`;
      } else if (this.headerInfo.videoURL) {
        return `height:261px;`;
      }
      return `${this.getBackgroundColorStyle}height:50px;`;
    },
    getFilteredCommunities() {
      this.itemKeys = (
        !this.listedCommunities && !this.fullApps
          ? Object.keys(this.list)
          : Object.keys(this.list).filter((value) => {
              const item = this.list[value];
              return (!this.listedCommunities || item.listed) && (!this.fullApps || !item.isBusinessOnly);
            })
      ).sort((a, b) => {
        if (this.list[a].pinned !== this.list[b].pinned) {
          return this.list[a].pinned == true ? -1 : 1;
        }
        if (this.list[a].ordinal !== this.list[b].ordinal) return this.list[a].ordinal - this.list[b].ordinal;
        const titleA = this.list[a].title || this.list[a].name;
        const titleB = this.list[b].title || this.list[b].name;
        return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
      });
      this.getListArray();
    },
    getTabStyle: function (selected) {
      return selected ? `border-bottom-color: ${this.getAccentColor};${this.getPrimaryColorStyle}` : this.getPrimaryColorStyle;
    },
    resetData: function (vm, route) {
      vm.itemKeys = [];
      vm.listArray = [];
      vm.list = {};
      if (route.params.menuId) {
        vm.getMenuItem(route.params.menuId || 'main-menu', route.params.gridMenuKey);
      } else {
        this.type = route.params.context;
        this.context = route.params.context;
        this.dbContext = route.params.context.replace('.', '/');
        this.displayType = typeMap[this.type][this.tabIndex].displayType;
        this.emptyComponent = typeMap[this.type][this.tabIndex].emptyComponent;
        this.emptyMessage = typeMap[this.type][this.tabIndex].emptyMessage;
        this.getDataList();
      }
      vm.tabIndex = route.params.tab || 0;
      vm.rightColumnKey = route.params.itemId || route.params.itemId == 0 ? route.params.itemId : '';
      vm.action = route.params.action !== 'view' ? route.params.action : '';
    },
    getMenuItem: async function (menuId, gridMenuKey) {
      //added to setup ootb recreation/services categories
      if (menuId && menuId.endsWith('local-categories')) {
        this.type = 'business';
        this.context = `${menuId.replace('-categories', '')}/`;
        menuId = `${menuId}/${menuId.replace('-local', '')}`;
      }
      let dbRef = this.locationDataRef.child(menuId);
      if (gridMenuKey) {
        dbRef = dbRef.child(gridMenuKey);
      }
      const res = await dbRef.once('value');
      this.setDataForMenuItem(res.val());
      this.getDataList();
      this.headerInfo =
        (await this.getContextRef(`header-info/${this.headerContextMap[this.dbContext] || this.dbContext}`).once('value')).val() || [];
      this.menuVisibility = !this.headerInfo.hideTitle;
    },
    setDataForMenuItem: async function (menuItem) {
      this.tabs = menuItem.tabs;
      //added to setup ootb recreation/services categories
      if (this.$route.params.menuId.endsWith('local-categories')) {
        this.context += menuItem.id;
        this.dbContext = this.context;
      } else {
        this.dbContext = menuItem.type ? menuItem.uniqueId : menuItem.id;
        this.context = menuItem.type ? menuItem.type : menuItem.id;
        this.type = this.context;
      }
      this.menuTitle = menuItem.name;
      this.emptyComponent = typeMap[this.type][this.tabIndex].emptyComponent;
      this.emptyMessage = typeMap[this.type][this.tabIndex].emptyMessage;
      this.displayType = typeMap[this.type][this.tabIndex].displayType;

      if (this.tabs) {
        this.dbContext = `${this.dbContext}-${this.tabs[this.tabIndex].id}`;
      }
      if (this.type === 'dining') {
        this.dbContext = `${this.dbContext}/restaurants`;
      }
      if (this.displayType === 'categorygrid' && ['services-local', 'recreation-local'].includes(this.dbContext)) {
        this.dbContext = `${this.dbContext}-categories/${this.context}-categories`;
      }

      if (this.type === 'events' || this.displayType === 'config.events') {
        await this.getLocations();
      }
    },
    getLocations: async function () {
      const locationsSS = await this.locationDataRef.child('locations').once('value');
      this.locations = locationsSS.val() || {};
    },
    getTopicRef: function (dbRef, type) {
      return dbRef.child('messages-config').child('topics').orderByChild('type').equalTo(type);
    },
    getDataList: async function (viewArchived) {
      this.displayFolders = false;

      let dbRef = this.locationDataRef;
      console.log('🚀 ~ file: ListBuilder.vue ~ line 267 ~ getDataList:function ~ this.dbContext', this.dbContext);
      switch (this.dbContext) {
        case 'splashscreen': {
          const appList = await this.getAppList();
          if (appList.length == 1) {
            dbRef = firebase
              .database()
              .ref('resort-navigator')
              .child('app-data')
              .child(appList[0].value)
              .child('splash-screen-data')
              .child('splashScreens');
          } else {
            //TODO: select app
            dbRef = firebase
              .database()
              .ref('resort-navigator')
              .child('app-data')
              .child(appList[0].value)
              .child('splash-screen-data')
              .child('splashScreens');
          }
          break;
        }
        case 'dollarsaccountstatus':
          dbRef = firebase.database().ref('resort-navigator').child('amc-dollars').child('participants');
          break;
        case 'email':
          dbRef = firebase.database().ref('resort-navigator').child('location-data').child('system-data').child('config').child('email');
          break;
        case 'feedback':
          dbRef = this.getFeedbackList(viewArchived);
          break;
        case 'switch-community':
          dbRef = firebase.database().ref('resort-navigator').child('campground-locations');
          break;
        case 'orders':
          dbRef = await this.getOrdersList(viewArchived);
          break;
        case 'category':
        case 'user-group':
          //const prevContext = this.$store.getters.params?.prevContext;
          if (!(await this.checkForMessages())) {
            //if (prevContext === 'systemwide-notification') {
            dbRef = firebase.database().ref('resort-navigator').child('location-data').child('system-data');
          }
          dbRef = this.getTopicRef(dbRef, this.dbContext);
          break;
        // TODO: Is the following case deprecated and need removed?
        case 'systemwide-notification':
          if (this.$route.params.context === 'systemwide-notification') {
            dbRef = firebase.database().ref('resort-navigator').child('location-data').child('system-data').child('messages');
            if (this.$store.getters.params?.newItem?.isFromEvent) this.$nextTick(() => this.setAction('add-button'));
          } else {
            dbRef = dbRef.child('messages');
          }
          break;
        case 'remote-messages-scheduled':
        case 'send-detail-notification': {
          let detailId = this.$store.getters.detailId;
          if (this.$route.params.uuid !== this.$store.getters.uuid) {
            const remoteBusinessUpdate = await firebase.functions().httpsCallable('remoteBusinessUpdate');
            const businessInfo = (await remoteBusinessUpdate({ uuid: this.$route.params.uuid, action: 'getId' })).data;
            if (businessInfo != null) {
              const itemKey = Object.keys(businessInfo.item)[0];
              detailId = businessInfo.item[itemKey].id;
              this.$store.commit('updateUUID', this.$route.params.uuid);
              this.$store.commit('updateRemoteInfo', businessInfo.remoteInfo);
              this.$store.commit('updateDetailId', businessInfo.item[itemKey].id);
              this.setCampgroundLocationData(detailId.split('/')[0], businessInfo.communityInfo);
            }
          }
          dbRef = firebase.database().ref('resort-navigator').child('remote-management').child('detail-pages').child(detailId);
          dbRef = this.dbContext !== 'remote-messages-scheduled' ? dbRef.child('messages') : dbRef.child('messages-scheduled');
          break;
        }
        case 'import':
          dbRef = firebase.database().ref('resort-navigator').child('location-import');
          break;
        default:
          dbRef = dbRef.child(this.dbContext.replace('.', '/'));
      }
      if (this.displayType == 'business') {
        this.locationDataRef
          .child('config/business/listIcons')
          .once('value')
          .then((snap) => {
            this.listIcons = snap.val() ?? {};
          });
      } else if (this.displayType == 'giveaways') {
        dbRef = dbRef.orderByChild('isGiveaway').equalTo(true);
      }
      if (this.dbContext.endsWith('/entries')) {
        dbRef = this.getEntriesList(viewArchived);
      }
      const isResortAdmin =
        window.localStorage.getItem(`RESORT_SYSTEM_ADMIN`) == true || window.localStorage.getItem('RESORT_SYSTEM_ADMIN') == 'true' ? true : false;

      if (['rewards-users', 'users', 'usermgmt'].includes(this.displayType)) {
        const userType = this.displayType === 'usermgmt' ? 'users' : this.displayType;
        var url = `${this.$apihostname}/api/${userType}?campgroundId=${this.campgroundKey}&_=${Date.now()}`;
        if (this.displayType === 'usermgmt') {
          url += '&action=usermgmt';
        }
        const response = await axios.get(url);
        if (response.data) {
          this.list = response.data;
        }
        this.newEmail = '';
        this.getKeyArray(viewArchived);
        this.setAction(this.action);
      } else if (this.displayType == 'file') {
        this.list = await this.getFileList();
        this.getKeyArray(viewArchived);
        this.setAction(this.action);
      } else if (['store', 'forms', 'hunts'].includes(this.displayType) && this.context !== 'orders' && this.dbContext !== 'hunts/entries') {
        const res = await dbRef.once('value');
        console.log('TCL: this.dbContext', this.dbContext);
        let templates = {};
        if ('custom-forms' === this.dbContext) {
          const formTemplates = require('@/json/formTemplates.json');
          templates = Object.keys(formTemplates).reduce((acc, key) => {
            acc[key] = { ...formTemplates[key], folder: 'App My Community Templates' };
            return acc;
          }, {});
        }
        this.list = { ...templates, ...(res.val() || {}) };
        this.filterList = { ...this.list };

        this.getKeyArray(viewArchived);
        this.setAction(this.action);

        this.displayFolders = true;
        this.$refs.folders?.initialize(this.list);
      } else if (this.context === 'orders') {
        dbRef.once('value', async (res) => {
          const items = (await res.val()) || [];
          this.list = typeof items === 'array' ? items : Object.values(items);
          this.list = this.list
            .map((item, index) => (item ? { ...item, orderId: index } : null))
            .filter((item) => (viewArchived ? item.completed > 0 : !item.completed || item.completed === 0));

          const filterId = this.$route.params.key;
          if (filterId) {
            this.list.forEach((item) => {
              if (item && item.cartItems) {
                item.cartItems = item.cartItems.filter((item) => item.storeItemKey === filterId);
              }
            });
            this.list = this.list.filter((item) => item.cartItems && item.cartItems.length > 0);
          }

          this.getKeyArray(viewArchived);
          this.setAction(this.action);
        });
      } else {
        dbRef.once('value', async (res) => {
          this.list = res.val() || {};
          if (this.dbContext === 'dollarsaccountstatus') {
            await this.combineDollarsAccountData();
          }
          if (this.needsReversed()) {
            this.reverseSnapshot(res.val());
          } else {
            if (this.dbContext == 'switch-community' && !isResortAdmin) {
              const editLocations = JSON.parse(window.localStorage.getItem(`EDIT_LOCATIONS`)) || [];
              const allCommunities = res.val() || {};
              this.list = Object.keys(allCommunities)
                .filter((x) => editLocations.includes(x))
                .reduce((acc, x) => {
                  acc[x] = allCommunities[x];
                  return acc;
                }, {});
            } else if (this.dbContext === 'google-events-import') {
              const currentTime = Date.now();
              const events = res.val();
              this.list = Object.keys(events)
                .filter((eventIndex) => events[eventIndex].startDate > currentTime)
                .reduce((acc, eventIndex) => {
                  acc[eventIndex] = events[eventIndex];
                  return acc;
                }, {});
            } else if (this.dbContext === 'hunts/entries' && !this.inProcess) {
              this.list = Object.keys(this.list).reduce((acc, x) => {
                const namedList = Object.keys(this.list[x]).reduce((acc, z) => {
                  acc[z] = this.list[x][z];
                  acc[z].createdBy = x;
                  return acc;
                }, {});
                return { ...acc, ...namedList };
              }, {});
            }
          }
          this.getKeyArray(viewArchived);
          this.setAction(this.action);
          if (['systemwide-notification', 'messages', 'usermgmt', 'hunts.entries'].includes(this.type)) dbRef.off();
        });
      }
      console.log('displayType', this.displayType);
      console.log('getData:list', this.list);
    },
    getEntriesList: function (viewArchived) {
      var tempDBContext = this.context.substr(0, this.context.indexOf('.'));
      tempDBContext = !tempDBContext.includes('hunts') || this.inProcess ? tempDBContext : 'hunt-submissions';
      const dbRef = this.getUserWrittenLocationContext().child(tempDBContext).child(this.$route.params.itemId);
      if (this.inProcess) {
        return dbRef;
      }
      if (!viewArchived) {
        return dbRef.orderByChild('completed').endAt(0);
      }
      return dbRef.orderByChild('completed').startAt(1);
    },
    getFeedbackList: function (viewArchived) {
      const dbRef = this.getUserWrittenLocationContext().child('user-feedback');
      if (!viewArchived) {
        return dbRef.orderByChild('completed').endAt(0);
      }
      return dbRef.orderByChild('completed').startAt(1);
    },
    combineDollarsAccountData: async function () {
      const participantsRef = firebase
        .database()
        .ref('resort-navigator')
        .child('amc-dollars')
        .child('participant-dollars')
        .orderByChild('currentDollars');
      const participantDollars = (await participantsRef.once('value')).val() || {};
      const keys = Object.keys(participantDollars).sort((o1, o2) =>
        participantDollars[o1].currentDollars < participantDollars[o2].currentDollars ? 1 : -1
      );
      this.list = keys.reduce((acc, itemKey) => {
        if (!this.list[itemKey]) return acc;
        acc[itemKey] = { ...this.list[itemKey], ...participantDollars[itemKey] };
        return acc;
      }, {});
    },
    getOrdersList: function (viewArchived) {
      return this.getUserWrittenLocationContext().child(this.context);
    },
    needsReversed: function () {
      return typeMap[this.displayType] && typeMap[this.displayType][this.tabIndex] && typeMap[this.displayType][this.tabIndex].reverse == true;
    },
    reverseSnapshot: function (items) {
      if (!items) return;
      this.list = {};
      const keys = Object.keys(items).reverse();
      keys.map((itemKey) => {
        this.list[itemKey] = items[itemKey];
      });
    },

    getListArray: function () {
      this.listArray = this.itemKeys.map((key) => this.list[key]);
      console.log('🚀 ~ this.listArray:', this.listArray);
    },
    getKeyArray: function (viewArchived) {
      this.itemKeys = Object.keys(this.list);
      console.log('🚀 ~ this.itemKeys:', this.itemKeys);
      if (this.dbContext === 'hunts/entries' && !this.inProcess) {
        this.itemKeys = this.itemKeys.sort();
      }
      if (this.displayType == 'messages' && this.dbContext !== 'messages-scheduled') {
        const currentTime = Date.now();
        this.itemKeys = this.itemKeys.filter((value) => {
          const endTime = this.list[value].endTime;
          return viewArchived == true ? endTime !== 0 && endTime < currentTime : endTime >= currentTime || endTime == 0;
        });
      } else if (this.dbContext == 'switch-community') {
        this.getFilteredCommunities();
      }
      this.getListArray();
    },
    deleteItem: function (itemIds) {
      this.resetData(this, this.$route);
    },
    getFormProps: async function () {
      const tempDBContext = this.type == 'feedback' ? 'user-feedback' : this.dbContext.substr(0, this.dbContext.indexOf('/')) || this.dbContext;
      const entryTypes = {
        'hunts.entries': 'HuntEntry',
        'custom-forms.entries': 'FormEntry',
        feedback: 'FormEntry',
        orders: 'StoreOrder',
      };
      const entryType = entryTypes[this.type];
      let currentForm = null;
      if (this.type == 'hunts.entries') {
        const formSS = await this.getLocationRef(this.campgroundKey)
          .child(tempDBContext)
          .orderByChild('key')
          .equalTo(this.$route.params.itemId)
          .once('value');
        formSS.forEach((currentFormSS) => {
          currentForm = currentFormSS.val();
        });
      } else if (this.type == 'feedback') {
        currentForm = {
          formSchema: [
            {
              label: 'Name',
              name: 'name',
              type: 'text',
            },
            {
              label: 'Site Number',
              name: 'siteNumber',
              type: 'label',
            },
            {
              label: 'Check-In Date',
              name: 'startDate',
              type: 'date',
            },
            {
              label: 'Check-Out Date',
              name: 'endDate',
              type: 'date',
            },
            {
              label: 'Cleanliness',
              name: 'cleanliness',
              type: 'starRating',
            },
            {
              label: 'Staff',
              name: 'staff',
              type: 'starRating',
            },
            {
              label: 'Amenities',
              name: 'amenities',
              type: 'starRating',
            },
            {
              label: 'Additional Comments',
              name: 'additionalComments',
              type: 'textarea',
            },
          ],
        };
      } else if (this.$route.params.itemId) {
        const formSS = await this.getLocationRef(this.campgroundKey).child(tempDBContext).child(this.$route.params.itemId).once('value');
        currentForm = formSS.val();
      }
      return {
        section: 'actions',
        context: this.context,
        dbContext: tempDBContext,
        displayType: entryType,
        item: currentForm,
      };
    },
    updateItem: async function (itemId, item) {
      this.list[itemId] = item;
    },
    editItem: async function (itemId) {
      switch (this.type) {
        case 'custom-forms.entries':
        case 'feedback':
        case 'hunts.entries':
        case 'orders':
          this.rightColumnProps = {
            ...(await this.getFormProps(itemId)),
            ...{
              entry: this.list[itemId],
              entryId: itemId,
            },
          };
          this.currentRightColumnComponent = this.rightColumnProps.displayType;
          break;
        default:
          this.rightColumnKey = itemId;
          this.setAction('edit');
      }
    },

    moveItems(evt) {
      const item = this.listArray.splice(evt.oldIndex, 1)[0];
      this.listArray.splice(evt.newIndex, 0, item);
    },
    setIndex: function (index) {
      const toAppend = this.$route.name == 'listing';
      this.$router.push({ path: index.toString(), append: toAppend });
    },
    /*getTopicsForUsers: function(userInformation){
                const currentAdminDBRef = admin.database();
                const topics = [];
                let topicsSnapshot = (await this.getLocationRef().child('messages-config').child('topics').once('value')).val();
                topicsSnapshot.forEach(function(topicSnapshot) {
                    topics.push(topicSnapshot.child('title').val());
                    if(topicSnapshot.hasChild('users')){
                        topicSnapshot.child('users').forEach(function(uidSnapshot){
                        if(userInformation[uidSnapshot.key]){
                            userInformation[uidSnapshot.key][topicSnapshot.child('title').val()] = true
                        }
                        });
                    }
                });
                makeCSV(res, userInformation, topics)

            },*/
    getArrayData: async function (itemKeys, itemsList) {
      let arrayData = [];
      let labels = [];
      if (['category', 'topic', 'user-groups', 'users', 'usermgmt'].includes(this.displayType)) {
        const url = `${this.$apihostname}/api/users?campgroundId=${this.campgroundKey}&action=namedUsers&_=${Date.now()}`;
        const response = await axios.get(url);

        const topics = (await this.getLocationRef(this.campgroundKey).child('messages-config').child('topics').once('value')).val();
        const topicIds = Object.keys(topics).filter((topicId) => topics[topicId].users);
        let userIds = {};
        const topicList = topicIds.map((topicId) => {
          Object.keys(topics[topicId].users).map((user) => {
            if (!userIds[user]) {
              userIds[user] = [];
            }
            userIds[user].push(topicId);
          });
          return topics[topicId].title;
        });
        arrayData = response.data.map((user) => {
          console.log('user', user);
          const userTopics = topicIds.map((topicId) => {
            return userIds[user.uid] && topics[topicId].users[user.uid];
          });
          return [user.name, user.email, user.communityId, user.loggedIn, ...userTopics, user.uid];
        });
        arrayData.sort(function (itemA, itemB) {
          const aName = itemA[0].toLowerCase();
          const bName = itemB[0].toLowerCase();
          if (aName == bName) {
            if (itemA[2] == 'Not Logged In') {
              return 1;
            }
            if (itemB[2] == 'Not Logged In') {
              return -1;
            }
            const aTime = moment(itemA[2]).valueOf();
            const bTime = moment(itemB[2]).valueOf();
            return aTime < bTime ? -1 : aTime > bTime ? 1 : 0;
          }
          if (aName == 'anonymous user') {
            return 1;
          }
          if (bName == 'anonymous user') {
            return -1;
          }
          return aName > bName ? 1 : aName < bName ? -1 : 0;
        });
        labels = ['Logged In User', 'Logged In Email', 'Community Id', 'LoggedIn', ...topicList, 'UID'];
        arrayData.unshift(labels);
      } else if (this.displayType === 'store') {
        console.log('list at store csv', this.list);
        //Populate labels and data with relevant values here.
        labels = [
          'Order ID',
          'Payment ID',
          'Title',
          'Quantity',
          'Price',
          'Primary Option',
          'Secondary Option',
          'Order Time',
          'Delivery Time',
          'Item Site',
          'Item Name',
          'Item Phone',
          'Item Email',
          'User Name',
          'User Email',
          'UID',
          'Confirmed',
        ];
        arrayData.unshift(labels);

        this.list.forEach((order) => {
          if (!order.cartItems || order.cartItems.length == 0) {
            return;
          }
          order.cartItems.forEach((cartItem) => {
            const primaryTitle = cartItem.primaryOptionTitle?.split(' -')[0];
            const primaryDollar = cartItem.primaryOptionTitle?.split('$')[1];
            const secondaryTitle = cartItem.secondaryOptionTitle?.split(' -')[0];
            const secondaryDollar = cartItem.secondaryOptionTitle?.split('$')[1];
            let totalPrice = 0;
            if (primaryDollar) {
              totalPrice += parseFloat(primaryDollar);
            }
            if (secondaryDollar) {
              totalPrice += parseFloat(secondaryDollar);
            }

            const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
            const itemData = [
              order.orderId,
              order.paymentIntentId || '',
              cartItem.storeItemTitle,
              cartItem.quantity,
              totalPrice,
              primaryTitle,
              secondaryTitle,
              new Date(order.orderTime).toLocaleString('en-US', options),
              new Date(cartItem.deliveryTime).toLocaleString('en-US', options),
              cartItem.site,
              cartItem.name,
              cartItem.phone,
              cartItem.email,
              order.loggedInUser,
              order.loggedInEmail,
              order.createdBy,
              cartItem.confirmed,
            ];
            if (order.site) {
              itemData.push(order.site);
              if (labels.indexOf('Order Site') == -1) {
                labels.push('Order Site');
              }
            }
            if (order.pin) {
              itemData.push(order.pin);
              if (labels.indexOf('Order Pin') == -1) {
                labels.push('Order Pin');
              }
            }
            arrayData.push(itemData);
          });
        });
      } else {
        const userEntryData = await this.getFormProps();

        let types = [];
        let ids = [];
        if (userEntryData.displayType == `FormEntry`) {
          ids = userEntryData.item.formSchema
            .filter((formItem) => !['header', 'label', 'static'].includes(formItem.type))
            .map((formItem) => {
              labels.push(formItem.label);
              types.push(formItem.type);
              return formItem.name;
            });
        } else if (userEntryData.displayType == `HuntEntry` && userEntryData.item.type != `golf`) {
          console.log('userEntryData', userEntryData);
          ids = userEntryData.item.steps.map((huntItem) => {
            labels.push(huntItem.title);
            types.push(huntItem.type);
            return huntItem.key;
          });
          ids.push('Total Answers', 'Total Correct Answers', 'Total Incorrect Answers');
          labels = [...labels, ...['Total Answers', 'Total Correct', 'Total Incorrect']];
          types = [...types, ...['text', 'text', 'text']];
        } else if (userEntryData.item.type == `golf`) {
          ids = userEntryData.item.steps.map((huntItem) => {
            labels.push(huntItem.title);
            types.push(huntItem.type);
            return huntItem.key;
          });
          ids.push('Total Score');
          labels = [...labels, ...['Total Score']];
          types = [...types, ...['text']];
        } else {
          ids = ['name', 'siteNumber', 'startDate', 'endDate', 'staff', 'cleanliness', 'amenities', 'additionalComments'];
          labels = ['Name', 'Site', 'Start date', 'End Date', 'Staff', 'Cleanliness', 'Amenities', 'Additional Comments'];
          types = ['text', 'text', 'date', 'date', 'text', 'text', 'text', 'text'];
        }
        console.log('itemsList', itemsList);
        console.log('itemKeys', itemKeys);
        const scoresArray = [];
        arrayData = itemKeys.map((itemKey) => {
          const scores = { key: itemKey, holes: [], players: [] };
          scoresArray.push(scores);

          const summary = this.dbContext === 'hunts/entries' ? this.composeSummary(userEntryData.item, itemsList[itemKey]) : [];
          return [
            itemsList[itemKey].loggedInUser || 'User Not Loaded Yet',
            itemsList[itemKey].loggedInEmail || 'Please Try Again',
            ...(this.dbContext !== 'hunts/entries'
              ? [
                  itemsList[itemKey].communityId,
                  itemsList[itemKey].createDate
                    ? `${this.getFormattedDate(itemsList[itemKey].createDate)} ${this.getFormattedTime(itemsList[itemKey].createDate)}`
                    : 'N/A',
                ]
              : []),
            ...ids.map((id, index) => {
              let value = '';
              if (this.dbContext === 'hunts/entries') {
                const formItem = itemsList[itemKey][id] || {};
                // value will be answer or completed.
                if (formItem['drawable']) {
                  value = formItem['drawable'];
                } else if (formItem['answer']) {
                  value = formItem['answer'];
                } else if (userEntryData.item.type == 'golf') {
                  if (formItem['scores']) {
                    let obj = formItem['scores'];
                    let playerScores = Object.keys(obj).map((key) => ({ name: key, score: obj[key] }));
                    scores.holes.push(playerScores);
                    if (scores.players.length == 0) {
                      scores.players = playerScores.map((player) => player.name);
                    }
                  } else value = '';
                } else {
                  value = formItem['completed'] ? 'Completed' : 'Not completed';
                }

                if (id in summary) {
                  value = summary[id] || '0';
                }
              } else {
                const formItem = itemsList[itemKey][id];
                value = formItem && formItem.value ? formItem.value : formItem && typeof formItem !== 'object' ? formItem : '';
                if (types[index] == 'date' && value) {
                  value = this.getFormattedDate(value);
                } else if (types[index] == 'signature' && !value) {
                  value = 'Signed image';
                }
              }
              return value || '';
            }),
          ];
        });

        if (userEntryData.item.type == `golf`) {
          const extractedData = [...arrayData];
          arrayData = [];
          let rowOffset = 0;
          scoresArray.forEach((scores, index) => {
            //Copy first array in arrayData and remove from arrayData
            const extractedArray = extractedData[index];

            const date = new Date(parseInt(scores.key));
            const submissionTime = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${String(
              date.getMinutes()
            ).padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
            for (let i = 0; i < scores.players.length; i++) {
              const array = new Array(labels.length + 3).fill('');

              let totalScore = 0;
              const startingHoleIndex = labels.indexOf('Hole #1') + 4;
              array[0] = extractedArray[0]; //Name
              array[1] = extractedArray[1]; //Email
              array[2] = submissionTime;
              array[3] = scores.players[i];
              scores.holes.forEach((hole, holeIndex) => {
                hole.forEach((player) => {
                  if (player.name == scores.players[i]) {
                    array[startingHoleIndex + holeIndex] = player.score;
                    totalScore += player.score;
                  }
                });
              });

              const totalScoreIndex = labels.indexOf('Total Score') + 4;
              array[totalScoreIndex] = totalScore;
              arrayData.push(array);
            }
            rowOffset += scores.players.length;
          });
        }

        if (this.dbContext === 'hunts/entries' && userEntryData.item.type != 'golf') {
          arrayData.unshift(['Name', 'Email Address', ...labels]);
        } else if (this.dbContext === 'hunts/entries' && userEntryData.item.type == 'golf') {
          arrayData.unshift(['Name', 'Email Address', 'Submission Time', 'Name', ...labels]);
        } else {
          arrayData.unshift(['Logged In User', 'Logged In Email', 'Community Id', 'Date Submitted', ...labels]);
        }

        console.log('arrayData', arrayData);
      }
      return arrayData;
    },
    sortItemsByTitle: function (items) {
      let itemObject = {};
      if (items) {
        const keys = Object.keys(items).sort((a, b) => {
          if (items[a].type !== items[b].type) {
            return items[a].type < items[b].type ? 1 : -1;
          }
          return items[a].title.toLowerCase() > items[b].title.toLowerCase() ? 1 : -1;
        });
        keys.map((itemKey) => {
          itemObject[itemKey] = items[itemKey];
        });
      }
      return itemObject;
    },
    getFileList: async function () {
      const refPath = this.$route.params.itemId || `${this.campgroundKey}/file-mgmt`;
      const files = {};
      const result = await firebase.storage().ref(refPath).listAll();
      result.prefixes.forEach((item) => {
        files[item.fullPath] = {
          title: item.name,
          type: 'folder',
          icon: 'folder_open',
        };
      });
      await Promise.all(
        result.items.map(async (item) => {
          const lastUpdated = (await item.getMetadata()).updated;
          files[item.fullPath] = {
            title: item.name,
            type: 'file',
            icon: 'insert_drive_file',
            lastUpdated,
          };
        })
      );
      return this.sortItemsByTitle(files);
    },
    setAction: async function (action, newKey) {
      console.log('setAction() action', action, newKey ? 'newKey' + newKey : '');
      this.action = action;
      if (this.action == 'add' || action == 'submit' || action == 'cancel' || action == 'reload') {
        this.action = null;
        this.getDataList(this.viewArchived);
      }
      if (this.action == 'filter') {
        this.itemKeys = this.itemKeys.filter((formId) => {
          return formId == this.$route.params.itemId;
        });
        this.action = null;
      } else if (action == 'list') {
        /*else if(this.action == 'add' && action == 'cancel'){
                    this.$delete(this.list, this.rightColumnKey);
                    this.itemKeys = Object.keys(this.list);
                    action = null;
                } else if(action == 'submit' || action == 'cancel'){
                    if(['rewards','store-listing'].includes(this.displayType)){
                        this.getDataList();
                    } else if(newKey){
                        this.list[newKey] = {...this.list[this.rightColumnKey]}
                        this.$delete(this.list, this.rightColumnKey);
                        this.itemKeys = this.itemKeys.filter((value) => {
                            return value !== this.rightColumnKey;
                        });
                        if(this.needsReversed()){
                            this.itemKeys.unshift(newKey);
                        } else {
                            this.itemKeys.push(newKey);
                        }
                    }
                    action = null;
                }*/
        this.action = null;
      }
      switch (action) {
        case 'add-folder':
        case 'add-files':
          this.action = null;
          this.list = {};
          this.itemKeys = [];
          this.getDataList();
          break;
        case 'upload-file':
        case 'create-folder':
          this.rightColumnProps = {
            section: 'actions',
            context: action,
            dbContext: action,
            displayType: 'ManageFiles',
            locations: this.locations,
            tabs: this.tabs,
          };
          this.currentRightColumnComponent = 'ManageFiles';
          this.action = null;
          break;
        case 'header-images': {
          this.rightColumnProps = {
            section: 'actions',
            context: action,
            dbContext: this.headerContextMap[this.dbContext] || this.dbContext,
            visible: true,
            displayType: 'HeaderImages',
            tabs: this.tabs,
            title: this.menuTitle,
          };
          this.currentRightColumnComponent = 'HeaderImages';
          this.action = null;
          break;
        }
        case 'manage-payments':
          this.rightColumnProps = {
            section: 'actions',
            context: 'config/payments',
            dbContext: 'config/payments',
            displayType: 'ManagePayments',
            locations: this.locations,
            tabs: this.tabs,
          };
          this.currentRightColumnComponent = 'ManagePayments';
          break;
        case 'export-data':
          if (this.itemKeys && this.itemKeys.length > 0) {
            var arrData = await this.getArrayData(this.itemKeys, this.list);
            this.csvExport(arrData);
            this.action = null;
          } else {
            this.$message.create({
              title: 'Error',
              body: 'No data to export',
              buttons: [this.$message.button('OK')],
              classes: 'amc-modal',
            });
          }
          break;
        case 'scheduled-notifications':
          this.list = {};
          this.itemKeys = [];
          this.dbContext = 'messages-scheduled';
          if (this.$route && this.$route.params) {
            this.dbContext = this.$route.params.context === 'send-detail-notification' ? 'remote-messages-scheduled' : this.dbContext;
            if (this.$route.params.context == 'systemwide-notification') {
              this.campgroundKey = 'system-data';
              this.locationDataRef = this.getLocationRef('system-data');
            }
          }
          this.action = null;
          this.getDataList();
          break;
        case 'export-files':
        case 'export-photos':
          let exportModal;
          try {
            exportModal = this.$message.create({
              title: 'Exporting',
              body: `<div class="row align-items-center" style="padding-left: 24px">
            <div class="col-auto px-0">
              <div class="spinner-border" role="status"></div>
            </div>
            <div class="col">
              <p class="my-4">Exporting ${this.exportDialog.exporting}... Please wait</p>
            </div>
          </div>`,
              buttons: [this.$message.button('Cancel')],
              classes: 'amc-modal',
            });

            let url = `${this.$apihostname}/api/downloads`;
            let storagePath = `user-written/${this.campgroundKey}/hunts/${this.$route.params.itemId}`;
            this.exportDialog.exporting = 'photos';

            if (action === 'export-files') {
              //storagePath = `${this.campgroundKey}/forms/completed/${this.$route.params.itemId}`;
              storagePath = `user-written/${this.campgroundKey}/forms/${this.$route.params.itemId}`;
              this.exportDialog.exporting = 'files';
              console.log('Export-files path:', storagePath);
            }
            this.action = null;
            const downloadResponse = await axios.post(url, {
              // reading from android code where the images are stored, they are stored in this location.
              storagePath,
              timeout: 120000,
            });

            console.log('🚀 ~ file: ListBuilder.vue:1028 ~ setAction:function ~ downloadResponse', downloadResponse);
            const downloadResponseData = downloadResponse.data;
            if (downloadResponseData.err) {
              setTimeout(() => {
                this.$message.hide(exportModal);

                this.errorMessage = downloadResponseData.message;
                this.$message.create({
                  title: 'Error',
                  body: this.errorMessage,
                  buttons: [this.$message.button('OK')],
                  classes: 'amc-modal',
                });
              }, 500);
            } else {
              setTimeout(() => {
                this.$message.hide(exportModal);

                this.downloadUrl = downloadResponseData.downloadUrl;
                this.$message.create({
                  title: 'Download',
                  body: 'Data was exported successfully. Click Download button to start downloading zip file',
                  buttons: [
                    this.$message.button('Dismiss', 'btn-secondary'),
                    this.$message.button('Download', 'btn-accent', () => {
                      window.open(`${this.$hostname}${this.downloadUrl}`, '_blank', 'noreferrer');
                    }),
                  ],
                  classes: 'amc-modal',
                });
              }, 500);
            }
          } catch (e) {
            this.$message.hide(exportModal);

            if (e.code == 'ECONNABORTED')
              this.errorMessage = 'There are too many files to process. Please contact App My Community to provide these files.';
            else this.errorMessage = 'An error occurred while exporting. Please try again later.';

            this.$message.create({
              title: 'Error',
              body: this.errorMessage,
              buttons: [this.$message.button('OK')],
              classes: 'amc-modal',
            });
          }
          break;
        case 'view-archived':
          this.list = {};
          this.itemKeys = [];
          if (this.dbContext.startsWith('events-')) {
            this.dbContext = `${this.dbContext}-archive`;
            this.displayType = 'events-archive';
          }
          if (this.$route.params.context == 'systemwide-notification') {
            this.campgroundKey = 'system-data';
            this.locationDataRef = this.getLocationRef('system-data');
          }
          this.viewArchived = true;
          this.action = null;
          this.getDataList(true);
          break;
        case 'view-active':
          this.list = {};
          this.itemKeys = [];
          if (this.dbContext == 'messages-scheduled') {
            this.dbContext = 'messages';
          } else if (this.dbContext.startsWith('events-')) {
            this.dbContext = this.dbContext.replace('-archive', '');
            this.displayType = 'events';
          }
          if (this.$route.params.context == 'systemwide-notification') {
            this.campgroundKey = 'system-data';
            this.locationDataRef = this.getLocationRef('system-data');
          }
          this.viewArchived = false;
          this.action = null;
          this.getDataList();
          break;
        case 'in-process':
          this.list = {};
          this.itemKeys = [];
          this.inProcess = true;
          this.action = null;
          this.getDataList();
          break;
        case 'submitted':
          this.list = {};
          this.itemKeys = [];
          this.inProcess = false;
          this.action = null;
          this.getDataList();
          break;
        case 'mark-item':
          const { value, entryId, entry } = newKey;
          let dbRef = this.getUserWrittenLocationContext().child(this.dbContext);
          dbRef = (this.dbContext.startsWith('hunt-submissions') ? dbRef.child(entry.createdBy || entry.uid) : dbRef).child(entryId);

          if (value == 0) {
            dbRef.child('completed').remove();
            dbRef.child('completedBy').remove();
            this.deleteItem(entryId);
          } else {
            dbRef.child('completed').set(value);
            dbRef.child('completedBy').set(firebase.auth().currentUser.uid);
            if (this.view !== 'view-archived') {
              this.deleteItem(entryId);
            } else {
              this.itemKeys.indexOf(entryId) > -1 ? (this.list[this.itemKeys.indexOf(entryId)].completed = value) : null;
            }
          }
        case 'sort-alpha': {
          let submitRef = this.getContextRef();
          const itemArray = this.itemKeys
            .sort((a, b) => {
              const titleA = this.list[a].title || this.list[a].name;
              const titleB = this.list[b].title || this.list[b].name;
              if (titleA < titleB) {
                return -1;
              }
              if (titleA > titleB) {
                return 1;
              }
              return 0;
            })
            .map((key) => this.list[key]);
          submitRef.set(itemArray);
          this.action = null;
          this.getDataList();
          break;
        }
        case 'sort-submit': {
          let submitRef = this.getContextRef();
          submitRef.set(this.listArray);
          this.action = null;
          this.getDataList();
          break;
        }
        case 'sort-cancel':
          this.itemKeys = Object.keys(this.list);
          this.getListArray();
          this.action = null;
          break;
        case 'config.events':
          this.rightColumnProps = {
            section: 'actions',
            context: 'config/events',
            dbContext: 'config/events',
            displayType: 'EventConfig',
            locations: this.locations,
            tabs: this.tabs,
          };
          this.currentRightColumnComponent = 'EventConfig';
          break;
        case 'dollarsconfig':
          console.log('🚀 ~ file: ListBuilder.vue ~ line 959 ~ setAction:function ~ dollarsconfig');
          this.rightColumnProps = {
            section: 'actions',
            context: 'dollarsconfig',
            dbContext: 'dollarsconfig',
            displayType: 'DollarsConfig',
            locations: this.locations,
            tabs: this.tabs,
          };
          this.currentRightColumnComponent = 'DollarsConfig';
          break;
        case 'splashconfig':
          this.rightColumnProps = {
            section: 'actions',
            context: 'splashconfig',
            dbContext: 'splashconfig',
            displayType: 'SplashConfig',
            locations: this.locations,
            tabs: this.tabs,
          };
          this.currentRightColumnComponent = 'SplashConfig';
          break;
        case 'addmaps':
          this.rightColumnProps = {
            section: 'actions',
            context: this.context,
            dbContext: this.dbContext,
            displayType: this.displayType,
          };
          this.currentRightColumnComponent = 'MapListItem';
          break;
        case 'add':
        case 'add-button':
        case 'duplicate':
        case 'update-map': {
          let newObject = action == 'duplicate' ? { ...this.list[newKey] } : {};
          if (action == 'duplicate') {
            this.$delete(newObject, 'effectiveTime');
            this.$delete(newObject, 'endTime');
            this.$delete(newObject, 'deliveryTime');
            this.$delete(newObject, 'recurrenceId');
            this.$delete(newObject, 'recurrenceInfo');
          } else if (newObjectMap[this.displayType] && newObjectMap[this.displayType].newObject) {
            newObject = JSON.parse(JSON.stringify(newObjectMap[this.displayType].newObject));
          }
          if (action == 'update-map' && this.itemKeys.length > 0) {
            this.rightColumnKey = this.itemKeys[0];
          } else {
            this.action = 'add';
            this.rightColumnKey = Date.now().toString();
            if (this.needsReversed()) {
              this.itemKeys.unshift(this.rightColumnKey);
            } else {
              this.itemKeys.push(this.rightColumnKey);
            }
            this.list[this.rightColumnKey] = newObject;
          }
        }
        //fallsthrough
        case 'edit':
          this.rightColumnProps = {
            dbContext: this.dbContext,
            item: this.list[this.rightColumnKey],
            rightColumnKey: this.rightColumnKey || '',
            action: this.action,
          };
          if (['topic', 'user-group'].includes(this.dbContext)) {
            this.rightColumnProps.isSystemWide = this.isSystemWide;
          }

          if (['rewards', 'events', 'categorygrid', 'store-listing'].includes(this.displayType)) {
            this.rightColumnProps.itemsList = this.list;
          }
          if (newObjectMap[this.displayType] && newObjectMap[this.displayType].detailComponent) {
            this.currentRightColumnComponent = newObjectMap[this.displayType].detailComponent;
            return;
          }
          break;
        default:
          this.rightColumnProps = {
            buttonSection: 'actions',
            context: this.context,
            dbContext: this.dbContext,
            displayType: this.displayType,
          };
          if (this.displayType) {
            this.currentRightColumnComponent = 'ButtonNavigation';
          }
      }
    },
    // showDownloadModal(downloadUrl) {
    //   this.downloadUrl = downloadUrl;
    //   this.$refs.downloadModal.show();
    // },
    // downloadClick(downloadUrl) {
    //   window.open(`${this.$hostname}${downloadUrl}`, '_blank', 'noreferrer');
    // },
    // showErrorModal(message) {
    //   this.errorMessage = message;
    //   this.$refs.errorModal.show();
    // },
    filterFolder(filterIds) {
      this.list = { ...this.filterList };
      this.itemKeys = Object.keys(this.list);
      if (filterIds) {
        //Remove id from `list` if it's not in filterIds array.
        for (const id in this.list) {
          if (!filterIds.includes(id)) {
            delete this.list[id];

            let index = this.itemKeys.indexOf(id);
            if (index !== -1) {
              this.itemKeys.splice(index, 1);
            }
          }
        }
      }
      this.getListArray();
    },
    getSortKey(item) {
      switch (this.displayType) {
        case 'categorygrid':
          return item.uniqueId;
        case 'contactinfo':
          return item.name;
        case 'simple':
          return item.title;
        case 'business':
          return item.id + item.title;
        default:
          return item.id;
      }
    },
  },
};
</script>

<style>
#tabs {
  display: flex;
  width: 100%;
}

.pointer,
#tabs div,
#menu-items > li:hover,
.item label {
  cursor: pointer;
}
.tab.selected {
  border-bottom: 4px solid;
}
.tab {
  color: white;
  text-align: center;
  float: left;
  padding-top: 16px;
  padding-bottom: 16px;
  flex-basis: 100%;
}

#items {
  list-style: none;
  color: white;
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

#items li {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 10px;
}

#items li > div {
  border-bottom: 1px solid black;
  padding: 8px 16px;
}
#items li ul li {
  display: list-item;
  list-style: disc;
}
#items li ol li {
  display: list-item;
}
#grid-container {
  min-height: 500px;
  list-style-type: none;
  margin: 0px;
  padding: 10px 0px;
  display: grid;
  grid-template-columns: repeat(3, minmax(80px, 1fr));
  grid-gap: 20px;
}

.list-item {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 10px;
  cursor: pointer;
}

.hide-actions i:not(.expand) {
  display: none;
}

.form-control.user-filter {
  width: 95%;
  margin: 0.5rem auto;
}

.simple-title {
  cursor: pointer;
  line-height: 25px;
}

.simple-title .expand::after {
  content: 'expand_more';
}

.simple-title[aria-expanded='true'] .expand::after {
  content: 'expand_less';
}

#listBuilderCarousel .carousel-indicators li {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  height: 4px;
  margin: 0 8px 0;
  opacity: 1;
  padding: 3px;
  position: relative;
  width: 4px;
}

#listBuilderCarousel .carousel-indicators li::after {
  border: none;
  /*background-color: transparent;*/
  /*border-radius: 50%;*/
  bottom: -7px;
  content: '';
  left: -7px;
  padding: 10px;
  position: absolute;
  right: -7px;
  top: -7px;
}

#listBuilderCarousel .carousel-indicators li.active {
  background-color: white;
}

#listBuilderCarousel .carousel-caption {
  background-color: #1b1b1bcc;
  padding: 0;
  bottom: -16px;
}

#listBuilderCarousel .carousel-inner {
  height: 261px;
}

#listBuilderCarousel .carousel-item img {
  object-fit: cover;
  height: 261px;
}
</style>
