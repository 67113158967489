<template>
  <div>
    <div class="row">
      <div class="col-12 col-xxl-7">
        <div class="mb-3">
          <label class="form-label">Title</label>
          <input
            type="text"
            class="form-control"
            v-model="newItem.title"
            :class="{ 'is-invalid': vuelidate$.newItem.title.$dirty && vuelidate$.newItem.title.$error }"
            trim />
          <Validation :validator="vuelidate$.newItem.title" feedback="Title is required." />
        </div>

        <div class="mb-3">
          <label class="form-label">Action</label>
          <select
            class="form-select"
            v-model="newItem.action"
            :class="{ 'is-invalid': vuelidate$.newItem.action.$dirty && vuelidate$.newItem.action.$error }">
            <option :value="null" disabled hidden>-- Select Action --</option>
            <option v-for="action in actions" :key="action.value" :value="action.value">{{ action.text }}</option>
          </select>
          <Validation :validator="vuelidate$.newItem.action" feedback="Action is required." />
        </div>
        <div class="row">
          <div class="col">
            <SelectList
              v-if="newItem.action == 'amc-events'"
              v-model="newItem.actionKey"
              dbContext="amc-events/config"
              label="Calendar"
              :state="vuelidate$.newItem.actionKey.$dirty ? !vuelidate$.newItem.actionKey.$error : true"
              @select="(val) => (newItem.actionKey = val)" />

            <SelectList
              v-if="newItem.action == 'hunt'"
              v-model="newItem.actionKey"
              dbContext="hunts"
              label="Scavenger Hunt"
              :state="vuelidate$.newItem.actionKey.$dirty ? !vuelidate$.newItem.actionKey.$error : true"
              keyName="key"
              orderBy="title"
              @select="(val) => (newItem.actionKey = val)" />
            <SelectList
              v-if="newItem.action == 'form'"
              v-model="newItem.actionKey"
              dbContext="custom-forms"
              label="Form"
              :state="vuelidate$.newItem.actionKey.$dirty ? !vuelidate$.newItem.actionKey.$error : true"
              orderBy="title"
              @select="(val) => (newItem.actionKey = val)" />

            <SelectList
              v-if="newItem.action == 'store'"
              v-model="newItem.actionKey"
              dbContext="store"
              label="Store Item"
              :state="vuelidate$.newItem.actionKey.$dirty ? !vuelidate$.newItem.actionKey.$error : true"
              keyName="key"
              orderBy="title"
              @select="(val) => (newItem.actionKey = val)" />

            <SelectList
              v-if="newItem.action == 'mainMenu'"
              v-model="newItem.actionKey"
              dbContext="main-menu"
              label="Home Screen Item"
              :state="vuelidate$.newItem.actionKey.$dirty ? !vuelidate$.newItem.actionKey.$error : true"
              keyName="uniqueId"
              orderBy="name"
              @select="(val) => (newItem.actionKey = val)" />

            <SelectList
              v-if="newItem.action == 'booking'"
              v-model="newItem.actionKey"
              dbContext="bookings"
              label="Booking Item"
              :state="vuelidate$.newItem.actionKey.$dirty ? !vuelidate$.newItem.actionKey.$error : true"
              orderBy="name"
              @select="(val) => (newItem.actionKey = val)" />

            <!--SelectList
              v-if="newItem.action == 'map'"
              v-model="newItem.actionKey"
              dbContext="locations"
              label="Community Locations"
              :state="vuelidate$.newItem.actionKey.$dirty ? !vuelidate$.newItem.actionKey.$error : null"
              keyName="searchableName"
              orderBy="name"
            /-->

            <MapList
              v-if="newItem.action == 'searchablemap'"
              v-model="mapKey"
              dbContext="searchablemap-detail"
              label="Select Map/Image"
              :state="vuelidate$.newItem.actionKey.$dirty ? !vuelidate$.newItem.actionKey.$error : true"
              orderBy="title"
              @input="(val) => (newItem.actionKey = val)" />
            <!--MapList
              v-if="mapKey"
              v-model="locationKey"
              :dbContext="`searchablemap-detail/${mapKey}/locations`"
              label="Select Map Location"
              keyName="key"
              :state="vuelidate$.newItem.actionKey.$dirty ? !vuelidate$.newItem.actionKey.$error : null"
              orderBy="title"
            /--->

            <FileList
              v-if="newItem.action == 'file'"
              v-model="newItem.actionKey"
              :state="vuelidate$.newItem.actionKey.$dirty ? !vuelidate$.newItem.actionKey.$error : true"
              @input="(val) => (newItem.actionKey = val)" />
            <div class="mb-3" v-if="newItem.action === `file`">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="use-web-view" v-model="useWebView" />
                <label class="form-check-label" for="use-web-view">Use Web View</label>
              </div>
              <div class="form-text">Use a web view to make the file open up inside the app. Otherwise the file will open in Safari/Chrome.</div>
            </div>

            <div class="mb-3" v-if="newItem.action === `website` && !newItem.fromFile">
              <label class="form-label">URL</label>
              <input
                type="text"
                class="form-control"
                id="url"
                v-model="newItem.actionKey"
                :class="{ 'is-invalid': vuelidate$.newItem.actionKey.$dirty && vuelidate$.newItem.actionKey.$error }"
                placeholder="http://www.google.com" />
              <Validation :validator="vuelidate$.newItem.actionKey" map="url" />
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="use-web-view" v-model="useWebView" />
                <label class="form-check-label" for="use-web-view">Use Web View</label>
              </div>
              <div class="form-text">
                Use a web view to make the website open up while looking like it is still inside the app. Otherwise the website will open in
                Safari/Chrome.
              </div>
            </div>

            <div class="mb-3" v-if="newItem.action === `mailto`">
              <label class="form-label">Email Address</label>
              <input
                type="text"
                class="form-control"
                id="email"
                v-model="newItem.actionKey"
                :class="{ 'is-invalid': vuelidate$.newItem.actionKey.$dirty && vuelidate$.newItem.actionKey.$error }" />
              <Validation :validator="vuelidate$.newItem.actionKey" map="email" />
            </div>
            <div class="mb-3" v-if="newItem.action === `call`">
              <label class="form-label">Phone Number</label>
              <input
                type="text"
                class="form-control"
                id="phoneNumber"
                v-model="newItem.actionKey"
                :class="{ 'is-invalid': vuelidate$.newItem.actionKey.$dirty && vuelidate$.newItem.actionKey.$error }" />
              <Validation :validator="vuelidate$.newItem.actionKey" map="phone" />
            </div>
          </div>
        </div>

        <div id="icon" class="form-group mb-3">
          <label class="form-label" for="icon" required>Icon</label>
          <cool-select
            v-if="!getIconSet().startsWith('svg')"
            v-model="newItem.icon"
            :items="icons"
            :placeholder="newItem.icon ? '' : 'Select Icon'"
            item-value="icon"
            item-text="icon"
            :error-message="vuelidate$.newItem.icon.$error ? 'Icon is required.' : null"
            :successful="vuelidate$.newItem.icon.$dirty ? !vuelidate$.newItem.icon.$error : null"
            disable-filtering-by-search
            :inputStyles="getInputStyles">
            <!-- slot for each item in the menu -->
            <template #item="{ item: icon }">
              <div :style="getBackgroundColorStyle" :class="getButtonClass(icon.icon, 'p-1')">
                <DetailButton :buttonIcon="icon.icon" :buttonText="newItem.title" :buttonColor="getAccentColor" />
              </div>
            </template>
            <!-- slot for the selected item (in the text field) -->
            <template #selection="{ item: icon }">
              <div :style="getBackgroundColorStyle" :class="getButtonClass(icon.icon)">
                <DetailButton :buttonIcon="icon.icon" :buttonText="newItem.title" :buttonColor="getAccentColor" />
              </div>
            </template>
            <template #input-end>
              <span v-if="vuelidate$.newItem.icon.$error" style="margin-right: 8px"
                ><img
                  style="width: calc(0.75em + 0.375rem); height: calc(0.75em + 0.375rem)"
                  src="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'><path stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/><circle r='.5'/><circle cx='3' r='.5'/><circle cy='3' r='.5'/><circle cx='3' cy='3' r='.5'/></svg>"
              /></span>
            </template>
          </cool-select>
          <div v-else>
            <div :class="{ 'is-invalid': vuelidate$.newItem.iconData.svg.$dirty && vuelidate$.newItem.iconData.svg.$error }">
              <IconList ref="iconList" v-model="newItem.iconData" :title="newItem.title" icon-only />
            </div>
            <Validation :validator="vuelidate$.newItem.iconData.svg" feedback="Icon is required." />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-xxxl-8">
            <button class="btn btn-secondary" type="button" @click="addItem">Add Button</button>
          </div>
        </div>
      </div>
      <div class="col">
        <Sortable
          v-if="localItems && localItems.length > 0"
          :list="items"
          item-key="title"
          tag="ul"
          class="editable-list"
          :options="sortableOptions"
          @end="(evt) => moveItems(evt, items)">
          <template #item="{ element, index }">
            <!-- <li v-for="(item, index) in localItems" :key="item._id" class="single-row"> -->
            <li class="single-row">
              <div :style="getAccentColorStyle">
                <DetailButton :buttonIcon="element.icon" :buttonText="element.title" :svg="element.iconData ? element.iconData.svg : null" />
              </div>
              <i class="item-remove material-icons" @click="removeItem(index)">delete</i>
            </li>
          </template>
        </Sortable>
      </div>
    </div>
  </div>
</template>

<script>
import { Sortable } from 'sortablejs-vue3';
import { required, requiredIf } from '@vuelidate/validators';
import SelectList from '../inputfields/SelectList';
import FileList from '../inputfields/FileList';
import MapList from '../inputfields/MapList';
import IconList from '../inputfields/IconList';
import DetailButton from '../DetailButton';
import { CoolSelect } from 'vue-cool-select';

import { icons } from '../../json/materialIcons.json';

export default {
  components: {
    Sortable,
    SelectList,
    CoolSelect,
    DetailButton,
    FileList,
    MapList,
    IconList,
  },
  props: {
    items: {
      type: Array,
    },
    title: {
      type: String,
    },
    buttonTitle: {
      type: String,
    },
    isRemote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localItems: [],
      newItem: {
        action: null,
      },
      mapKey: null,
      locationKey: null,
      useWebView: false,
      actions: [
        { value: 'amc-events', text: 'Open Calendar', allowRemote: false, requiresKey: false },
        { value: 'store', text: 'Open Store Item', allowRemote: false, requiresKey: true },
        { value: 'form', text: 'Open Custom Form', allowRemote: false, requiresKey: true },
        { value: 'mainMenu', text: 'Open Main Menu Item', allowRemote: false, requiresKey: true },
        { value: 'searchablemap', text: 'Open Map/Image', allowRemote: false, requiresKey: true },
        { value: 'booking', text: 'Open Booking Item', allowRemote: false, requiresKey: true },
        { value: 'file', text: 'Open File', allowRemote: false, requiresKey: true },
        { value: 'website', text: 'Open Website', allowRemote: true, requiresKey: true },
        { value: 'hunt', text: 'Open Scavenger Hunt', allowRemote: false, requiresKey: true },
        { value: 'call', text: 'Dial Number', allowRemote: true, requiresKey: true },
        { value: 'mailto', text: 'Send Email', allowRemote: true, requiresKey: true },
      ],
      icons: icons,
    };
  },
  computed: {
    getInputStyles: function () {
      return { 'background-color': this.getBackgroundColor };
    },
    sortableOptions() {
      return {
        animation: 150,
        easing: 'cubic-bezier(1, 0, 0, 1)',
      };
    },
  },
  watch: {
    item: function () {
      this.phoneNumber = this.item.replace(/\D+/g, '');
    },
    items: function () {
      this.localItems = this.items;
    },
    'newItem.action': function (newValue, oldValue) {
      this.useWebView = newValue != oldValue && newValue == 'file';
    },
    mapKey: function () {
      this.newItem.actionKey = this.mapKey.mapKey;
      this.newItem.actionParams = this.mapKey.locations;
    },
  },
  validations() {
    return {
      newItem: {
        title: {
          required: requiredIf(function () {
            return this.newItem.action || this.newItem.iconData.svg;
          }),
        },
        icon: {
          required: requiredIf(function () {
            return !this.getIconSet().startsWith('svg');
          }),
        },
        iconData: {
          svg: {
            required: requiredIf(function () {
              return this.getIconSet().startsWith('svg');
            }),
          },
        },
        action: {
          required: requiredIf(function () {
            return this.newItem.title || this.newItem.iconData;
          }),
        },
        actionKey: {
          required: requiredIf(function () {
            return this.actions.filter((x) => this.newItem.action === x.value)[0].requiresKey;
          }),
        },
      },
    };
  },
  created() {
    this.intButtonTitle = this.buttonTitle || this.intButtonTitle;
    this.intTitle = this.title;
    this.localItems = this.items || [];
    console.info('Local Items:', this.localItems);
    if (this.isDollarsEnabled) {
      this.actions.push({ value: 'dollars_transfer', text: 'Transfer Dollars', allowRemote: false, requiresKey: false });
      this.actions.push({ value: 'dollars_purchase', text: 'Purchase Dollars', allowRemote: false, requiresKey: false });
    }
    if (this.isRemote) {
      this.actions = this.actions.filter((x) => x.allowRemote);
    }
  },
  methods: {
    getButtonClass: function (icon, classes) {
      if (icon == 'cio' && this.$fbProject != 'campinginontario' && this.$fbProject != 'campon') {
        return 'd-none';
      }
      return classes || '';
    },
    addItem() {
      this.newItem.icon = this.newItem.iconData && this.newItem.iconData.svg && !this.newItem.icon ? 'update_app' : this.newItem.icon;

      this.vuelidate$.$touch();
      if (!this.vuelidate$.$invalid) {
        this.vuelidate$.$reset();
        this.newItem.action =
          (this.newItem.action == 'website' || this.newItem.action == 'file') && this.useWebView
            ? `${this.newItem.action}-webview`
            : this.newItem.action;
        if (this.newItem.action === 'mailto') {
          this.newItem.action = 'website';
          this.newItem.actionKey = `mailto:${this.newItem.actionKey}`;
        }
        this.localItems.push(this.newItem);
        this.newItem = {
          action: null,
        };
        this.$refs.iconList.resetIcons();
        this.$refs.iconList.iconSelected = null;
        //this.$emit('updateList', this.localItems);
      }
    },
    checkAdd() {
      //Checks if all of the neccessary fields are filled out before adding the item
      if (this.newItem.title && this.newItem.action && this.newItem.actionKey && this.newItem.iconData.svg) {
        this.addItem();
      }
    },
    removeItem(index) {
      this.localItems.splice(index, 1);
      //this.$emit('updateList', this.localItems);
    },
    moveItems(evt, array) {
      const item = array.splice(evt.oldIndex, 1)[0];
      array.splice(evt.newIndex, 0, item);
    },
  },
};
</script>

<style scoped>
#icon .IZ-select__menu-items .IZ-select__item:hover {
  background-color: initial;
}
</style>
