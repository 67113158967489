<template>
  <div>
    <button type="button" class="btn btn-secondary ai-action-button" @click="generate()" v-tooltip-top="tooltip">
      <div v-if="!spinner">
        <i v-if="icon" class="material-icons ai-action-button-title">{{ icon }}</i>
        <p v-else class="ai-action-button-title">{{ name }}</p>
      </div>
      <div v-else class="spinner-border" role="status"></div>
    </button>
  </div>
</template>

<script>
import OpenAIMixin from '../mixins/OpenAIMixin';

export default {
  name: 'TextAIButton',
  props: ['icon', 'name', 'tooltip', 'preMsg', 'postMsg', 'text'],
  mixins: [OpenAIMixin],
  data() {
    return {
      spinner: false,
    };
  },
  created() {
    this.isDisabled = true;
  },
  computed: {
    iconOrName() {
      if (this.icon) return this.icon;
      if (this.name) return this.name;
      return '⚠️';
    },
  },
  methods: {
    async generate() {
      const message = `${this.preMsg}: '${this.text}'. ${this.postMsg}`;
      if (this.spinner === false) {
        console.log(`Prompting: ${message}`);
        const result = await this.promptGPT(message, 'gpt-3.5-turbo-1106', (val) => {
          this.spinner = val;
          //this.$forceUpdate();
        });
        const promptText = this.getPromptText(result);

        console.log('promptText', promptText);
        if (promptText.startsWith('"') && promptText.endsWith('"')) {
          this.$emit('action', promptText.substring(1, promptText.length - 1));
        } else {
          this.$emit('action', promptText);
        }
      }
    },
  },
};
</script>

<style scoped>
.ai-action-button {
  margin: 1px 0px 0px 6px;
  padding: 2px;
  width: 36px;
  height: 36px;
}
.ai-action-button-title {
  margin: 2px;
  padding: 0px;
}
.spinner-border {
  width: 70%;
  height: 70%;
  margin: 4px;
}
</style>
