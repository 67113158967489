<template>
  <div>
    <b-modal
      :id="`event-select-modal-${id}`"
      :ref="`event-select-modal-${id}`"
      title="Select Event"
      size="xl"
      classes="amc-modal"
      :scrollable="true"
      :buttons="[
        { title: 'Cancel', variant: 'btn-secondary', action: () => {} },
        { title: 'Use Event', variant: 'btn-accent', action: () => emitEvent() },
      ]"
      @ready="(m) => (modal = m)"
      @scroll-bottom="nextChunk">
      <template #body>
        <FilterList
          @filterText="(val) => (filterText = val)"
          @filterDate="
            (start, end) => {
              filterStartDate = start;
              filterEndDate = end;
            }
          "
          :external-filters="recurrenceId ? true : false"
          :showDefault="true"
          @ready="(ready) => (filterReady = ready)" />
        <div v-for="(event, index) in chunkList" :key="index" class="ms-3 me-3" style="height: 60px">
          <div :class="index % 2 === 0 ? 'd-flex event-select-item' : 'd-flex event-select-item event-select-item-alt'" @click="selectEvent(index)">
            <div class="event-select-radio">
              <input type="radio" v-model="selectedKey" :value="chunkKeys[index]" :id="`event-select-radio-${index}`" @click="selectEvent(index)" />
            </div>
            <div class="event-select-item-title">{{ event.title }}</div>
            <div class="event-select-item-date">
              {{ getFormattedDay(parseFloat(event.startDate)) }} {{ getFormattedTime(parseFloat(event.startDate)) }}
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <Overlay text="Loading events" :showProp="showOverlay" />
  </div>
</template>

<script>
import BModal from '@/components/common/BModal.vue';
import InfiniteScrollMixin from '@/components/mixins/InfiniteScrollMixin.js';
import FilterList from '@/components/inputfields/FilterList.vue';
import Overlay from '@/components/common/Overlay.vue';

export default {
  props: {
    calendarKey: {
      type: String,
      default: null,
    },
    eventKey: {
      type: String,
      default: null,
    },
  },
  components: {
    BModal,
    FilterList,
    Overlay,
  },
  mixins: [InfiniteScrollMixin],
  watch: {
    calendarKey: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.clearChunks();
          this.setDatabasePath(this.getLocationRef().child('amc-events').child('calendars'), this.calendarKey + '/events');
          this.nextChunk();
        }
      },
    },
    eventKey: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.selectedKey = newVal;
        }
      },
    },
  },
  data() {
    return {
      modal: null,
      selectedEvent: null,
      selectedKey: null,
      dbRef: null,
      subsequentChunkSize: 20,
    };
  },
  created() {},
  mounted() {
    this.$emit('ready', this.modal);
  },
  methods: {
    //Mixin function overrides
    setLastKey(result) {
      this.lastLoadedKey = result.list.length > 0 ? result.list[result.list.length - 1].startDate : null;
    },
    chunkQuery(query) {
      query = query.orderByChild('startDate');
      if (this.lastLoadedKey) query = query.startAt(this.lastLoadedKey);
      query = query.limitToFirst(this.chunkSize);
      return query;
    },
    checkFilters(item) {
      const results = [];
      //Text filters
      if (this.filterText) {
        const textResults = [];
        const text = this.filterText.toLowerCase();
        textResults.push(this.isFilterContains(item.title, text));
        textResults.push(this.isFilterContains(item.description, text));

        //Add true to result if textResults has any true values.
        results.push(textResults.some((result) => result));
      }

      //Start/end date filters
      if (this.filterStartDate) {
        if (item.endDate > this.filterStartDate) results.push(true);
        else results.push(false);
      }
      if (this.filterEndDate) {
        if (item.endDate + item.endDateDuration < this.filterEndDate) results.push(true);
        else results.push(false);
      }
      if (this.recurrenceId) results.push(item.recurrenceId === this.recurrenceId);

      return results.every((result) => result);
    },
    loadChunksFromData(data) {
      data.forEach((child) => {
        this.chunkKeys.push(child.key);
        this.chunkList.push(child.val());
      });
      //Sort chunkKeys/chunkList by chunkList[key].startDate.

      this.chunkList.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    },

    //Modal functions
    selectEvent(index) {
      this.selectedEvent = this.chunkList[index];
      this.selectedKey = this.chunkKeys[index];
    },
    emitEvent() {
      this.$emit('selected', { key: this.selectedKey, val: this.selectedEvent });
    },
  },
};
</script>

<style scoped>
.event-select-item {
  cursor: pointer;
  align-items: center;

  height: 60px;
}
.event-select-item-alt {
  background-color: var(--bs-primary);
}

.event-select-item-title {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.event-select-item-date {
  padding-right: 10px;
}
</style>
