<template>
  <div>
    <slot name="description">
      <p>In this section you can grant individuals, outside of app admins, access to update this detail page.</p>
      <p>This is useful for businesses, clubs, committees, etc.</p>
      <p>When enabled, anyone with the URL(s) created below will have access to modify this specific detail page.</p>
    </slot>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" v-model="remotelyManaged" id="remoteManagementCheck" />
      <label class="form-check-label" for="remoteManagementCheck"> Allow Remote Management </label>
    </div>
    <Sortable
      v-if="remotelyManaged"
      :list="remoteInfo"
      :item-key="
        (item) => {
          return item;
        }
      "
      tag="ul"
      class="editable-list"
      :options="sortableOptions"
      @end="(evt) => moveItems(evt, remoteInfo)">
      <template #item="{ element, index }">
        <li v-if="element != null" class="editable-list-item">
          <i class="item-remove material-icons" @click="removeRemoteLink(index)">delete</i>
          <div class="mb-3" v-if="!isDollarsEnabled">
            <label class="form-label">Grant Access By:</label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                v-model="element.accessType"
                :name="`accessType${index}`"
                value="email"
                :id="`emailLoginRadio-${index}`" />
              <label class="form-check-label" :for="`emailLoginRadio-${index}`">Email Login</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                v-model="element.accessType"
                :name="`accessType${index}`"
                value="url"
                :id="`urlLoginRadio-${index}`" />
              <label class="form-check-label" :for="`urlLoginRadio-${index}`">URL(No Login Required)</label>
            </div>
          </div>
          <div v-if="element.accessType === 'url' || !element.accessType">
            <div class="mb-3">
              <label class="form-label">Relationship</label>
              <input type="text" class="form-control" v-model="element.title" placeholder="e.g. Owner, Manager, Staff" />
            </div>
            <button :id="'copy' + index" class="btn m-1 btn-primary" type="button" @click="copyRemoteURL(index)">
              Copy Link for Remote Management
            </button>
          </div>
          <div v-else>
            <div class="mb-3">
              <label class="form-label">User's Name</label>
              <input type="text" class="form-control" v-model="element.name" />
            </div>
            <div class="mb-3">
              <label class="form-label">Email Address</label>
              <input type="text" class="form-control" v-model="element.email" />
            </div>
          </div>
        </li>
      </template>
    </Sortable>
    <button v-if="remotelyManaged" class="btn m-1 btn-secondary" type="button" @click="addRemoteUUID">Add Additional Access</button>
  </div>
</template>

<script>
import Vue from 'vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import uuidv4 from 'uuid/v4';

import { Sortable } from 'sortablejs-vue3';

export default {
  props: {
    path: {
      type: String,
      default: '/detail/remote/',
      required: true,
    },
    remoteKey: {
      type: String,
      default: null,
    },
    dbContext: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'business',
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
  components: {
    Sortable,
  },
  mixins: [],
  data() {
    return {
      keyPath: null,
      remoteRef: null,
      remotelyManaged: false,
      remoteInfo: {},
      origRemoteIds: [],
      currentCommunityKey: null,
      internalRemoteKey: null,
    };
  },
  watch: {
    remotelyManaged() {
      if (this.remotelyManaged && Object.keys(this.remoteInfo).length === 0) {
        this.remoteInfo = {};
        this.addRemoteUUID();
      }
    },
    remoteKey() {
      if (this.type == 'calendar' && this.remoteKey) {
        this.internalRemoteKey = this.remoteKey;
        this.getRemoteInfo();
      }
    },
  },
  created() {
    this.currentCommunityKey = this.getCampgroundKey;
  },
  async mounted() {
    this.remoteRef = firebase.database().ref('resort-navigator').child('remote-management');
  },
  methods: {
    getRemoteInfo() {
      this.remoteRef
        .child('detailId')
        .child(this.internalRemoteKey)
        .once('value')
        .then((uuidSnap) => {
          this.remoteInfo = uuidSnap.val() || {};
          this.origRemoteIds = Object.keys(this.remoteInfo);
          this.remotelyManaged = this.origRemoteIds.length > 0;
          const uids = this.origRemoteIds.filter((x) => this.remoteInfo[x].uid).map((x) => this.remoteInfo[x].uid);
          const getUserData = firebase.functions().httpsCallable('getUserData');
          getUserData({ uids }).then((results) => {
            this.origRemoteIds.forEach((id) => {
              if (this.remoteInfo[id].uid) {
                Vue.set(this.remoteInfo[id], 'name', results.data[id].displayName);
                Vue.set(this.remoteInfo[id], 'email', results.data[id].email);
              }
            });
          });
          console.log('remoteInfo', this.remoteInfo);
        })
        .catch(() => null);
    },
    addRemoteUUID() {
      this.getDetailId();
      const uuid = uuidv4();
      Vue.set(this.remoteInfo, uuid, {
        type: this.type,
        contexts: [this.dbContext],
        title: '',
        editInfo: true,
        accessType: this.isDollarsEnabled ? 'email' : 'url',
        origContext: this.currentCommunityKey,
        type: this.type,
      });
      this.origRemoteIds.push(uuid);
    },
    removeRemoteLink(key) {
      Vue.delete(this.remoteInfo, key);
    },
    getDetailId() {
      if (this.internalRemoteKey) return this.internalRemoteKey;
      else {
        this.internalRemoteKey = this.remoteRef.push().key;
        this.$emit('update:remoteKey', this.internalRemoteKey);
        return this.internalRemoteKey;
      }
    },
    copyRemoteURL(uuid) {
      if (this.type == 'calendar') this.$copyText(`${this.$hostname}${this.path}${uuid}`);
    },

    async update() {
      const updateUID = (remoteUpdates, uid, value) => {
        remoteUpdates[`uid/${uid}/${this.internalRemoteKey}`] = value;
        remoteUpdates[`detailId/${this.internalRemoteKey}/${uid}`] = value;
      };
      const remoteUpdates = {};
      this.origRemoteIds.forEach((uid) => updateUID(remoteUpdates, uid, null));

      if (this.remotelyManaged) {
        const itemKeys = Object.keys(this.remoteInfo);
        for (let index = 0; index < itemKeys.length; index++) {
          var itemId = itemKeys[index];
          const remoteInfo = { ...this.remoteInfo[itemId], contexts: [this.dbContext], detailTitle: this.title };
          if (remoteInfo.email && !remoteInfo.uid) {
            const getUserData = firebase.functions().httpsCallable('getUserData');
            itemId = (
              await getUserData({
                type: 'uid',
                email: remoteInfo.email,
                name: remoteInfo.name || '',
                template: 'sendEmailLinkDetailAdmin',
                detailTitle: this.title,
                vueHost: this.$vueHost,
              })
            ).data;
            remoteInfo.uid = itemId;
          }
          delete remoteInfo.email;
          delete remoteInfo.name;
          updateUID(remoteUpdates, itemId, remoteInfo);
        }
      }
      if (Object.keys(remoteUpdates).length > 0) {
        this.remoteRef.update(remoteUpdates).catch((err) => {
          console.log('🚀 ~ file: RemoteManagement.vue ~ line 218 ~ update:function ~ err', err);
        });
        console.log('🚀 ~ file: RemoteManagement.vue ~ line 221 ~ update:function ~ remoteUpdates', remoteUpdates);
      }
    },
  },
};
</script>

<style scoped></style>
