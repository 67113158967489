<template>
  <div :class="itemHasPassedClass()">
    <div v-if="displayDate()" class="list-item text-center">{{ getFormattedDay(parseFloat(itemId)) }}</div>
    <div @click="eventDetail" class="list-item">
      <div class="main-text">
        <input
          v-if="bulkSelected"
          type="checkbox"
          class="bulk-select"
          v-model="bulkCheck"
          :checked="bulkSelected.includes(itemId)"
          @click.stop="bulkCheckItem" />
        <div class="item-info">
          <div class="item-title">
            {{ item.title }}
          </div>
          <div class="item-subtitle small">@{{ getLocation(item) }}</div>
        </div>
      </div>
      <div>
        {{ getFormattedTime(parseFloat(itemId)) }}
      </div>
      <i v-if="item.rsvpRequest" class="material-icons" @click.stop="openRSVP">person_add</i>
      <i class="material-icons show">edit</i>
      <i class="material-icons show" v-if="this.item.endDate >= Date.now()" @click.stop="createNotification">add_alert</i>
      <i class="material-icons show" @click.stop="duplicateItem">content_copy</i>
      <i class="material-icons show" @click.stop="deleteItem">delete</i>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item', 'itemId', 'locations', 'dbContext', 'view', 'prevItemId', 'isArchived', 'bulkSelected'],
  data() {
    return {
      bulkCheck: false,
    };
  },
  mounted() {
    this.detailRouteType = this.isArchived ? 'legacyEventsArchived' : 'legacyEvents';
  },
  methods: {
    getLocation: function (item) {
      if (!item.resortLocationId) {
        return item.location;
      }
      return this.locations[item.resortLocationId] ? this.locations[item.resortLocationId].title : '';
    },
    eventDetail: function () {
      this.$router.push({ name: 'detailEdit', params: { type: this.detailRouteType, dbContext: this.dbContext, itemId: this.itemId } });
    },
    displayDate: function () {
      return !this.prevItemId || this.getFormattedDay(parseFloat(this.itemId)) !== this.getFormattedDay(parseFloat(this.prevItemId));
    },
    openRSVP: function () {
      this.$router.push({
        name: 'detailAction',
        params: { type: this.detailRouteType, dbContext: this.dbContext, itemId: this.itemId, detailAction: 'rsvp' },
      });
    },
    createNotification: function () {
      const newItem = {
        //If this title or description format/template are changed, update them in Event as well (updateNotifications).
        title: `Reminder: ${this.item.title}${this.getLocation(this.item) ? ' @' + this.getLocation(this.item) : ''}`,
        //Strips HTML tags and special characters from description.
        description: this.item.description
          ?.replace(/<br>/g, '\n\n')
          .replace(/<[^>]+>/g, '')
          .replace(/&[^\s]*;/g, ''),
        userGroup: 'currentGuest',
        location: this.getLocation(this.item),
        startDate: this.item.endDate - this.item.endDateDuration,
        endDate: this.item.endDate,
        isFromEvent: true,
        eventId: this.itemId,
      };
      this.pushToNotification(newItem);
    },
    deleteItem: function () {
      if (this.item.recurrenceId) {
        this.displayRecurrenceConfirmationModal();
      } else {
        this.displayNormalConfirmationModal();
      }
      /**/
    },
    getRecurrenceDataFromFB: async function () {
      const dbRecurrenceRef = this.getContextRef(`${this.dbContext}-recurrences`).child(this.item.recurrenceId);
      return await dbRecurrenceRef.once('value');
    },
    deleteSingleItem() {
      this.getLocationRef(null, this.isArchived).child(`${this.dbContext}/${this.itemId}`).remove();

      let firebaseUpdates = {};
      firebaseUpdates[`${this.dbContext}-recurrences/${this.item.recurrenceId}/startDates/${this.itemId}`] = null;
      firebaseUpdates[`${this.dbContext}-recurrences/${this.item.recurrenceId}/editedEvents/${this.itemId}`] = null;
      this.getLocationRef(null).update(firebaseUpdates);

      let removedItems = [];
      removedItems.push(this.itemId);
      this.$emit('deleteItem', removedItems);
      this.$emit('action', 'reload');
    },

    getRecurrancesToRemove(recurrenceInfo, startDateKeys) {
      const currentTime = Date.now();
      if (this.isArchived) {
        return startDateKeys.filter((startDate) => Number(startDate) < currentTime);
      }
      return startDateKeys.filter((startDate) => Number(startDate) > currentTime || Number(startDate) == this.itemId);
    },
    async deleteRecurringItem() {
      const recurrenceInfo = (await this.getRecurrenceDataFromFB()).val();
      const startDateKeys = Object.keys(recurrenceInfo.startDates);
      const removedItems = this.getRecurrancesToRemove(recurrenceInfo, startDateKeys);
      const removeEntireRecurrence = startDateKeys.length == removedItems.length;
      const firebaseUpdates = removedItems.reduce(
        (accumulator, currentStartDate) => {
          accumulator.eventDeletions[`${this.dbContext}/${currentStartDate}`] = null;
          if (!removeEntireRecurrence) {
            accumulator.recurrenceDeletions[`${this.dbContext}-recurrences/${this.item.recurrenceId}/startDates/${currentStartDate}`] = null;
            accumulator.recurrenceDeletions[`${this.dbContext}-recurrences/${this.item.recurrenceId}/editedEvents/${currentStartDate}`] = null;
          }
          return accumulator;
        },
        { eventDeletions: {}, recurrenceDeletions: {} }
      );
      if (removeEntireRecurrence) {
        firebaseUpdates.recurrenceDeletions[`${this.dbContext}-recurrences/${this.item.recurrenceId}`] = null;
      }
      this.getLocationRef().update(firebaseUpdates.recurrenceDeletions);
      this.getLocationRef(null, this.isArchived).update(firebaseUpdates.eventDeletions);
      console.log('TCL: deleteRecurringItem -> this.getLocationRef(null, this.isArchived)', this.getLocationRef(null, this.isArchived).toString());
      console.log('TCL: deleteRecurringItem -> this.isArchived', this.isArchived);
      console.log('TCL: deleteRecurringItem -> firebaseUpdates.eventDeletions', JSON.stringify(firebaseUpdates.eventDeletions));
      this.$emit('deleteItem', removedItems);
      this.$emit('action', 'reload');
    },
    displayRecurrenceConfirmationModal: async function () {
      this.$message.create({
        title: 'Delete Recurrence',
        body: 'Do you want to delete only this event or all events in this recurrence?',
        buttons: [
          this.$message.button('Cancel', 'btn-secondary', null),
          this.$message.button('Delete Recurrence', 'btn-danger', this.deleteRecurringItem),
          this.$message.button('Delete Event', 'btn-danger', this.deleteSingleItem),
        ],
      });
    },
    displayNormalConfirmationModal: function () {
      this.$message.create({
        title: 'Delete Event',
        body: 'Are you sure you want to delete this event?',
        buttons: [
          this.$message.button('Cancel', 'btn-secondary', null),
          this.$message.button('Delete', 'btn-danger', () => {
            this.getContextRef(this.dbContext, null, this.isArchived).child(this.itemId).remove();
            this.$emit('deleteItem', this.itemId);
            this.$emit('action', 'reload');
          }),
        ],
      });
    },
    duplicateItem: function () {
      this.$router.push({ name: 'detailDuplicate', params: { type: this.detailRouteType, dbContext: this.dbContext, itemId: this.itemId } });
    },
    itemHasPassedClass: function () {
      return this.item.endDate < Date.now() && this.view !== 'view-archived' ? 'event-passed' : 'event-current';
    },
    bulkCheckItem: function () {
      this.$emit('bulkCheckItem', this.itemId, this.bulkCheck);
    },
  },
};
</script>

<style>
.main-text {
  display: flex;
  align-items: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-text .item-info {
  display: flex;
  flex-direction: column;
}
input[type='checkbox'].bulk-select {
  width: 20px !important;
  min-width: 20px;
}
.item-subtitle {
  white-space: initial;
}
.event-passed {
  background-color: red;
}
.show {
  display: inline !important;
}
</style>
