<template>
  <div class="button-container" v-if="property">
    <button
      v-for="button in buttons"
      :key="button.id"
      class="btn action-button"
      :class="button.style ? button.style : 'btn-secondary'"
      @click="button.action()"
      v-tooltip-top="button.tooltip">
      <div v-if="button.emoji" class="action-icon">{{ button.emoji }}</div>
      <i v-else class="material-icons action-icon">{{ button.icon }}</i>
    </button>
  </div>
</template>

<script>
import OpenAIMixin from '../mixins/OpenAIMixin';

export default {
  name: 'ActionAIButtons',
  props: ['promptKey', 'responseKey', 'property', 'originalPrompt', 'map'],
  mixins: [OpenAIMixin],
  data() {
    return {
      buttons: [
        {
          id: 1,
          icon: 'restart_alt',
          tooltip: 'Rephrases the text, while keeping the core content.',
          action: () => {
            const options = this.getMappedProperty(this.property.id).options;
            const message = `Rephrase this ${this.property.id}: '${this.property.text}'. ${options ? options : ''}`;
            this.buttonPrompt(message, 'actionRephrase');
          },
          style: 'btn-secondary',
        },
        {
          id: 2,
          icon: 'unfold_less',
          tooltip: 'Shortens the length of the text.',
          action: () => {
            const options = this.getMappedProperty(this.property.id).options;
            const message = `Shorten this ${this.property.id}: '${this.property.text}'. ${options ? options : ''}`;
            this.buttonPrompt(message, 'actionShorten');
          },
          style: 'btn-secondary',
        },
        {
          id: 3,
          icon: 'unfold_more',
          tooltip: 'Lengthens the text.',
          action: () => {
            const options = this.getMappedProperty(this.property.id).options;
            const message = `Lengthen this ${this.property.id}: '${this.property.text}'. ${options ? options : ''}`;
            this.buttonPrompt(message, 'actionLengthen');
          },
          style: 'btn-secondary',
        },
        {
          id: 4,
          emoji: '😄',
          tooltip: 'Adds emojis to the text.',
          action: () => {
            const options = this.getMappedProperty(this.property.id).options;
            const message = `Add emojis to this ${this.property.id}: '${this.property.text}'. ${options ? options : ''}`;
            this.buttonPrompt(message, 'actionEmojify');
          },
          style: 'btn-secondary',
        },
        {
          id: 5,
          icon: 'tune',
          tooltip: 'Regenerates the text based off of its original prompt.',
          action: () => {
            const options = this.getMappedProperty(this.property.id).options;
            const message = `Create a ${this.property.id} based off of this prompt: '${this.originalPrompt}'. ${options ? options : ''}`;
            this.buttonPrompt(message, 'actionRegenerate');
          },
          style: 'btn-secondary',
        },
      ],
    };
  },
  methods: {
    async buttonPrompt(message, callback) {
      if (this.property.spinner === false) {
        console.log(`Prompting: ${message}`);
        const result = await this.promptGPT(message, 'gpt-3.5-turbo-1106', (val) => {
          this.property.spinner = val;
          this.$forceUpdate();
          this.$emit('update');
        });
        const promptText = this.getPromptText(result);

        if (promptText.startsWith('"') && promptText.endsWith('"')) {
          this.property.text = promptText.substring(1, promptText.length - 1);
          this.property.history.push(this.property.text);
          console.log('new text', this.property);
        } else {
          this.property.text = promptText;
          this.property.history.push(this.property.text);
        }

        this.$emit('action', this.promptKey, this.responseKey, this.property);
        if (callback) this.$emit(callback, this.promptKey, this.responseKey, this.property);
      }
    },
    getMappedProperty(id) {
      const property = this.map.find((element) => element.id === id);
      console.log('property', property);
      return property;
    },
  },
};
</script>

<style scoped>
.button-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex-shrink: 0;
  min-width: 180px;
  height: 35px;
  margin: 0px 0px 0px 5px;
}
.action-button {
  display: flex;
  align-items: left;
  justify-content: left;
  margin: 2px;
  padding: 2px !important;
  width: 30px;
  height: 30px;
}
</style>
