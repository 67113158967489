<template>
  <div class="dropdown-container">
    <div class="grid-icon-set" :class="{ hovered: show }" @click.stop>
      <div><i class="item-menu material-icons"> more_vert </i></div>
      <slot>
        <i class="item-edit material-icons"> edit </i>
        <i class="item-remove material-icons"> delete </i>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false }, // manually show the dropdown
  },
  data() {
    return {};
  },
};
</script>

<style>
.dropdown-container {
  position: relative;
  display: inline-block;
  width: 20px;
}

.grid-icon-set {
  position: absolute;
  right: 0;
  grid-template-columns: 1fr;
  display: grid;
  padding: 2px;
  cursor: initial;
  height: auto;
  z-index: 1;
}
.grid-icon-set div:first-of-type,
.grid-icon-set.hovered div,
.grid-icon-set:hover div {
  display: flex;
}
.grid-icon-set:hover,
.grid-icon-set.hovered {
  border: 1px grey solid;
  border-radius: 10px;
  background: #1da9f3;
  z-index: 2;
}

.grid-icon-set div {
  display: flex;
  justify-content: flex-end; /* align items to the right */
  align-items: center; /* align items vertically */
  text-align: right;
}

.grid-icon-set div:not(:first-child) {
  border-top: 1px solid #ccc;
  padding: 10px;
  margin: 0px;
}
.grid-icon-set div:not(:first-child):hover {
  background: #1da9f3;
}
.grid-icon-set span {
  margin-right: 10px; /* space between the label and the icon */
  margin-left: 6px;
  width: 150px;
}

.grid-icon-set div {
  margin: 0px 0px 6px 0px;
  display: none;
  cursor: pointer;
}
.grid-icon-set div:first-child {
  pointer-events: none;
}
</style>
