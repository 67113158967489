<template>
  <div>
    <div id="home">
      <img src="/amc_logo.png" alt="" id="amc-logo" @click="goHome()" />
      <i class="material-icons logo-icons" @click="goHome()">home</i>
    </div>
    <div id="flyer-page">
      <div id="image-column" @click="selectElement(null)">
        <div id="image-borders" class="shadow">
          <div id="image-margins">
            <div ref="flyer-image" id="image-background" :style="{ backgroundImage: `url(${flyer.image})` }">
              <div id="image-overlay" :style="{ display: hideElements ? 'none' : '' }">
                <h3 v-if="!flyer.image" class="image-none">Use the tools on the <br />right to generate an image</h3>
                <div v-if="flyer.image" id="image-tint"></div>
                <drag-resizable
                  v-for="(element, index) in visibleElements"
                  v-show="flyer.image"
                  :key="index"
                  :handles="['tm', 'tr', 'mr', 'br', 'bm', 'bl', 'ml', 'tl']"
                  :class="{ 'vdr-hidden': selectedElement !== element }"
                  :x="element.position.x"
                  :y="element.position.y"
                  :w="element.position.width"
                  :h="element.position.height"
                  @dragging="(x, y) => dragElement(element, x, y)"
                  @resizing="(_top, _left, w, h) => resizeElement(element, w, h)">
                  <div :ref="`flyer-elements`" :style="getElementStyle(element)" @click.stop="selectElement(element)">
                    <template v-if="element.type === 'text'">
                      <span
                        v-if="element.type == 'text'"
                        style="width: 100%; height: auto; margin-top: auto; margin-bottom: auto"
                        :contenteditable="element == selectedElement"
                        @input="
                          (evt) => {
                            selectedElement.text = evt.target.innerHTML.replace(/<[^>]+>/g, '').replace(/&[^\s]*;/g, '');
                          }
                        ">
                        {{ element.text }}</span
                      >&nbsp;
                    </template>
                    <template v-if="element.type === 'image'">
                      <img
                        :src="element.src"
                        :style="{
                          width: element.position.width,
                          height: element.position.height,
                          'max-width': '100%',
                          'max-height': '100%',
                          'object-fit': 'contain',
                        }" />
                    </template>
                  </div>
                </drag-resizable>
              </div>
            </div>
            <!-- <img id="image-background" :src="flyer.image" /> -->
          </div>
        </div>
      </div>
      <div id="right-column" class="p-2">
        <div class="p-2 form-container" :style="getContainerStyle">
          <form @submit.prevent="submitUpdate" novalidate>
            <b-accordion id="events" :headings="['Flyer Image', 'Modify Text/Elements']">
              <template #body="{ heading }">
                <div v-if="heading === 'Flyer Image'">
                  <!-- Radio buttons for "Use Existing" and "Generate" -->
                  <div v-if="item?.headerInfo?.images?.length > 0" class="row ms-3 mb-3">
                    <div class="form-check col-6">
                      <input class="form-check-input" type="radio" value="existing" v-model="flyer.imageType" id="use-existing-radio" />
                      <label class="form-check-label" for="use-existing-radio">Use Existing</label>
                    </div>
                    <div class="form-check col-6">
                      <input class="form-check-input" type="radio" value="generate" v-model="flyer.imageType" id="generate-radio" />
                      <label class="form-check-label" for="generate-radio">Generate</label>
                    </div>
                  </div>
                  <div v-if="flyer.imageType == 'existing'" class="row">
                    <label class="form-label">Header Images</label>
                    <div class="row ms-1">
                      <div class="col">
                        <div class="row">
                          <div
                            class="col-md-2 history-item"
                            v-for="image in item?.headerInfo?.images"
                            :key="image.date"
                            @click="setFromHeader(image)">
                            <div class="history-thumbnail">
                              <img :src="image.originalURL" class="img-thumbnail" />
                            </div>
                            <!-- <button type="button" class="btn btn-danger history-button" @click.stop="deleteFromHistory(image)">
                              <i class="material-icons"> delete </i>
                            </button> -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Orientation -->
                    <div class="mb-3">
                      <label for="model-style-select">Orientation</label>
                      <select id="model-style-select" class="form-select" v-model="flyer.paper.orientation">
                        <option v-for="orientation in ['Portrait', 'Landscape']" :key="orientation" :value="orientation">{{ orientation }}</option>
                      </select>
                    </div>
                    <!-- Size -->
                    <div class="mb-3">
                      <label for="model-style-select">Paper Size</label>
                      <select id="model-style-select" class="form-select" v-model="flyer.paper.type">
                        <option v-for="style in filteredPaperTypes" :key="style.id" :value="style.id">
                          {{ style.name }}
                        </option>
                      </select>
                    </div>
                    <div class="row mb-1">
                      <div class="col-6">
                        <label for="flyer-image-position" class="form-label"
                          >Horizontal Position <small>({{ flyer.paper.offsetX }}%)</small></label
                        >
                        <input type="range" class="form-range" id="flyer-image-position" step="5" v-model="flyer.paper.offsetX" />
                      </div>
                      <div class="col-6">
                        <label for="flyer-image-position" class="form-label"
                          >Vertical Position <small>({{ flyer.paper.offsetY }}%)</small></label
                        >
                        <input type="range" class="form-range" id="flyer-image-position" step="5" v-model="flyer.paper.offsetY" />
                      </div>
                    </div>
                  </div>

                  <div v-if="flyer.imageType === 'generate'">
                    <!-- input text for title -->
                    <div class="d-flex mb-1">
                      <div style="width: 94%">
                        <label for="title">Search Image Text</label>
                        <input id="title" class="form-control" v-model="flyer.title" required />
                      </div>
                      <div style="margin-left: 8px; margin-top: 25px">
                        <!-- Clear flyer.title button -->
                        <button type="button" class="btn btn-secondary" style="width: 44px; height: 36px; padding: 5px" @click="flyer.title = ''">
                          <i class="material-icons" style="font-size: 20px"> backspace </i>
                        </button>
                      </div>
                    </div>

                    <!-- Orientation -->
                    <div class="mb-3">
                      <label for="model-style-select">Orientation</label>
                      <select id="model-style-select" class="form-select" v-model="flyer.paper.orientation">
                        <option v-for="orientation in ['Portrait', 'Landscape']" :key="orientation" :value="orientation">{{ orientation }}</option>
                      </select>
                    </div>
                    <!-- Size -->
                    <div class="mb-3">
                      <label for="model-style-select">Paper Size</label>
                      <select id="model-style-select" class="form-select" v-model="flyer.paper.type">
                        <option v-for="style in filteredPaperTypes" :key="style.id" :value="style.id">
                          {{ style.name }}
                        </option>
                      </select>
                    </div>
                    <div class="row mb-1" v-if="flyer.paper.type !== 'match' && flyer.paper.type !== 'match-landscape'">
                      <div class="col-6">
                        <label for="flyer-image-position" class="form-label"
                          >Horizontal <small>({{ flyer.paper.offsetX }}%)</small></label
                        >
                        <input type="range" class="form-range" id="flyer-image-position" step="5" v-model="flyer.paper.offsetX" />
                      </div>
                      <div class="col-6">
                        <label for="flyer-image-position" class="form-label"
                          >Vertical <small>({{ flyer.paper.offsetY }}%)</small></label
                        >
                        <input type="range" class="form-range" id="flyer-image-position" step="5" v-model="flyer.paper.offsetY" />
                      </div>
                    </div>
                    <!-- Tags -->
                    <div class="mb-3">
                      <label for="tags">Styles</label>
                      <div class="d-flex flex-wrap mb-3">
                        <div class="m-1" v-for="tag in flyer.tags" :key="tag" @click="toggleTag(tag)">
                          <span
                            class="badge tag-badge"
                            role="button"
                            style="font-size: 16px"
                            :class="{ 'bg-dark': !hasTag(tag), 'bg-light text-dark': hasTag(tag) }"
                            >{{ tag }}</span
                          >
                        </div>
                      </div>
                      <div class="d-flex flex-wrap mb-3">
                        <div v-for="tag in tags" :key="tag" @click="toggleTag(tag)">
                          <span
                            v-if="!flyer.tags.includes(tag)"
                            class="badge tag-badge m-1"
                            role="button"
                            style="font-size: 14px"
                            :class="{ 'bg-dark': !hasTag(tag), 'bg-light text-dark': hasTag(tag) }"
                            >{{ tag }}</span
                          >
                        </div>
                        <div class="m-1" v-for="tag in customTags" :key="tag" @click="toggleTag(tag)">
                          <span
                            v-if="!flyer.tags.includes(tag)"
                            class="badge tag-badge"
                            role="button"
                            style="font-size: 14px"
                            :class="{ 'bg-success': !hasTag(tag), 'bg-light text-dark': hasTag(tag) }"
                            >{{ tag }}</span
                          >
                        </div>
                        <div class="m-1" @click="editTag = !editTag">
                          <span class="badge bg-success tag-badge" role="button"
                            ><i class="material-icons" style="font-size: 16px">{{ editTag ? 'close' : 'add' }}</i></span
                          >
                        </div>
                      </div>
                      <div v-if="editTag" class="row">
                        <label for="add-tag">New Style</label>
                        <div class="col-md-10">
                          <input id="add-tag" class="form-control" v-model="tagName" @keydown.enter="createTag" />
                        </div>
                        <div class="col">
                          <button class="btn mb-3 btn-secondary" type="button" @click="createTag">Add</button>
                        </div>
                      </div>
                    </div>

                    <button class="btn mb-3 btn-secondary" type="button" @click="promptAI">Generate</button>
                  </div>

                  <div class="row" v-if="history.generated.length > 0">
                    <label class="form-label">History</label>
                    <div class="row ms-1">
                      <div class="col">
                        <div class="row">
                          <div class="col-md-2 history-item" v-for="image in history.generated" :key="image.created" @click="setFromHistory(image)">
                            <div class="history-thumbnail">
                              <img :src="image.url" class="img-thumbnail" />
                            </div>
                            <button type="button" class="btn btn-danger history-button" @click.stop="deleteFromHistory(image)">
                              <i class="material-icons"> delete </i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="heading === 'Modify Text/Elements'">
                  <div class="row mb-3">
                    <div class="col">
                      <label class="mb-2">Tint</label> <br />
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          :value="false"
                          v-model="flyer.tint.whiteBlack" />
                        <label class="form-check-label" for="inlineRadio1">Black</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          :value="true"
                          v-model="flyer.tint.whiteBlack" />
                        <label class="form-check-label" for="inlineRadio2">White</label>
                      </div>
                    </div>
                    <div class="col">
                      <label for="flyer-tint-opacity" class="form-label"
                        >Tint Opacity <small>({{ Math.round(flyer.tint.opacity * 100) }}%)</small></label
                      >
                      <input type="range" class="form-range" id="flyer-tint-position" step=".05" min="0" max=".75" v-model="flyer.tint.opacity" />
                    </div>
                  </div>

                  <b-accordion
                    id="elements"
                    :headings="elementHeaders"
                    color="secondary"
                    :openIndex="accordionIndex"
                    @clicked-index="selectElementByIndex">
                    <template #body="{ heading }">
                      <div v-for="element in flyer.elements" :key="element" @click="selectElement(element)">
                        <div v-if="element.label === heading">
                          <div v-if="element.type == 'text'">
                            <div class="mb-1">
                              <label for="element-text">Text</label>
                              <input id="element-text" class="form-control" v-model="element.text" />
                            </div>

                            <!-- Font type/size -->
                            <div class="row mb-1">
                              <div class="col">
                                <label for="font-family-select">Font</label>
                                <select id="font-family-select" class="form-select" v-model="element.style.text.family">
                                  <option
                                    v-for="font in [
                                      'Arial',
                                      'Verdana',
                                      'Tahoma',
                                      'Trebuchet MS',
                                      'Times New Roman',
                                      'Courier New',
                                      'Georgia',
                                      'Comic Sans MS',
                                    ]"
                                    :key="font"
                                    :value="font">
                                    {{ font }}
                                  </option>
                                </select>
                              </div>
                              <div class="col">
                                <label for="font-size-select">Font Size</label>
                                <select id="font-size-select" class="form-select" v-model="element.style.text.size">
                                  <option
                                    v-for="size in [14, 16, 18, 20, 22, 24, 26, 28, 30, 35, 40, 45, 50, 55, 60, 65, 72, 84, 96]"
                                    :key="size"
                                    :value="size">
                                    {{ size }}px
                                  </option>
                                </select>
                              </div>
                            </div>
                            <!-- Italic/bold -->
                            <div class="row mb-3">
                              <div class="col">
                                <input type="checkbox" id="font-italic" class="flyer-checkbox" v-model="element.style.text.italic" />
                                <label for="font-italic">Italic</label>
                              </div>
                              <div class="col">
                                <input type="checkbox" id="font-bold" class="flyer-checkbox" v-model="element.style.text.bold" />
                                <label for="font-bold">Bold</label>
                              </div>
                            </div>
                            <!-- Alignment/case -->
                            <div class="row mb-3">
                              <div class="col">
                                <label for="align-select">Alignment</label>
                                <select id="align-select" class="form-select" v-model="element.style.text.align">
                                  <option
                                    v-for="align in [
                                      { id: 'left', value: 'Left' },
                                      { id: 'center', value: 'Center' },
                                      { id: 'right', value: 'Right' },
                                      { id: 'justify', value: 'Justify' },
                                    ]"
                                    :key="align.id"
                                    :value="align.id">
                                    {{ align.value }}
                                  </option>
                                </select>
                              </div>
                              <div class="col">
                                <label for="align-select">Case</label>
                                <select id="align-select" class="form-select" v-model="element.style.text.transform">
                                  <option
                                    v-for="transform in [
                                      { id: 'none', value: 'None' },
                                      { id: 'lowercase', value: 'Lowercase' },
                                      { id: 'uppercase', value: 'Uppercase' },
                                      { id: 'capitalize', value: 'Capitalize' },
                                    ]"
                                    :key="transform.id"
                                    :value="transform.id">
                                    {{ transform.value }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <!-- Colors -->
                            <div class="row mb-3">
                              <div class="col">
                                <label class="form-label">Font Color</label>
                                <div class="color-selector mb-3">
                                  <label class="btn btn-light" @click.stop="openFontColor = !openFontColor">
                                    <div :style="getColorPickerBackground(element.style.text.color)">&nbsp;</div>
                                  </label>
                                </div>
                                <chrome-picker
                                  id="editFontColor"
                                  :data-edit="openFontColor"
                                  v-show="openFontColor"
                                  v-model="fontPicker"
                                  v-click-outside="
                                    () => {
                                      this.openFontColor = false;
                                    }
                                  " />
                              </div>
                              <div class="col">
                                <label class="form-label">Background Color</label>
                                <div class="color-selector mb-3">
                                  <label class="btn btn-light" @click.stop="openBoxColor = !openBoxColor">
                                    <div :style="getColorPickerBackground(element.style.box.color)">&nbsp;</div>
                                  </label>
                                </div>
                                <chrome-picker
                                  id="editBoxColor"
                                  :data-edit="openBoxColor"
                                  v-show="openBoxColor"
                                  v-model="boxPicker"
                                  v-click-outside="
                                    () => {
                                      this.openBoxColor = false;
                                    }
                                  " />
                              </div>
                            </div>

                            <!-- Corner radius-->
                            <div class="mb-1">
                              <input type="checkbox" id="enable-shadows-checkbox" class="flyer-checkbox" v-model="element.style.box.corners" />
                              <label for="enable-shadows-checkbox">Round corners</label>
                            </div>
                            <div class="row mb-3" v-if="element.style.box.corners">
                              <div class="col">
                                <label for="corner-top-range" class="form-label"
                                  >Corner Radius <small>({{ element.style.box['corner-radius'] }}px)</small></label
                                >
                                <input
                                  type="range"
                                  class="form-range"
                                  id="corner-top-range"
                                  step="1"
                                  min="1"
                                  max="20"
                                  v-model="element.style.box['corner-radius']" />
                              </div>
                            </div>

                            <!-- Box shadow -->
                            <div class="mb-1">
                              <input type="checkbox" id="enable-shadows-checkbox" class="flyer-checkbox" v-model="element.style.box.shadow.enable" />
                              <label for="enable-shadows-checkbox">Box Shadow</label>
                            </div>
                            <div class="row mb-3" v-if="element.style.box.shadow.enable">
                              <div class="col">
                                <label for="shadow-distance-range" class="form-label"
                                  >Shadow Distance <small>({{ element.style.box.shadow.distance }}px)</small></label
                                >
                                <input
                                  type="range"
                                  class="form-range"
                                  id="shadow-distance-range"
                                  step="1"
                                  min="-30"
                                  max="30"
                                  v-model="element.style.box.shadow.distance" />
                              </div>
                              <div class="col">
                                <label for="shadow-blur-range" class="form-label"
                                  >Shadow Blur <small>({{ element.style.box.shadow.blur }}px)</small></label
                                >
                                <input
                                  type="range"
                                  class="form-range"
                                  id="shadow-blur-range"
                                  step="1"
                                  min="0"
                                  max="30"
                                  v-model="element.style.box.shadow.blur" />
                              </div>
                            </div>

                            <!-- Text shadow -->
                            <div class="mb-1">
                              <input
                                type="checkbox"
                                id="enable-text-shadows-checkbox"
                                class="flyer-checkbox"
                                v-model="element.style.text.shadow.enable" />
                              <label for="enable-text-shadows-checkbox">Text Shadow</label>
                            </div>
                            <div class="row mb-3" v-if="element.style.text.shadow.enable">
                              <div class="col">
                                <label for="text-shadow-distance-range" class="form-label"
                                  >Shadow Distance <small>({{ element.style.text.shadow.distance }}px)</small></label
                                >
                                <input
                                  type="range"
                                  class="form-range"
                                  id="text-shadow-distance-range"
                                  step="1"
                                  min="-10"
                                  max="10"
                                  v-model="element.style.text.shadow.distance" />
                              </div>
                              <div class="col">
                                <label for="text-shadow-blur-range" class="form-label"
                                  >Shadow Blur <small>({{ element.style.text.shadow.blur }}px)</small></label
                                >
                                <input
                                  type="range"
                                  class="form-range"
                                  id="text-shadow-blur-range"
                                  step="1"
                                  min="0"
                                  max="20"
                                  v-model="element.style.text.shadow.blur" />
                              </div>
                            </div>
                          </div>
                          <div v-if="element.type == 'image'">
                            <!-- Background color -->
                            <div class="row mb-3">
                              <div class="col">
                                <label class="form-label">Background Color</label>
                                <div class="color-selector mb-3">
                                  <label class="btn btn-light" @click.stop="openBoxColor = !openBoxColor">
                                    <div :style="getColorPickerBackground(element.style.box.color)">&nbsp;</div>
                                  </label>
                                </div>
                                <chrome-picker
                                  id="editBoxColor"
                                  :data-edit="openBoxColor"
                                  v-show="openBoxColor"
                                  v-model="boxPicker"
                                  v-click-outside="
                                    () => {
                                      this.openBoxColor = false;
                                    }
                                  " />
                              </div>
                            </div>
                            <!-- Corner radius-->
                            <div class="mb-1">
                              <input type="checkbox" id="enable-shadows-checkbox" class="flyer-checkbox" v-model="element.style.box.corners" />
                              <label for="enable-shadows-checkbox">Round corners</label>
                            </div>
                            <div class="row mb-3" v-if="element.style.box.corners">
                              <div class="col">
                                <label for="corner-top-range" class="form-label"
                                  >Corner Radius <small>({{ element.style.box['corner-radius'] }}px)</small></label
                                >
                                <input
                                  type="range"
                                  class="form-range"
                                  id="corner-top-range"
                                  step="1"
                                  min="1"
                                  max="20"
                                  v-model="element.style.box['corner-radius']" />
                              </div>
                            </div>
                            <!-- Box shadow -->
                            <div class="mb-1">
                              <input type="checkbox" id="enable-shadows-checkbox" class="flyer-checkbox" v-model="element.style.box.shadow.enable" />
                              <label for="enable-shadows-checkbox">Box Shadow</label>
                            </div>
                            <div class="row mb-3" v-if="element.style.box.shadow.enable">
                              <div class="col">
                                <label for="shadow-distance-range" class="form-label"
                                  >Shadow Distance <small>({{ element.style.box.shadow.distance }}px)</small></label
                                >
                                <input
                                  type="range"
                                  class="form-range"
                                  id="shadow-distance-range"
                                  step="1"
                                  min="-30"
                                  max="30"
                                  v-model="element.style.box.shadow.distance" />
                              </div>
                              <div class="col">
                                <label for="shadow-blur-range" class="form-label"
                                  >Shadow Blur <small>({{ element.style.box.shadow.blur }}px)</small></label
                                >
                                <input
                                  type="range"
                                  class="form-range"
                                  id="shadow-blur-range"
                                  step="1"
                                  min="0"
                                  max="30"
                                  v-model="element.style.box.shadow.blur" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                    <template #pre-heading="{ heading }">
                      <div v-for="element in flyer.elements" :key="element">
                        <i
                          v-if="element.label == heading"
                          class="material-icons"
                          @click="() => (element.visible = !element.visible)"
                          style="cursor: pointer; margin-left: 8px; margin-top: 0.5rem; margin-right: 0.5rem; user-select: none"
                          v-tooltip-left="element.visible ? 'Hide' : 'Show'">
                          {{ element.visible ? 'visibility' : 'visibility_off' }}</i
                        >
                      </div>
                    </template>
                    <template #post-heading="{ heading }">
                      <div v-for="element in flyer.elements" :key="element">
                        <div v-if="element.label == heading" class="btn-group ms-2" role="group" aria-label="Basic example">
                          <button
                            v-if="isElementChanged(element)"
                            type="button"
                            class="btn btn-warning"
                            style="height: 37px"
                            @click.stop="revertElement(element)"
                            v-tooltip-left="'Revert Changes'">
                            <i class="material-icons"> history </i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-danger"
                            style="height: 37px"
                            @click.stop="deleteElement(element)"
                            v-tooltip-left="'Delete Element'">
                            <i class="material-icons"> delete </i>
                          </button>
                        </div>
                      </div>
                    </template>
                  </b-accordion>
                  <button class="btn m-1 btn-primary mb-3" type="button" @click.stop="addElement()" :disabled="!flyer.image">
                    Add New Text Element
                  </button>
                  <br />
                  <small v-if="!flyer.image"> Generate an image or select an existing one to add new elements. </small>
                  <button
                    v-if="selectedHistory && anyElementChanges()"
                    class="btn m-1 btn-danger mb-3"
                    type="button"
                    @click.stop="revertHistoryElements()">
                    Revert Changes
                  </button>
                </div>

                <div v-if="heading === 'Layout'">
                  <div class="mb-3"></div>

                  <template v-if="layout == 'event'">
                    <div class="mb-3" v-for="(element, index) in flyer.elements" :key="index">
                      <label for="element-text">{{ element.label }}</label>
                      <input id="element-text" class="form-control" v-model="element.text" />
                    </div>
                  </template>
                </div>
              </template>
            </b-accordion>

            <Validation :validator="vuelidate$.newItem" map="form" />

            <button class="btn m-1 btn-primary" type="button" @click.stop="downloadAll">Download</button>
            <button class="btn m-1 btn-primary" type="button" @click.stop="downloadBackground">Download Background Image Only</button>
            <button class="btn m-1 btn-secondary" type="button" @click.stop="cancel">Cancel</button>
          </form>
        </div>

        <Overlay :showProp="showOverlay" :text="overlayText" subtext="This may take up to a minute" />
      </div>
    </div>
  </div>
</template>

<script>
import BAccordion from '@/components/common/BAccordion.vue';
import Validation from '@/components/common/Validation.vue';
import Overlay from '@/components/common/Overlay.vue';

import OpenAIMixin from '@/components/mixins/OpenAIMixin.js';
import domToImage from 'dom-to-image-more';
import { Chrome } from 'vue-color';
import axios from 'axios';
import VueDraggableResizable from 'vue-draggable-resizable';
import Fuse from 'fuse.js';
import { LoginMixin } from '@/components/mixins/LoginMixin';
import QRCode from 'qrcode';

export default {
  props: [],
  mixins: [OpenAIMixin, LoginMixin],
  components: {
    BAccordion,
    Validation,
    Overlay,
    'chrome-picker': Chrome,
    'drag-resizable': VueDraggableResizable,
  },
  data() {
    const textTemplate = {
      type: 'text',
      label: 'Text',
      text: 'New text element',
      visible: true,
      position: {
        x: 0,
        y: 0,
        width: '450',
        height: '100',
      },
      style: {
        text: {
          family: 'Verdana',
          size: 24,
          color: '#FFFFFF',
          transform: 'uppercase',
          align: 'center',
          style: 'normal',
          weight: 'normal',
          italic: false,
          bold: false,
          shadow: {
            enable: false,
            distance: 0,
            blur: 0,
            color: '#00000088',
          },
        },
        box: {
          color: 'rgba(255, 255, 255, 0)',
          'corner-radius': 0,
          corners: false,
          shadow: {
            enable: false,
            distance: 0,
            blur: 0,
            color: '#00000088',
          },
        },
      },
    };
    const imageTemplate = {
      type: 'image',
      label: 'Image',
      src: '',
      visible: true,
      position: {
        x: 0,
        y: 0,
        width: '450',
        height: '100',
      },
      style: {
        box: {
          color: 'rgba(255, 255, 255, 0)',
          'corner-radius': 0,
          corners: false,
          shadow: {
            enable: false,
            distance: 0,
            blur: 0,
            color: '#00000088',
          },
        },
      },
    };

    return {
      overlayText: 'Generating flyer',
      showOverlay: false,
      enableMargins: false,
      item: null,
      location: null,
      selectedHistory: null,
      selectedElement: null,
      editedElement: null,
      hideElements: false,
      clickTimeout: null,
      fontPicker: {},
      boxPicker: {},
      openFontColor: false,
      openBoxColor: false,
      history: { generated: [] },
      allHistory: { generated: [] },
      accordionIndex: 1,
      elementIndex: 1,
      configRef: {},
      qrURL: null,
      qrImage: null,
      image: {
        width: 0,
        height: 0,
        size: 'auto 100%',
      },

      //Object templates
      flyer: {
        title: '',
        image: null,
        imageType: 'generate',
        tags: [],
        tint: {
          whiteBlack: false,
          opacity: 0.25,
        },
        margins: {
          color: '#ffffff',
          width: 0,
          height: 0,
        },
        paper: {
          type: 'match',
          orientation: 'Portrait',
          width: 1024,
          height: 1792,
          offsetX: 50,
          offsetY: 50,
        },
        elements: [],
      },
      ai: {
        prompt: '',
        model: 'dall-e-3',
        size: '1024x1792',
        quality: 'hd',
      },
      paperTypes: [
        { id: 'match', name: 'Match Image (9:16)', width: 1024, height: 1792, orientation: 'Portrait' },
        { id: 'letter', name: 'Letter (8.5" x 11")', width: 1024, height: 1325, orientation: 'Portrait' },
        { id: 'square', name: 'Square', width: 1792, height: 1792, orientation: 'Portrait' },
        { id: 'match-landscape', name: 'Match Image (16:9)', width: 1792, height: 1024, orientation: 'Landscape' },
        { id: 'letter-landscape', name: 'Letter (8.5" x 11")', width: 1325, height: 1024, orientation: 'Landscape' },
        { id: 'square-landscape', name: 'Square', width: 1792, height: 1792, orientation: 'Landscape' },
        { id: 'header-landscape', name: 'Header Image', width: 1125, height: 600, orientation: 'Landscape' },
      ],
      tags: [
        'Muted',
        'Classy',
        '2D Art',
        'Vibrant',
        'Minimalist',
        'Abstract',
        'Realistic',
        'Fantasy',
        'Sci-Fi',
        'Retro',
        'Futuristic',
        'Grunge',
        'Cartoon',
        '3D Art',
        'Nature',
        'Urban',
        'Pop Art',
      ],
      customTags: [],
      tagName: '',
      editTag: false,
      textTemplate,
      imageTemplate,
      elementHeaders: [],
      trackedChanges: {},

      fuseOptions: {
        isCaseSensitive: false,
        includeScore: true,
        shouldSort: true,
        threshold: 0.5,
        keys: ['title'],
      },
    };
  },
  computed: {
    visibleElements() {
      return this.flyer.elements.filter((element) => element.visible);
    },
    marginWidth() {
      return this.flyer.margins.width + 'px';
    },
    marginHeight() {
      return this.flyer.margins.height + 'px';
    },
    tint() {
      const rgb = this.flyer.tint.whiteBlack ? 255 : 0;
      return `rgba(${rgb}, ${rgb}, ${rgb}, ${this.flyer.tint.opacity})`;
    },
    imageWidth() {
      if (this.flyer.paper.orientation == 'Portrait') return this.calculateAspectRatio(this.flyer.paper.width, this.flyer.paper.height, 80) + 'vh';
      return '80vh'; //Landscape
    },
    imageHeight() {
      if (this.flyer.paper.orientation == 'Portrait') return '80vh';
      return this.calculateAspectRatio(this.flyer.paper.height, this.flyer.paper.width, 80) + 'vh'; //Landscape
    },
    imageOffsetX() {
      return this.flyer.paper.offsetX + '%';
    },
    imageOffsetY() {
      return this.flyer.paper.offsetY + '%';
    },
    size() {
      return this.flyer.paper.orientation == 'Portrait' ? '1024x1792' : '1792x1024';
    },
    filteredPaperTypes() {
      return this.paperTypes.filter((type) => type.orientation === this.flyer.paper.orientation);
    },
  },
  watch: {
    'flyer.title': function (val) {
      this.fuzzyMatch();
    },
    'flyer.paper.type': function (val) {
      const paper = this.paperTypes.find((type) => type.id === val);
      this.flyer.paper.width = paper.width;
      this.flyer.paper.height = paper.height;
      this.$nextTick(() => this.calculateImageConstraints());
      this.saveConfig();
    },
    'flyer.image': function (val) {
      if (val) {
        //Get image width and height and set to value
        const img = new Image();
        img.src = val;
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          this.$nextTick(() => this.calculateImageConstraints());
        };
      }
    },
    marginPicker(val) {
      this.flyer.margins.color = val.hex8;
    },
    fontPicker(val, ref) {
      this.selectedElement.style.text.color = val.hex8;
    },
    boxPicker(val, ref) {
      this.selectedElement.style.box.color = val.hex8;
    },
    'flyer.paper.orientation': function (val) {
      if (val == 'Portrait') this.flyer.paper.type = 'match';
      else this.flyer.paper.type = 'match-landscape';
      this.$nextTick(() => this.calculateImageConstraints());
      this.saveConfig();
    },
    customTags(val) {
      this.saveConfig();
    },
    'flyer.tags': function (val) {
      this.saveConfig();
    },
    'flyer.elements': {
      handler() {
        this.elementHeaders = this.flyer.elements.map((element) => element.label);

        this.flyer.elements.forEach((element) => {
          this.trackedChanges[element.label] = this.setElementChanged(element);
        });
      },
      deep: true,
    },
  },
  async mounted() {
    this.loadConfig();
    this.$nextTick(async () => await this.loadHistory());

    if (this.$route.params.action) {
      this.item = this.$store.getters.params.newItem;
      this.location = this.$store.getters.params.location;
      // this.item = {
      //     title: 'Water Aerobics',
      //     startDate: '2022-08-01T09:00:00',
      //     endDate: '2022-08-01T10:00:00',
      //     description:
      //       'Join us for a fun and invigorating water aerobics class at the pool. Join us for a fun and invigorating water aerobics class at the pool.',
      //   };
      if (this.item) {
        //Set flyer data and prompt AI
        this.flyer.title = this.item.title;
        this.flyer.image = null;
        await this.createQR();
        if (this.item?.headerInfo?.images?.length > 0) {
          this.flyer.imageType = 'existing';
          this.setFromHeader(this.item.headerInfo.images[0]);
        }
      }
    }
  },
  methods: {
    //Config functions
    saveConfig() {
      const config = {
        orientation: this.flyer.paper.orientation || null,
        type: this.flyer.paper.type || null,
        tags: this.flyer.tags || null,
        customTags: this.customTags || null,
      };
      const dbRef = this.getLocationRef().child('flyers').child('config');
      dbRef.set(config);
    },
    loadConfig() {
      const dbRef = this.getLocationRef().child('flyers').child('config');
      dbRef.once('value', (snapshot) => {
        const config = snapshot.val();
        if (config) {
          this.flyer.paper.orientation = config.orientation;
          this.flyer.paper.type = config.type;
          this.customTags = config.customTags || [];
          this.flyer.tags = config?.tags || [];
        }
      });
    },

    //Tag functions
    hasTag(id) {
      return this.flyer.tags.find((tag) => tag === id);
    },
    addTag(id) {
      this.flyer.tags.push(id);
    },
    removeTag(id) {
      this.flyer.tags = this.flyer.tags.filter((tag) => tag !== id);
    },
    toggleTag(id) {
      if (this.hasTag(id)) this.removeTag(id);
      else this.addTag(id);
    },
    createTag() {
      if (this.tagName) {
        if (this.customTags.indexOf(this.tagName) == -1) {
          this.customTags.push(this.tagName);
          this.tagName = '';
        } else {
          this.$toast.message('Tag already exists.', 'warning');
        }
      }
    },

    //Prompt functions
    buildPrompt() {
      let tags = '';
      const tagData = this.flyer.tags;
      if (tagData.length > 0) tags = tagData.join(', ') + '. ';

      this.ai.prompt =
        `A vivid style event marketing material background image of ${this.flyer.title}. ` +
        `${tags}Rules: vertical layout, portrait, 1024x1792 aspect ratio, high definition quality.`;
    },
    async promptAI() {
      this.buildPrompt();
      const response = await this.promptDallE(this.ai.prompt, this.ai.model, this.size, this.ai.quality, (val) => {
        this.showOverlay = val;
      });

      const image = this.getImage(response);
      this.flyer.image = await this.copyImage(image, 'flyers');
      this.reloadLayout(this.$route.params.action);
      this.uploadToHistory();
    },
    async copyImage(imageURL, path) {
      const url = `${this.$apihostname}/api/copyImage`;
      const response = await axios.post(url, { url: imageURL, communityKey: this.communityKey || this.getCampgroundKey, bucket: path });
      return response.data.url;
    },
    fuzzyMatch() {
      if (this.flyer?.title?.length > 1) {
        const fuse = new Fuse(this.allHistory.generated, this.fuseOptions);
        const results = fuse.search(this.flyer.title);

        const keys = results.map((result) => result.item.key);
        this.history.generated = this.allHistory.generated.filter((item) => keys.indexOf(item.key) > -1);
      } else {
        this.history.generated = this.allHistory.generated.slice(0, 12);
      }
    },

    //Layout functions

    flyerBounds() {
      const width = this.$refs['flyer-image'] ? this.$refs['flyer-image'].offsetWidth : 0;
      const height = this.$refs['flyer-image'] ? this.$refs['flyer-image'].offsetHeight : 0;
      return { width, height };
    },
    openLoadLayout() {
      this.$message.create({
        title: 'Load Event Layout',
        body: 'Do you want to load text from the event? Any previous element changes will be lost.',
        buttons: [
          this.$message.button('Cancel', 'btn-secondary', null),
          this.$message.button('Yes', 'btn-primary', () => {
            this.loadLayout(this.$route.params.action);
          }),
        ],
      });
    },
    reloadLayout(type) {
      if (this.visibleElements?.length > 0) {
        this.$nextTick(() => {
          this.spaceElements(30, 10);
        });
      } else {
        this.loadLayout(type);
      }
    },
    loadLayout(type) {
      if (type == 'event') {
        this.flyer.elements = [];
        const element = () => {
          const element = this.getElementCopy();
          element.style.text.family = 'Verdana';
          element.style.text.bold = true;
          element.style.text.shadow.distance = 2;
          element.style.text.shadow.blur = 3;
          element.style.text.shadow.color = '#000000CC';
          element.style.text.shadow.enable = true;
          element.position.width = '80vw';
          element.position.height = 'auto';
          return element;
        };

        const title = element();
        title.label = 'Title';
        title.text = this.item.title;
        title.style.text.size = 30;

        const description = element();
        description.label = 'Description';
        description.text = this.item.description;
        //Replace <br /> with new line
        description.text = description.text.replace(/<br\s*\/?>/gi, '\n\n');
        //Strip HTML tags from description
        description.text = description.text.replace(/<[^>]+>/g, '').replace(/&[^\s]*;/g, '');
        description.style.text.size = 24;
        description.visible = false;

        const date = element();
        const startDate = new Date(this.item.startDate).toLocaleDateString('en-US', { day: 'numeric', month: 'long' });
        const endDate = new Date(this.item.endDate).toLocaleDateString('en-US', { day: 'numeric', month: 'long' });
        date.label = 'Date';
        date.text = startDate === endDate ? startDate : `${startDate} - ${endDate}`;
        date.style.text.size = 30;
        date.position.height = 35;

        const time = element();
        const startTime = new Date(this.item.startDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        const endTime = new Date(this.item.endDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        time.label = 'Time';
        time.text = this.item.allDay ? 'All Day' : `${startTime} - ${endTime}`;
        time.style.text.size = 30;
        time.position.height = 35;
        time.width = 200;

        const location = element();
        location.label = 'Location';
        location.text = this.location;
        location.style.text.size = 30;
        location.position.height = 35;

        const qr = this.getElementCopy('image');
        qr.label = 'QR Code';
        qr.src = this.qrImage;
        const bounds = this.flyerBounds();
        qr.position.y = bounds.height - 250;
        qr.position.height = 100;
        qr.position.width = 100;

        this.flyer.elements = [title, description, date, time, location, qr];
        this.reloadLayout(type);
      }
    },
    spaceElements(spacing = 50, offset = 100) {
      let height = offset;
      const bounds = this.flyerBounds();
      this.visibleElements.forEach((element, index) => {
        const ref = this.$refs[`flyer-elements`][index];
        if (element.label == 'QR Code') {
          element.position.x = bounds.width - 120;
          element.position.y = bounds.height - 120;
        } else if (ref && element.visible) {
          // element.position.width = ref.offsetWidth;
          // element.position.height = ref.offsetHeight - 5;
          element.position.x = (bounds.width - ref.offsetWidth - 5) / 2;
          element.position.y = height;
          height += ref.offsetHeight + spacing;
        }
      });
    },
    //Update elements with new text information when loading history items
    setToExistingFlyer() {
      console.log('Called setToExistingFlyer');

      this.flyer.elements.forEach((element) => {
        if (element.label === 'Title') element.text = this.item.title;
        if (element.label === 'Description') {
          element.text = this.item.description;
          element.text = element.text.replace(/<br\s*\/?>/gi, '\n\n');
          element.text = element.text.replace(/<[^>]+>/g, '').replace(/&[^\s]*;/g, '');
        }
        if (element.label === 'Date') {
          console.log('date label');

          const startDate = new Date(this.item.startDate).toLocaleDateString('en-US', { day: 'numeric', month: 'long' });
          const endDate = new Date(this.item.endDate).toLocaleDateString('en-US', { day: 'numeric', month: 'long' });
          element.text = startDate === endDate ? startDate : `${startDate} - ${endDate}`;
        }
        if (element.label === 'Time') {
          console.log('time label');

          const startTime = new Date(this.item.startDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
          const endTime = new Date(this.item.endDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
          element.text = this.item.allDay ? 'All Day' : `${startTime} - ${endTime}`;
        }
        if (element.label === 'Location') element.text = this.location;
        if (element.label === 'QR Code') element.src = this.qrImage;
      });
    },
    getElementCopy(type = 'text') {
      if (type == 'text') return JSON.parse(JSON.stringify(this.textTemplate));
      else return JSON.parse(JSON.stringify(this.imageTemplate));
    },

    //Background functions
    resetMargins() {
      this.flyer.margins.width = 30;
      this.flyer.margins.height = 30;
    },
    toggleMargins() {
      if (this.enableMargins) {
        this.flyer.margins.width = 30;
        this.flyer.margins.height = 30;
      } else {
        this.flyer.margins.width = 0;
        this.flyer.margins.height = 0;
      }
    },
    calculateImageConstraints() {
      if (this.flyer.image) {
        const bounds = this.flyerBounds();

        if (this.flyer.paper.orientation == 'Landscape') {
          if (this.image.width / this.image.height > bounds.width / bounds.height) {
            this.image.size = 'auto 100%';
          } else {
            this.image.size = 'cover';
          }
        } else {
          if (this.image.height / this.image.width > bounds.height / bounds.width) {
            this.image.size = '100% auto';
          } else {
            this.image.size = 'cover';
          }
        }
      }
    },

    //Element functions
    addElement(select = true) {
      const element = this.getElementCopy();
      element.label = `Text Element ${this.elementIndex++}`;
      this.flyer.elements.push(element);
      if (select) this.selectedElement = element;
      return element;
    },
    selectElementByIndex(index) {
      this.selectedElement = this.flyer.elements[index - 1];
    },
    selectElement(element) {
      this.selectedElement = element;
      this.openElementAccordion(element);
    },
    openElementAccordion(element) {
      if (element) this.accordionIndex = this.flyer.elements.indexOf(element) + 1;
    },
    revertElement(element) {
      const history = this.history.generated.find((item) => item.key === this.selectedHistory).elements;
      if (history) {
        const revert = history.find((el) => el.label === element.label);
        if (revert) {
          const index = this.flyer.elements.findIndex((el) => el === element);
          this.flyer.elements[index] = JSON.parse(JSON.stringify(revert));
          this.selectElement(null);
        }
      }
    },
    isElementChanged(element) {
      return this.trackedChanges[element.label];
    },
    setElementChanged(element) {
      const history = this.history.generated.find((item) => item.key === this.selectedHistory)?.elements;
      if (history) {
        const match = history.find((el) => el.label === element.label);
        return JSON.stringify(element) !== JSON.stringify(match);
      }
      return false;
    },
    anyElementChanges() {
      return Object.values(this.trackedChanges).some((value) => value === true);
    },
    deleteElement(element) {
      if (this.selectedElement == element) this.selectedElement = null;
      this.flyer.elements = this.flyer.elements.filter((el) => el !== element);
    },
    dragElement(element, x, y) {
      element.position.x = x;
      element.position.y = y;
    },
    resizeElement(element, w, h) {
      element.position.width = Math.round(w);
      element.position.height = Math.round(h);
    },
    setLayoutElement(label, text) {
      const element = this.flyer.elements.find((element) => element.label === label);
      if (element) element.text = text;
    },
    getElementStyle(element) {
      if (element.type == 'text') {
        const textShadow = element.style.text.shadow.enable
          ? `${element.style.text.shadow.distance}px ${element.style.text.shadow.distance}px ${element.style.text.shadow.blur}px ${element.style.text.shadow.color}`
          : 'none';
        const boxShadow = element.style.box.shadow.enable
          ? `${element.style.box.shadow.distance}px ${element.style.box.shadow.distance}px ${element.style.box.shadow.blur}px ${element.style.box.shadow.color}`
          : 'none';

        const style = {
          width: '100%',
          height: '100%',
          'align-items': 'center',
          'border-radius': element.style.box.corners ? `${element.style.box['corner-radius']}px` : '0px',
          'box-shadow': boxShadow,
          'text-shadow': textShadow,
          fontSize: element.style.text.size + 'px',
          fontFamily: element.style.text.family,
          color: element.style.text.color,
          backgroundColor: `${element.style.box.color}`,
          'text-align': element.style.text.align,
          'font-style': element.style.text.italic ? 'italic' : 'normal',
          'font-weight': element.style.text.bold ? 'bold' : 'normal',
          'text-transform': element.style.text.transform,
          cursor: 'pointer',
          display: element.visible ? 'flex' : 'none',
        };
        return style;
      }

      if (element.type == 'image') {
        const boxShadow = element.style.box.shadow.enable
          ? `${element.style.box.shadow.distance}px ${element.style.box.shadow.distance}px ${element.style.box.shadow.blur}px ${element.style.box.shadow.color}`
          : 'none';

        const style = {
          width: '100%',
          height: '100%',
          'border-radius': element.style.box.corners ? `${element.style.box['corner-radius']}px` : '0px',
          'box-shadow': boxShadow,
          backgroundColor: `${element.style.box.color}`,
          'align-items': 'center',
          'justify-content': 'center',
          cursor: 'pointer',
          display: element.visible ? 'flex' : 'none',
        };
        return style;
      }
    },

    //History management functions
    uploadToHistory() {
      const dbRef = this.getLocationRef().child('flyers').child('generated');

      const key = dbRef.push().key;
      const obj = {
        title: this.flyer.title,
        url: this.flyer.image,
        tags: this.flyer.tags,
        orientation: this.flyer.paper.orientation,
        created: Date.now(),
        key: key,
      };

      dbRef.child(key).set(obj);
      this.history.generated.unshift(obj);
      this.selectedHistory = key;
      this.flyer.key = key;
    },
    setHistoryElements() {
      const dbRef = this.getLocationRef().child('flyers').child('generated').child(this.flyer.key);
      dbRef.child('elements').set(this.flyer.elements);
    },
    revertHistoryElements() {
      const elements = this.history.generated.find((item) => item.key === this.selectedHistory).elements;
      if (elements) this.flyer.elements = JSON.parse(JSON.stringify(elements));
      else this.flyer.elements = [];
    },
    async loadHistory() {
      const dbRef = this.getLocationRef().child('flyers').child('generated');
      const snapshot = await dbRef.once('value');
      const data = await snapshot.val();

      if (data) {
        for (let key in data) data[key].key = key;
        this.allHistory.generated = Object.values(data);
        this.allHistory.generated.sort((a, b) => b.created - a.created);
        this.history.generated = this.allHistory.generated.slice(0, 12);
      }
    },
    setFromHeader(image) {
      this.flyer.image = image.originalURL;
      this.reloadLayout(this.$route.params.action);
    },
    setFromHistory(image) {
      this.flyer.key = image.key;
      this.selectedHistory = image.key;

      this.flyer.image = image.url;
      this.flyer.title = image.title;
      this.flyer.paper.orientation = image.orientation || 'Portrait';
      if (image.elements?.length > 0) {
        this.flyer.elements = JSON.parse(JSON.stringify(image.elements));
        this.setToExistingFlyer();
      } else this.reloadLayout(this.$route.params.action);
      this.flyer.tags = image.tags || [];
      //this.customTags = this.flyer.tags.filter((tag) => this.tags.indexOf(tag) == -1);
    },
    deleteFromHistory(image) {
      this.$message.create({
        title: 'Delete Flyer',
        body: 'Are you sure you want to permanently delete this flyer from history?',
        buttons: [
          this.$message.button('Cancel', 'btn-secondary', null),
          this.$message.button('Delete', 'btn-danger', () => {
            const dbRef = this.getLocationRef().child('flyers').child('generated').child(image.key);
            dbRef.remove();
            this.history.generated = this.history.generated.filter((item) => item.key !== image.key);
            if (this.flyer.image == image.url) {
              this.flyer.image = null;
              this.flyer.title = '';
              this.flyer.tags = [];
              this.flyer.elements = [];
            }
          }),
        ],
      });
    },

    //QR code functions
    async createQR() {
      this.qrURL = await this.createAdjustDeepLink(
        'event',
        null,
        [{ key: 'itemDetailPath', value: this.item.detailPath }], //${dbContext}/${params.itemId}
        this.skipSplashScreen
      );
      this.qrImage = await QRCode.toDataURL(this.qrURL);
    },

    //Download functions
    async downloadAll() {
      if (!this.flyer.image) {
        this.$toast.message('Please generate an image or select one from history first.', 'warning');
        return;
      }

      if (this.flyer.imageType === 'existing' && !this.flyer.key) this.uploadToHistory();
      this.setHistoryElements();

      this.selectedElement = null;
      this.$nextTick(async () => {
        await this.downloadImage();
      });
    },
    async downloadBackground() {
      this.hideElements = true;
      this.$nextTick(async () => {
        await this.downloadImage();
        this.hideElements = false;
      });
    },
    async downloadImage() {
      const node = document.getElementById('image-margins');
      const image = document.getElementById('image-background');
      const targetWidth = 1024 + this.flyer.margins.width * 2;
      const targetHeight = 1792 + this.flyer.margins.height * 2;
      const targetScale = targetWidth / image.offsetWidth;

      const props = {
        scale: targetScale,
      };

      await domToImage
        .toPng(node, props)
        .then((dataUrl) => {
          this.downloadFile(dataUrl, `${this.flyer.title} flyer.png`);

          //Uncomment for preview/debugging
          // const img = new Image();
          // img.src = dataUrl;
          // img.crossOrigin = 'Anonymous';
          // img.style.maxWidth = '300px';
          // document.body.appendChild(img);
        })
        .catch((error) => {
          console.error('Error exporting png:', error);
        });
    },
    cancel() {
      this.$router.go(-1);
    },

    //Utility functions
    calculateAspectRatio(width, height, factor) {
      return (width / height) * factor;
    },
    getColorPickerBackground: function (newFont) {
      return { 'background-color': newFont || newFont.hex || this.fontColor.hex || this.fontColor };
    },
  },
};
</script>

<style lang="scss" scoped>
//Import css from vue-draggable-resizable
@import '../../node_modules/vue-draggable-resizable/dist/style.css';
.vdr {
  touch-action: none;
  position: absolute;
  box-sizing: border-box;
  border: 2px dashed blue;
}
.vdr-hidden {
  border: 0px dashed transparent;
}

#home {
  cursor: pointer;
}

#flyer-page {
  display: grid;
  grid-template-columns: 1fr 600px;
}
#image-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

//Border styling/margins
#image-borders {
  border: 1px dashed #666666;
}
#image-margins {
  width: 100%;
  height: 100%;
  // background-color: v-bind('flyer.margins.color');
  // padding: v-bind('marginHeight') v-bind('marginWidth');
}

#image-background {
  position: relative;
  width: v-bind('imageWidth');
  height: v-bind('imageHeight');
  background-repeat: no-repeat;
  background-size: v-bind('image.size');
  background-position: v-bind('imageOffsetX') v-bind('imageOffsetY');
}
#image-overlay {
  width: 100%;
  height: 100%;
}
#image-tint {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: v-bind('tint');
}
.image-none {
  width: 100%;
  height: 100%;
  padding: 50px;
  text-align: center;
  align-content: center;
}

//Right column
.history-item {
  position: relative;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 4px !important;
}
.history-thumbnail {
  height: 80%;
}
.history-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px auto 0px auto;
  width: 30px;
  height: 30px;
}
.history-button i {
  font-size: 20px;
}

.tag-badge:hover {
  opacity: 0.7;
}
.tag-badge {
  opacity: 1;
  user-select: none;
}

.layout-item {
  position: relative;
  margin: 8px;
  text-align: center;
  align-content: center;
  align-items: center;
  height: 100px;
  cursor: pointer;
}
.layout-thumbnail {
  height: 50px;
  border-radius: 5px;
}

input.flyer-checkbox {
  min-width: 15px !important;
  margin-left: 4px;
  margin-right: 8px;
}

.color-selector div {
  width: 20px;
  height: 20px;
}
.color-selector .btn {
  padding: 0.25rem 0.25rem;
}
</style>
