<template>
  <div id="filter" :class="{ collapse: true, 'mt-2': true, show: showDefault }">
    <template v-if="eventFilterData">
      <div class="row">
        <div class="col">
          <label class="mb-2"> Event Filter</label><br />
          <select v-model="filterEvent" class="form-select">
            <option value="">All Events</option>
            <option v-for="(event, id) in eventFilterData" :key="id" :value="id">
              {{ event.eventTitle }}
            </option>
          </select>
        </div>
      </div>
    </template>
    <template v-if="textFilterVisible">
      <div class="row">
        <div class="col">
          <label class="mb-2">Filter Title & Description</label><br />
          <input v-model="filterText" type="text" placeholder="Filter Items" />
        </div>
      </div>
    </template>
    <template v-if="dateFilterVisible">
      <div class="row">
        <div class="col">
          <DateTime
            ref="startDatePicker"
            label="Filter Start Date"
            :value="filterStartDate"
            format="MM/dd/yyyy"
            :min-date="null"
            :date-required="false"
            :time-required="false"
            :clearable="true"
            :time-requested="false"
            :null-default="null"
            @update="(val) => (filterStartDate = val)" />
        </div>
        <div class="col">
          <DateTime
            ref="endDatePicker"
            label="Filter End Date"
            :value="filterEndDate"
            format="MM/dd/yyyy"
            :min-date="null"
            :date-required="false"
            :time-required="false"
            :clearable="true"
            :time-requested="false"
            :null-default="null"
            @update="(val) => (filterEndDate = val)" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import DateTime from '@/components/DateTime2';

export default {
  components: { DateTime },
  props: {
    bulkSelected: { type: Array, default: () => [] },
    textFilterVisible: { type: Boolean, default: true },
    dateFilterVisible: { type: Boolean, default: true },
    externalFilters: { type: Boolean, default: false },
    eventFilterData: { type: Object, default: null },
    showDefault: { type: Boolean, default: false },
  },
  data() {
    return {
      filterText: '',
      filterEvent: '',
      filterStartDate: 0,
      filterEndDate: 0,
      bulkCheckAll: false,
    };
  },
  watch: {
    filterText: {
      handler() {
        this.$emit('filterText', this.filterText);
      },
    },
    filterStartDate: {
      handler() {
        this.$emit('filterDate', this.filterStartDate, this.filterEndDate);
      },
    },
    filterEndDate: {
      handler() {
        this.$emit('filterDate', this.filterStartDate, this.filterEndDate);
      },
    },
    filterEvent: {
      handler() {
        this.$emit('filterEvent', this.filterEvent);
      },
    },
  },
  mounted() {
    const clear = () => {
      this.filterText = '';
      this.filterEvent = '';
      this.$refs.startDatePicker.clear();
      this.$refs.endDatePicker.clear();
    };
    const any = () => {
      return this.filterText || this.filterStartDate || this.filterEndDate || this.externalFilters || this.eventFilterData;
    };
    this.$emit('ready', { clear: clear, any: any });
  },
  methods: {
    deleteSelected() {
      this.$emit('delete');
    },
    clearFilters() {
      this.filterText = '';
      this.filterEvent = '';
      this.$refs.startDatePicker.clear();
      this.$refs.endDatePicker.clear();

      this.$emit('clear-filters');
      console.log('clearing filters');
    },
    anyFilters() {
      return this.filterText || this.filterStartDate || this.filterEndDate || this.externalFilters || this.eventFilterData;
    },
  },
};
</script>

<style scoped></style>
