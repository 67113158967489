import { Tooltip } from 'bootstrap';

export const clickOutsideDirective = {
  beforeMount: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        if (binding.instance[el.id]) {
          binding.value(event);
        }
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};

export function createTooltipDirective(placement) {
  return {
    mounted(el, binding) {
      el._tooltipInstance = new Tooltip(el, {
        title: binding.value,
        placement: placement,
        customClass: 'custom-tooltip',
        html: true,
      });

      const showTooltip = () => el._tooltipInstance.show();
      const hideTooltip = () => el._tooltipInstance.hide();

      el.addEventListener('mouseover', showTooltip);
      el.addEventListener('mouseout', hideTooltip);

      el._showTooltip = showTooltip;
      el._hideTooltip = hideTooltip;
    },

    updated(el, binding) {
      // Dispose of the old tooltip instance and create a new one when content updates
      if (el._tooltipInstance) el._tooltipInstance.dispose();

      el._tooltipInstance = new Tooltip(el, {
        title: binding.value, // Ensure updated title
        placement: placement,
        customClass: 'custom-tooltip',
        html: true,
      });
    },

    unmounted(el) {
      el.removeEventListener('mouseover', el._showTooltip);
      el.removeEventListener('mouseout', el._hideTooltip);
      if (el._tooltipInstance) {
        el._tooltipInstance.dispose();
      }
    },
  };
}
