<template>
  <div>
    <div class="row">
      <div class="col" v-if="adjustDeepLinkURL == null">
        <div>
          <VueQrcode :value="deepLinkURL" />
        </div>
        <div>
          {{ title }}
        </div>
        <div>URL: {{ deepLinkURL }}</div>
      </div>
      <div class="col" v-if="adjustDeepLinkURL">
        <div class="mb-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="skipSplashScreen"
              @change="generateAdjustDeepLink"
              id="skipSplashScreenCheckbox" />
            <label class="form-check-label" for="skipSplashScreenCheckbox"> Skip Splash Screen(s) </label>
          </div>
        </div>
        <div>
          <VueQrcode ref="qrcodeRef" :value="adjustDeepLinkURL" />
        </div>
        <div>
          {{ title }}
        </div>
        <button type="button" class="btn btn-secondary mb-3 mt-2" @click="downloadImage">Download Image</button>
        <div style="overflow-wrap: break-word">
          URL:
          <sl-copy-button class="copy-button" error-label="URL Copied" @click="copyText">
            <sl-icon slot="success-icon" library="material" name="check_round" />
            <sl-icon slot="error-icon" library="material" name="check_round" />
          </sl-copy-button>
          {{ adjustDeepLinkURL }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SlCopyButton } from '@shoelace-style/shoelace';
import VueQrcode from 'vue3-qrcode';
export default {
  components: {
    VueQrcode,
  },
  props: {
    title: {
      type: String,
      default: 'QR Code',
    },
    action: {
      type: String,
      default: null,
    },
    actionKey: {
      type: String,
      default: null,
    },
    actionParams: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      deepLinkURL: '',
      adjustDeepLinkURL: '',
      skipSplashScreen: 'false',
    };
  },
  watch: {
    action: {
      deep: true,
      handler() {
        this.getDeepLinks();
      },
    },
    actionKey: {
      deep: true,
      handler() {
        this.getDeepLinks();
      },
    },
    actionParams: {
      deep: true,
      handler() {
        this.getDeepLinks();
      },
    },
  },
  async created() {
    this.getDeepLinks();
  },
  methods: {
    async getDeepLinks() {
      this.deepLinkURL = await this.createDeepLink(`deeplinks/${this.action}`, this.actionKey, this.actionParams);
      console.log('🚀 ~ file: Business.vue ~ line 821 ~ getDeepLink ~ this.deepLinkURL', this.deepLinkURL);
      this.generateAdjustDeepLink();
    },
    async generateAdjustDeepLink() {
      this.adjustDeepLinkURL = await this.createAdjustDeepLink(this.action, this.actionKey, this.actionParams, this.skipSplashScreen);
      console.log('🚀 ~ file: Business.vue ~ line 821 ~ getDeepLink ~ this.deepLinkURL', this.adjustDeepLinkURL);
      this.$emit('linkURL', this.adjustDeepLinkURL);
    },
    copyText() {
      this.$copyText(this.adjustDeepLinkURL);
    },
    downloadImage() {
      const a = document.createElement('a');
      a.href = this.$refs.qrcodeRef.dataUrl;
      a.download = `${this.title} QR Code.png`;
      a.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-button {
  --error-color: black;
}
</style>
