<template>
  <div class="two-column">
    <div id="center-column">
      <div id="item-header" :style="`${this.getBackgroundColorStyle}height:50px;`">
        <h1>Manage Maps</h1>
      </div>
      <ul id="items">
        <li id="item" v-for="(map, index) in maps" :key="index">
          <SimpleItems :item="map" :itemId="index" :dbContext="dbContext" @editItem="editItem" @deleteItem="deleteItem" />
        </li>
      </ul>
    </div>
    <div id="right-column" class="p-2">
      <component
        :is="rightColumnComponent"
        v-bind="rightColumnProps"
        @action="setAction"
        :key="rightColumnKey"
        :rightColumnKey="rightColumnKey"
        :action="action"
        :is-archived="isArchived"
        @header-info-update="headerInfoUpdate" />
    </div>
  </div>
</template>

<script>
import ButtonNavigation from '@/components/ButtonNavigation.vue';
import SimpleItems from './listitems/SimpleItems.vue';
import CreateMap from './forms/CreateMap.vue';

export default {
  components: {
    ButtonNavigation,
    SimpleItems,
    CreateMap,
  },
  data() {
    return {
      rightColumnComponent: 'ButtonNavigation',
      rightColumnProps: {
        buttonSection: 'actions',
        context: 'manage-maps',
        dbContext: this.dbContext,
        displayType: this.displayType,
      },
      dbContext: 'searchablemap-detail',
      dbRef: null,
      maps: [],
    };
  },
  async mounted() {
    await this.getMaps();
    console.log('calendars', this.calendars);
  },
  methods: {
    async getMaps() {
      this.dbRef = this.getContextRef();
      try {
        const snapshot = await this.dbRef.once('value');
        this.maps = snapshot.val();
      } catch (error) {
        console.error(error);
      }
    },
    editItem(itemId) {
      console.log('edit 2');

      //   let newParams = this.$route.params;
      //   newParams.dbContext = this.dbContext;
      //   newParams.itemId = itemId;
      //   newParams.type = this.displayType || 'business';
      //   this.$router.push({ name: 'detailEdit', params: newParams });

      this.$router.push(`/detail/searchablemap/searchablemap-detail/${itemId}`);
    },
    deleteItem(itemId) {
      delete this.maps[itemId];
    },
    async setAction(action) {
      if (action == 'submit' || action == 'cancel') {
        this.rightColumnComponent = 'ButtonNavigation';
        this.rightColumnProps = {
          buttonSection: 'actions',
          context: 'manage-maps',
          dbContext: this.dbContext,
          displayType: this.displayType,
        };

        if (action == 'submit') await this.getMaps();
      }
      if (action == 'create-map') {
        this.rightColumnComponent = 'CreateMap';
      }
    },
  },
};
</script>

<style scoped>
#items {
  list-style: none;
  color: white;
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

#items li {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 10px;
}

#items li > div {
  border-bottom: 1px solid black;
  padding: 8px 16px;
}
#items li ul li {
  display: list-item;
  list-style: disc;
}
#items li ol li {
  display: list-item;
}

.list-item {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 10px;
  cursor: pointer;
}
</style>
