<template>
  <div class="form-group mb-3">
    <label :for="label" class="form-label">{{ label }}</label>
    <a v-if="getURLForList" :href="getURLForList" class="add-item" target="_blank">Create New {{ label }}</a>
    <select class="form-select" :class="{ 'is-invalid': !state }" v-model="localValue" @change="selectItem">
      <option :value="null" disabled>-- Select {{ label }} --</option>
      <option v-for="item in itemList" :key="item.value" :value="item.value">{{ item.text }}</option>
    </select>
    <Validation :validator="!state" :feedback="`${label} is required.`" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: true,
    },
    dbContext: {
      type: String,
    },
    keyName: {
      type: String,
      default: '',
    },
    orderBy: {
      type: String,
      default: null,
    },
    community: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      itemList: [],
      communityKey: null,
      localValue: this.value,
    };
  },
  computed: {
    getURLForList() {
      switch (this.dbContext) {
        case 'custom-forms':
          return '/detail/forms/custom-forms';
        case 'hunts':
          return '/detail/hunts/hunts';
        case 'store':
          return '/detail/store/store';
        case 'locations':
          return '/listnm/locations';
        default:
          return null;
      }
    },
  },
  watch: {
    dbContext: function () {
      this.getItemList();
    },
    community: function () {
      this.getItemList();
    },
    value(newVal) {
      this.localValue = newVal;
    },
  },
  created() {
    this.getItemList();
  },
  methods: {
    async getItemList() {
      this.communityKey = this.community || this.getCampgroundKey;
      this.itemList = [];
      let dbRef = this.getContextRef(this.dbContext, this.communityKey);
      if (this.orderBy) {
        dbRef = dbRef.orderByChild(this.orderBy);
      }
      let valueList = [];
      await dbRef.once('value').then((forms) => {
        this.itemList = [];
        forms.forEach((element) => {
          if (element.child('type').val() !== 'rsvp') {
            const itemSelection = {
              value: !this.keyName ? element.key : element.child(this.keyName).val(),
              text: element.child('title').val() || element.child('name').val(),
            };
            this.itemList.push(itemSelection);
            valueList.push(itemSelection.value);
          }
        });
        if (!valueList.includes(this.value)) {
          this.selectItem(null);
        }
      });
    },
    selectItem(value) {
      if (value) {
        const selectedValue = value.target.options[value.target.selectedIndex].value;
        this.localValue = selectedValue;
        this.$emit('select', selectedValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-item {
  font-size: small;
  float: right;
}
</style>
