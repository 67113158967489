import 'firebase/compat/database';
import { typeMap, newObjectMap } from '@/json/listBuilderData.json';

export default {
  data() {
    const initialChunkSize = 50;
    const subsequentChunkSize = 5;
    return {
      //Chunking variables
      type: null,
      tabIndex: 0,
      databasePath: null,
      campgroundKey: null,
      locationDataRef: null,
      initialChunkSize,
      subsequentChunkSize,
      chunkSize: initialChunkSize,
      lastLoadedKey: null,
      preventShift: false,
      //Chunking arrays
      chunkKeys: [],
      chunkList: [],
      prefetchKeys: [],
      prefetchList: [],
      cachedKeys: [],
      cachedList: [],
      //Loading states
      isLoadingInitialChunks: true,
      isLoadingChunk: false,
      isClearingChunks: false,
      isScrollDisabled: false,
      isRemote: false,
      remoteInfo: null,
      chunksLoaded: false,
      view: 'view-active',
      //Actions / context variables
      actions: {
        default: () => {
          this.rightColumnProps = {
            buttonSection: 'actions',
            context: this.context,
            dbContext: this.dbContext,
            displayType: this.displayType,
            isRemote: this.isRemote,
          };
          if (this.displayType) {
            this.rightColumnComponent = 'ButtonNavigation';
          }
        },
      },
      action: null,
      params: null,
      dbContext: null,
      context: 'events',
      displayType: 'events',
      tabs: null,
      viewArchived: false,
      isSystemwide: false,
      //Right column variables
      rightColumnKey: null,
      rightColumnProps: {},
      rightColumnComponent: 'ButtonNavigation',
      rightColumnItem: {},
      //Header image mapping
      headerContextMap: {
        'events-resort': 'events',
        'events-local': 'events',
        'services-resort': 'services',
        'services-local': 'services',
        'recreation-resort': 'recreation',
        'recreation-local': 'recreation',
        'reservations-lodging': 'reservations',
        'reservations-misc': 'reservations',
        'maps-resort': 'maps',
        'maps-local': 'maps',
        'dining-resort': 'dining',
        'dining-local': 'dining',
        'dining-delivery': 'dining',
      },
      _debug: false,
      bulkCheckAll: false,
      bulkSelected: [],
      filterText: '',
      filterStartDate: null,
      filterEndDate: null,
      filterEvent: '',
      showOverlay: false,
    };
  },
  watch: {
    bulkCheckAll: async function (val) {
      await this.loadOnSelectAll();

      this.bulkSelected = [];
      if (val) {
        this.chunkKeys.forEach((key) => this.bulkSelected.push(key));
      }
    },
    filterEvent: async function (newVal, oldVal) {
      if (newVal == null || newVal == '') {
        this.resetToCached();
      }

      await this.loadOnSelectAll();
      this.filterData();
    },
    filterText: async function (newVal, oldVal) {
      if (newVal == null || newVal == '') {
        this.resetToCached();
      }

      await this.loadOnSelectAll();
      this.filterData();
    },
    filterStartDate: async function (newVal, oldVal) {
      await this.loadOnSelectAll();
      this.filterData();
    },
    filterEndDate: async function (newVal, oldVal) {
      await this.loadOnSelectAll();
      this.filterData();
    },
    chunkKeys: function (newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        const diff = newVal.filter((item) => !oldVal.includes(item));
        diff.forEach((key) => {
          if (!this.cachedKeys.includes(key)) {
            this.cachedKeys.push(key);
          }
        });
        diff.forEach((key) => {
          let item = this.getItemByKey(key);
          if (!this.cachedList.includes(item)) {
            this.cachedList.push(item);
          }
        });
      }
    },
  },
  computed: {
    centerComponent() {
      return typeMap[this.type][this.tabIndex].listComponent;
    },
  },
  created() {
    this.campgroundKey = this.$route.params.context !== 'systemwide-notification' ? this.getCampgroundKey : 'system-data';
    if (!['remoteCalendar', 'remoteCalendarNoKey'].includes(this.$route?.name)) this.locationDataRef = this.getLocationRef(this.campgroundKey);
  },
  methods: {
    //Infinite scrolling setup
    setRouteProperties() {
      const params = this.$route.params;
      if (params) {
        this.dbContext = this.dbContext || params.context;
        this.view = params.view || 'view-active';
        this.tabIndex = params.tab || 0;
        this.isSystemwide = params.context == 'systemwide-notification';
      }
    },
    setDatabasePath(path, context) {
      if (this.databasePath) this.databasePath.off();

      this.dbContext = context;
      this.databasePath = path.child(context);
      if (this._debug) {
        console.log('Set path to:', this.databasePath.toString());
        console.log('Set dbContext to:', this.dbContext);
      }
    },

    //Infinite scrolling data management functions
    async nextChunk() {
      if (this.isLoadingChunk || this.chunksLoaded) return;
      this.isLoadingChunk = true;
      this.$emit('chunks-loading');

      if (this.prefetchList && this.prefetchList.length > 0) {
        this.chunkList.push(...this.prefetchList);
        this.chunkKeys.push(...this.prefetchKeys);
        if (this._debug) console.log(`Used ${this.prefetchList.length} prefetched data for next chunk.`);
        this.prefetchList = null;
        this.prefetchKeys = null;
      } else {
        const { list, keys } = await this._getChunk('main chunk');
        this.chunkList.push(...list);
        this.chunkKeys.push(...keys);
      }
      this.isLoadingChunk = false;

      const { list, keys } = await this._getChunk('prefetch chunk');
      this.prefetchList = list;
      this.prefetchKeys = keys;
      if (this._debug) console.log(`Prefetched ${list.length} items for next chunk.`);

      //this.verifyChunkSize();
    },
    async _getChunk(target) {
      let result = { keys: [], list: [] };
      let query = this.chunkQuery(this.databasePath);
      try {
        const data = (await query.once('value')) || null;
        const dataArray = [];
        const keyArray = [];
        data.forEach((child) => {
          const val = child.val();
          if (this.skipQueryItem(child.key, val)) return;

          keyArray.push(child.key);
          dataArray.push(val);
        });
        if (keyArray.length > 0) {
          // If lastLoadedKey is set, skip the first record
          if (this.lastLoadedKey) {
            if (this.preventShift === false) {
              dataArray.shift();
              keyArray.shift();
            }
            if (dataArray.length == 0) {
              this.chunksLoaded = true;
              this.$emit('chunks-loaded');
            }
          }

          //this.clearKeyConflicts();
          this.logKeyConflicts();

          result.list.push(...dataArray);
          result.keys.push(...keyArray);
          this.setLastKey(result);
          result = this.changeOrder(result);
          if (this._debug) {
            console.log(`Assigned lastLoadedKey to ${this.lastLoadedKey} for ${target}.`);
            console.log(`Loaded ${result.keys.length} items for ${target}.`);
          }
        } else {
          this.chunksLoaded = true;
          console.log('Database node is empty.', this.databasePath.toString());
        }
      } catch (error) {
        console.error('Error loading data:', error);
      }

      if (this.isLoadingInitialChunks == true) {
        this.isLoadingInitialChunks = false;
        this.initialLoad();
        this.chunkSize = this.subsequentChunkSize;

        this.cachedKeys = [...result.keys];
        this.cachedList = [...result.list];
      }

      return result;
    },
    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (
        this.isScrollDisabled == false &&
        this.chunksLoaded == false &&
        scrollTop + clientHeight >= scrollHeight - scrollHeight * 0.2 &&
        scrollTop > 0
      ) {
        await this.nextChunk();
      }
    },
    // filterSetup(newVal, oldVal) {
    //   if (!oldVal) {
    //     this.chunkKeysOrig = [...this.chunkKeys];
    //     this.chunkListOrig = [...this.chunkList];
    //   }
    //   if (!newVal) {
    //     this.chunkKeys = [...this.chunkKeysOrig];
    //     this.chunkList = [...this.chunkListOrig];
    //   }
    //   this.chunkList = [];
    //   this.chunkKeys = [];
    // },

    //Override these functions in the component for custom behavior (e.g, sorting, filtering, etc)
    setLastKey(result) {
      this.lastLoadedKey = result.keys[result.keys.length - 1];
    },
    chunkQuery(query) {
      if (this.lastLoadedKey) query = query.orderByKey().startAt(String(this.lastLoadedKey));
      query = query.limitToFirst(this.chunkSize);
      return query;
    },
    skipQueryItem(key, val) {
      return false;
    },
    changeOrder(results) {
      return results;
    },

    //Filtering functions
    filterData() {
      this.chunkKeys = [];
      this.chunkList = [];

      this.chunkKeys = this.cachedKeys.filter((_, index) => {
        const item = this.cachedList[index];
        if (this.checkFilters(item)) {
          this.chunkList.push(item);
          return true;
        }
        return false;
      });

      this.checkScrollDisable();
      this.postFilterData();
    },
    //This function should be overridden in the component to filter the data based on the filter text.
    checkFilters(item) {
      const text = this.filterText.toLowerCase();
      return this.isFilterContains(item.title, text) || this.isFilterContains(item.description, text);
      const hasEvent = !this.filterEvent || this.filterEvent === '' || this.filterEvent === item.eventId;
      return hasText && hasEvent;
    },
    postFilterData() {},
    isFilterContains(value, text) {
      return (value || '').toLowerCase().includes(text);
    },
    checkScrollDisable() {
      if (this.filterText || this.filterStartDate || this.filterEndDate) this.isScrollDisabled = true;
      else this.isScrollDisabled = false;
    },
    async loadOnSelectAll() {
      if (this.chunksLoaded == false && this.isClearingChunks == false) {
        this.showOverlay = true;
        await this.loadAll();
        this.showOverlay = false;
      }
    },
    bulkCheckItem(key, checked) {
      console.log('TCL: bulkCheckItem -> key', key);
      const index = this.bulkSelected.indexOf(key);
      if (index === -1) {
        this.bulkSelected.push(key);
      } else {
        this.bulkSelected.splice(index, 1);
      }
    },

    //Called after the initial chunk is loaded (or when chunksLoaded is true for data less than chunk size).
    initialLoad() {},

    //Right column functions
    addAction(name, func) {
      if (!name || name == '') {
        console.error('addAction: name is required');
        return;
      }
      if (!func || typeof func !== 'function') {
        console.error('addAction: func is required and must be a function');
        return;
      }

      this.actions[name] = func;
    },
    async setAction(action) {
      this.action = action;
      this.params = arguments;

      if (this.actions.hasOwnProperty(action)) {
        //if (arguments.length == 1) console.log(`Calling ${action} action.`);
        //else console.log(`Calling ${action} action with ${arguments.length - 1} arguments.`, arguments);
        await this.actions[action](this.params);
      } else {
        this.actions.default(this.params);
      }
    },
    editItem(key) {
      this.rightColumnKey = key;
      this.setAction('edit');
    },
    deleteItem(keys) {
      const keyCopy = Array.isArray(keys) ? [...keys] : [keys];
      const removeFrom = (key, keys, list) => {
        const index = keys.indexOf(key);
        if (index > -1) {
          keys.splice(index, 1);
          list.splice(index, 1);
        }
      };

      keyCopy.forEach((key) => {
        removeFrom(key, this.chunkKeys, this.chunkList);
        removeFrom(key, this.cachedKeys, this.cachedList);
      });

      this.setAction('delete');
    },

    //Chunk management functions
    getItemByKey(key) {
      return this.chunkList[this.chunkKeys.indexOf(key)];
    },
    getKeyByItem(item) {
      return this.chunkKeys[this.chunkList.indexOf(item)];
    },
    changeView(view) {
      this.clearChunks();
      this.action = null;
      this.view = view;
      this.isArchived = view == 'view-archived';
    },
    async loadAll() {
      let currentChunkSize = this.chunkSize;
      this.chunkSize = 10000;
      const query = this.chunkQuery(this.databasePath);
      const data = (await query.once('value')) || null;
      this.chunkSize = currentChunkSize;

      if (data) {
        this.chunkKeys = [];
        this.chunkList = [];
        this.loadChunksFromData(data);
        this.chunksLoaded = true;
        this.$forceUpdate();
      } else {
        console.log('Database node is empty.', this.databasePath.toString());
      }
    },
    loadChunksFromData(data) {
      data.forEach((child) => {
        this.chunkKeys.push(child.key);
        this.chunkList.push(child.val());
      });
    },
    resetToCached() {
      this.chunkList = this.cachedList;
      this.chunkKeys = this.cachedKeys;
    },

    //This method prevents the component from not being able to trigger more chunks
    //if the initial chunk is less than the chunk size due to filtering.
    async verifyChunkSize() {
      if (this.chunkList.length < this.chunkSize && !this.chunksLoaded) {
        if (this._debug) console.log(`Chunk size under limit and not fully loaded (${this.chunkList.length} < ${this.chunkSize})`);
        await this.nextChunk();
      }
    },
    clearChunks() {
      this.isClearingChunks = true;
      this.chunkSize = this.initialChunkSize;
      this.chunkKeys = [];
      this.chunkList = [];
      this.prefetchKeys = [];
      this.prefetchList = [];
      this.cachedKeys = [];
      this.cachedList = [];
      this.bulkSelected = [];
      this.bulkCheckAll = false;

      this.isLoadingInitialChunks = true;
      this.isLoadingChunk = false;
      this.chunksLoaded = false;
      this.lastLoadedKey = null;
      this.$nextTick(() => (this.isClearingChunks = false));
    },
    clearKeyConflicts() {
      const keySet = new Set(this.chunkKeys);
      if (keySet.size !== this.chunkKeys.length) {
        this.chunkList = this.chunkList.filter((item, index) => this.chunkKeys.indexOf(this.getKeyByItem(item)) === index);
        this.chunkKeys = this.chunkKeys.filter((key, index) => this.chunkKeys.indexOf(key) === index);
      }
    },
    logKeyConflicts() {
      const keySet = new Set(this.chunkKeys);
      if (keySet.size !== this.chunkKeys.length) {
        const conflicts = this.chunkKeys.filter((key, index) => this.chunkKeys.indexOf(key) !== index);
        if (this._debug) console.warn('Key conflicts:', conflicts);
      }
    },
  },
};
