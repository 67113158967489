<template>
  <div class="p-2 form-container">
    <template v-for="(huntStep, key) in item.steps">
      <div v-if="huntStep.type !== 'golf'" :key="key">
        <div class="fw-bold">{{ huntStep.title }}:</div>
        <div v-if="entry[huntStep.key]" class="ps-2">
          <div v-if="huntStep.type == 'photo'">
            <div v-if="entry[huntStep.key].drawable" :style="{ display: 'flex', 'justify-content': 'space-between', margin: 'auto' }">
              <img :src="entry[huntStep.key].drawable" width="150px" />
              <HuntEntryAction :score-map="scoreMap" :hunt-step="huntStep" @update-score="updateScore"></HuntEntryAction>
            </div>
            <div v-else>Not Answered</div>
          </div>
          <!-- <div v-else-if="huntStep.type == 'golf'">
          <div v-for="name in entry[huntStep.key].names" :key="name">
            {{ name }} :
            {{ entry[huntStep.key].scores && entry[huntStep.key].scores[name] ? entry[huntStep.key].scores[name] : '-' }}
          </div>
        </div> -->
          <div v-else-if="huntStep.type == 'answer'" :style="{ display: 'flex', 'justify-content': 'space-between', margin: 'auto' }">
            {{ entry[huntStep.key].answer ? entry[huntStep.key].answer : 'Not Answered' }}
            <HuntEntryAction :score-map="scoreMap" :hunt-step="huntStep" @update-score="updateScore"></HuntEntryAction>
          </div>
          <div v-else-if="huntStep.type == 'multiple_choice_single'" :style="{ display: 'flex', 'justify-content': 'space-between', margin: 'auto' }">
            {{ entry[huntStep.key].answer ? entry[huntStep.key].answer : 'Not Answered' }}
            <HuntEntryAction :score-map="scoreMap" :hunt-step="huntStep" @update-score="updateScore"></HuntEntryAction>
          </div>
          <div v-else>
            <div v-if="scoreMap[huntStep.key] == 0">
              Incorrect Scan
              <i v-if="scoreMap[huntStep.key] === 0" class="bi bi-x text-danger"></i>
              <button
                v-if="scoreMap[huntStep.key] === 0 || scoreMap[huntStep.key] === 1"
                class="btn btn-danger ms-2"
                @click="updateScore(huntStep.key, null)">
                Clear score
              </button>
            </div>
            <div v-else-if="scoreMap[huntStep.key] == 1">
              Correct Scan
              <i v-if="scoreMap[huntStep.key] === 1" class="bi bi-check text-success"></i>
              <button
                v-if="scoreMap[huntStep.key] === 0 || scoreMap[huntStep.key] === 1"
                class="btn btn-danger ms-2"
                @click="updateScore(huntStep.key, null)">
                Clear score
              </button>
            </div>
            <div v-else>Not Answered</div>
          </div>
        </div>
        <div class="ps-2" v-else>Not Answered</div>
      </div>
    </template>
    <div v-if="item.type === 'golf'" :style="{ 'margin-top': '16px' }">
      <!-- <b-table striped hover :items="summary"></b-table> -->
      <div class="grid-container">
        <!-- Title Row. This is Hole, 1, 2, 3, ..., ending with Total. -->
        <div class="grid-row">
          <div v-for="title in golfTitles" :key="title" class="grid-cell golf-holes">{{ title }}</div>
        </div>
        <!-- Par Data Row. This is Par, 5, 5, 3, ... etc -->
        <div class="grid-row">
          <div v-for="(par, index) in golfPar" :key="par + '-' + index" class="grid-cell golf-par">{{ par }}</div>
        </div>
        <!-- Player Data Rows -->
        <div v-for="(player, name) in golfPlayers" :key="name" class="grid-row">
          <div class="grid-cell golf-name">{{ name }}</div>
          <div v-for="(score, index) in player" :key="name + '-' + index" class="grid-cell golf-score">{{ score }}</div>
          <div class="grid-cell golf-total">{{ calculateTotal(player) }}</div>
        </div>
      </div>
    </div>
    <div v-else :style="{ 'margin-top': '16px' }">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Summary</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, key) in summary" :key="key">
            <td>{{ key }}</td>
            <td>{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <form novalidate @submit.prevent="submitUpdate">
      <button class="btn m-1 btn-primary" type="button" @click.stop="markItem(completedValues.completed)">Completed</button>
      <button class="btn m-1 btn-secondary" type="button" @click.stop="markItem(completedValues.denied)">Denied</button>
      <button class="btn m-1 btn-secondary" type="button" @click.stop="cancelUpdate">Cancel</button>
    </form>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Vue from 'vue';
// import { BIconCheck, BIconX } from 'bootstrap-vue';
import HuntEntryAction from '@/components/HuntEntryAction.vue';

import { NonDetailFormMixin } from '../mixins/NonDetailFormMixin.js';

export default {
  components: {
    // BIconCheck,
    // BIconX,
    HuntEntryAction,
  },
  mixins: [NonDetailFormMixin],
  props: ['dbContext', 'entry', 'item', 'entryId'],
  emits: ['hunt-entry-updated'],
  data() {
    return {
      completedValues: {
        completed: 2,
        open: 0,
        denied: 1,
      },
      userName: 'N/A',
      completed: null,
      scoreMap: {},
      golfTitles: [],
      golfPar: [],
      golfPlayers: {},
      summary: [
        {
          summary: 'Total Answers',
          value: 0,
        },
        {
          summary: 'Total Correct Answers',
          value: 0,
        },
        {
          summary: 'Total Wrong Answers',
          value: 0,
        },
      ],
    };
  },
  watch: {
    item: {
      handler: 'updateSummary',
      immediate: true,
    },
  },
  mounted() {
    for (const huntStep of this.item.steps) {
      this.setScored(huntStep.key);
    }
  },
  methods: {
    setScored: function (key) {
      Vue.set(this.scoreMap, key, isNaN(this.entry[key]?.scored) ? null : this.entry[key]?.scored);
      this.updateSummary();
    },
    markItem: function (value) {
      this.entry.completed = value;
      this.$emit('action', 'mark-item', value, this.entryId, this.entry);
      this.cancelUpdate();
    },
    updateScore: async function (key, score) {
      const dbRef = firebase
        .database()
        .ref('resort-navigator')
        .child('user-written')
        .child(this.getCampgroundKey)
        .child(this.dbContext)
        .child(this.$route.params.itemId)
        .child(this.entryId)
        .child(key);

      if (score === 1 || score === 0) {
        await dbRef.child('scored').set(score);
        this.scoreMap[key] = score;
      } else {
        dbRef.child('scored').remove();
      }
      Vue.set(this.scoreMap, key, score);
      this.scoreMap = JSON.parse(JSON.stringify(this.scoreMap));
      this.updateSummary();
    },
    updateSummary: function () {
      if (this.item.type == 'golf') {
        const golfPlayers = {};

        //Populate holes and par data.
        const { golfPar, golfTitles, golfParTotal } = this.item.steps
          .filter((step) => step.type === 'golf')
          .reduce(
            (acc, step) => {
              acc.golfPar.push(step.par);
              acc.golfTitles.push(step.title);
              acc.golfParTotal += step.par;

              return acc;
            },
            {
              golfPar: ['Par'],
              golfTitles: ['Hole'],
              golfParTotal: 0,
            }
          );

        this.golfPar = golfPar;
        this.golfTitles = golfTitles;
        this.golfPar.push(golfParTotal);
        this.golfTitles.push('Total');

        this.item.steps
          .filter((step) => step.type === 'golf')
          .reduce(
            (acc, step, index) => {
              // Iterate through each hunt step.
              const scores = this.entry[step.key]?.scores || {};
              //Iterate through every player's name/score in the hole.
              for (const name in scores) {
                if (!(name in golfPlayers)) {
                  golfPlayers[name] = new Array(golfPar.length - 2).fill('-');
                }
                //Add score for name to their array, where index is equal to hole.
                golfPlayers[name][index] = scores[name] || '-'; //.push(scores[name]);
              }
              index++;
              return acc;
            },
            {
              golfPlayers: {},
            }
          );
        this.golfPlayers = golfPlayers;
      } else {
        //Calculate and set generic hunt summary.
        this.summary = this.composeSummary(this.item, this.entry, this.scoreMap);
      }
    },
    calculateTotal(playerData) {
      const validScores = playerData.filter((score) => score !== '-').map(Number);
      return validScores.reduce((total, score) => total + score, 0);
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: minmax(60px, max-content) minmax(60px, max-content) repeat(auto-fit, minmax(50px, 1fr));
  grid-column-gap: 0px;
}

.grid-row {
  display: grid;
  grid-template-rows: minmax(40px, max-content) repeat(auto-fit, minmax(50px, 1fr));
  grid-column-gap: 0px;
}

.grid-cell {
  margin: 1px 1px;
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  min-height: 50px;
}

.grid-cell.golf-holes {
  background-color: #1a4261;
  color: white;
  padding: 8px;
}

.grid-cell.golf-par {
  background-color: #c5a845;
  color: white;
}

.grid-cell.golf-name {
  background-color: #838383;
  color: white;
}

.grid-cell.golf-score {
  background-color: white;
  color: black;
}

.grid-cell.golf-total {
  background-color: #838383;
  color: white;
}
</style>
