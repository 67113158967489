<template>
  <div class="p-2 form-container">
    <div v-if="this.createDate">
      <div class="fw-bold">Date Submitted in App:</div>
      <div class="ps-2">
        {{ this.createDate }}
      </div>
    </div>
    <a v-if="pdfURL" :href="pdfURL"> Download PDF File </a>
    <template v-for="(formItem, key) in item.formSchema">
      <div v-if="!['header', 'label', 'static'].includes(formItem.type)" :key="key">
        <div class="fw-bold">{{ formItem.label }}:</div>
        <div class="ps-2" v-if="formItem.type !== 'image'">
          {{ getValue(formItem) }}
        </div>
        <img v-else :src="imageURLs[formItem.name]" class="w-100" />
      </div>
    </template>
    <form @submit.prevent="submitUpdate" novalidate>
      <button class="btn m-1 btn-primary" type="button" @click.stop="markItem(completedValues.completed)">Completed</button>
      <button class="btn m-1 btn-secondary" type="button" @click.stop="markItem(completedValues.denied)">Denied</button>
      <button class="btn m-1 btn-secondary" type="button" @click.stop="cancelUpdate">Cancel</button>
    </form>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import moment from 'moment';

import Vue from 'vue';
import { NonDetailFormMixin } from '../mixins/NonDetailFormMixin.js';

export default {
  data() {
    return {
      newItem: {
        completed: null,
      },
      completedValues: {
        completed: 2,
        open: 0,
        denied: 1,
      },
      userName: 'N/A',
      completed: null,
      createDate: null,
      pdfURL: '',
      imageURLs: {},
    };
  },
  mixins: [NonDetailFormMixin],
  props: ['dbContext', 'entry', 'item', 'entryId', 'itemId'],
  watch: {
    entry: {
      handler() {
        console.log('🚀 ~ file: FormEntry.vue ~ line 63 ~ handler ~ handler');
        this.pdfURL = '';
        this.getDownloadURL();
      },
      deep: true,
    },
    entryId: function () {
      this.setupEntry();
    },
  },
  mounted() {
    this.setupEntry();
  },
  methods: {
    setupEntry() {
      this.newItem = {
        completed: null,
      };
      this.completedValues = {
        completed: 2,
        open: 0,
        denied: 1,
      };
      this.userName = 'N/A';
      this.completed = null;
      this.createDate = null;
      this.getDownloadURL();
      this.createDate = moment.tz(this.entry.createDate, this.getTimeZone).format('MM/DD/YYYY hh:mm a');
      Object.keys(this.item.formSchema).forEach((formKey) => {
        if (this.item.formSchema[formKey].type == 'image') {
          let key = this.item.formSchema[formKey].name;
          if (this.entry[key]) {
            this.getImage(this.entry[key], key);
          }
        }
      });
    },
    getDownloadURL: function () {
      if (this.entry.file) {
        let storageRef = firebase
          .storage()
          .ref()
          .child(this.getCampgroundKey)
          .child('forms/completed')
          .child(this.$route.params.itemId)
          .child(this.entry.file);
        storageRef.getDownloadURL().then((url) => {
          this.pdfURL = url;
        });
      }
    },
    markItem: function (value) {
      this.$emit('action', 'mark-item', value, this.entryId, this.item);
      this.cancelUpdate();
    },
    getValue: function (formItem) {
      let value = 'Not Answered';
      if (this.entry[formItem.name] && this.entry[formItem.name].value) {
        value = this.entry[formItem.name].value;
      } else if (this.dbContext == 'user-feedback') {
        value = this.entry[formItem.name];
      } else if (formItem.type == 'signature') {
        value = 'Check PDF';
      }
      if (formItem.type == 'date') {
        value = moment.tz(value, this.getTimeZone).format('MM/DD/YYYY');
      }
      return value;
    },
    getImage: function (formItem, formKey) {
      if (formItem.value && formItem.value == 'true') {
        let storage = firebase.storage().ref();
        let filename = `${formKey}.jpg`;
        let storageRef = storage
          .child('user-written')
          .child(this.getCampgroundKey)
          .child('forms')
          .child(this.$route.params.itemId)
          .child(this.entryId);

        storageRef
          .child(filename)
          .getDownloadURL()
          .then((url) => {
            Vue.set(this.imageURLs, formKey, url);
          })
          .catch(() => {
            storageRef
              .child(`${filename}.jpg`)
              .getDownloadURL()
              .then((url) => {
                Vue.set(this.imageURLs, formKey, url);
              });
          });
      } else if (formItem.value) {
        Vue.set(this.imageURLs, formKey, formItem.value);
      }
    },
  },
  computed: {},
};
</script>

<style scoped></style>
