<template>
  <div class="two-column">
    <div id="center-column">
      <div id="item-header" :style="getHeaderStyles()">
        <iframe
          v-if="newItem.headerInfo && newItem.headerInfo.videoURL"
          width="490"
          height="261"
          :src="`https://www.youtube.com/embed/${newItem.headerInfo.videoURL}`"
          title="YouTube video player"
          frameborder="0"
          allow="mute; autoplay; allowfullscreen"></iframe>
        <div
          v-if="newItem.headerInfo && newItem.headerInfo.images && newItem.headerInfo.images.length > 0"
          id="listBuilderCarousel"
          class="carousel slide"
          data-bs-ride="carousel"
          :data-bs-interval="newItem.headerInfo.delaySeconds * 1000 || 5000">
          <div class="carousel-inner">
            <div v-for="(src, index) in newItem.headerInfo.images" :key="index" class="carousel-item" :class="{ active: index === 0 }">
              <img :src="src.imageURL" class="d-block w-100" :alt="src.caption" style="height: 261px" />
              <div class="carousel-caption d-none d-md-block">
                <p>{{ src.caption }}</p>
              </div>
            </div>
          </div>
          <ol class="carousel-indicators" style="list-style-type: none">
            <li
              v-for="(src, index) in newItem.headerInfo.images"
              :key="index"
              data-bs-target="#listBuilderCarousel"
              :data-bs-slide-to="index"
              :class="{ active: index === 0 }"></li>
          </ol>
          <button
            v-if="newItem.headerInfo.images && newItem.headerInfo.images.length > 1"
            id="carousel-control-prev"
            class="carousel-control-prev"
            type="button"
            data-bs-target="#listBuilderCarousel"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            v-if="newItem.headerInfo.images && newItem.headerInfo.images.length > 1"
            id="carousel-control-next"
            class="carousel-control-next"
            type="button"
            data-bs-target="#listBuilderCarousel"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <h1 v-show="!newItem.headerInfo || !newItem.headerInfo.hideTitle">{{ newItem.title }}</h1>
      </div>
      <div class="row buttonRow">
        <div v-if="!newItem.rsvpRequest" class="col">
          <DetailButton button-text="Add Favorite" button-icon="star" />
        </div>
        <div v-else class="col" @click="openRSVP()">
          <DetailButton button-text="RSVP" button-icon="person_add" />
        </div>
        <div v-if="newItem.phone" class="col">
          <DetailButton button-text="Call" button-icon="call" />
        </div>
        <div v-if="displayMap" class="col">
          <DetailButton button-text="Map" button-icon="map" />
        </div>
        <div v-if="newItem.website" class="col">
          <DetailButton button-text="Website" button-icon="link" @click="openURL(newItem.website)" />
        </div>
        <div v-if="newItem.ticketing" class="col">
          <DetailButton button-text="Tickets" button-icon="theaters" @click="openURL(newItem.ticketing)" />
        </div>
        <div v-if="newItem.huntKey" class="col">
          <DetailButton button-text="Start Hunt" button-icon="search" />
        </div>
        <div v-for="(button, index) in newItem.detailButtons" :key="index" class="col">
          <DetailButton
            :button-text="button.title"
            :button-icon="button.icon"
            :action="button.action"
            :action-key="button.actionKey"
            :svg="button.iconData ? button.iconData.svg : null" />
        </div>
      </div>
      <div class="row m-1">
        <div class="col">
          <div v-if="newItem.startDate">
            <div class="fw-bold" style="display: flex">
              {{ getEventDate() }} &nbsp;
              <div v-if="!newItem.allDay" style="display: flex">
                <div>{{ getFormattedTime(newItem.startDate) }}&nbsp;</div>
                <div v-if="!newItem.endDateHide">- {{ getFormattedTime(newItem.endDate) }}</div>
              </div>
              <div v-else>All Day</div>
            </div>
          </div>
          <div v-if="getLocation()">
            <div class="fw-bold mt-2">Location: {{ getLocation() }}</div>
          </div>
          <span v-html="newItem.description"></span>
          <div class="fw-bold mt-2 text-capitalize">Audience: {{ newItem.audience }}</div>
        </div>
      </div>
    </div>
    <div id="right-column" class="p-2">
      <button class="btn btn-primary" style="width: 98.5%; height: 36px; padding: 4px; margin: 0px 0px 10px 6px" @click.stop="openAssistants()">
        <img src="@/assets/openai_white.png" style="width: 24px; height: auto" />
        Generate Event Ideas
      </button>
      <div class="p-2 form-container" :style="getContainerStyle">
        <div v-if="importCalendar" class="alert alert-info" role="alert">
          <small
            >Event imported from "<strong>{{ importCalendar.title || importCalendar.calendarId }}</strong
            >" calendar.</small
          >
        </div>
        <form v-else novalidate @submit.prevent="submitUpdate">
          <b-accordion
            id="events"
            :headings="[
              'Existing Events',
              'General Information',
              'Dates',
              'Location',
              'Header Image(s)/Video',
              'Additional Features',
              'Additional Buttons',
              'Associated Calendars',
              'QR Code',
            ]"
            :hidden="[dbContext === 'google-events-import' ? '' : 'Existing Events', newItem.creator ? '' : 'QR Code']"
            :open-index="dbContext === 'google-events-import' ? 1 : 2">
            <template #heading="{ heading }">
              <div v-if="heading === 'Existing Events'">
                {{ 'Existing Events ' + getFormattedDay(startDate) }}
              </div>
              <div v-else>{{ heading }}</div>
            </template>

            <template #body="{ heading }">
              <div v-if="heading === 'Existing Events'">
                <div v-for="existingEvent in existingEvents" :key="existingEvent.key">
                  <a
                    :href="`${$hostname}/detail/events/${existingEvent.context}/${existingEvent.key}`"
                    :class="{ 'text-danger': existingEvent.title.toLowerCase() === newItem.title.toLowerCase() }"
                    >{{ existingEvent.title }}</a
                  >
                </div>
              </div>

              <div v-if="heading === 'General Information'">
                <div class="mb-3" style="flex-grow: 2">
                  <label class="mb-2 form-label">Title</label>
                  <div style="display: flex">
                    <input
                      v-model="newItem.title"
                      class="form-control"
                      :class="{ 'is-invalid': vuelidate$.newItem.title && vuelidate$.newItem.title.$dirty && vuelidate$.newItem.title.$error }"
                      :disabled="isImportedEvent"
                      trim />
                    <TextAIButton
                      name="😄"
                      tooltip="Emojify"
                      pre-msg="Add emojis to this title"
                      :text="newItem.title"
                      post-msg="Use title case."
                      @action="onEmojifyTitle" />
                    <button
                      type="button"
                      class="btn btn-primary"
                      style="width: 36px; height: 36px; padding: 4px; margin: 1px 0px 10px 6px"
                      :disabled="!newItem.title || newItem.title === 0"
                      @click.stop="setDataToAI()">
                      <img src="@/assets/openai_white.png" style="width: 24px; height: auto" />
                    </button>
                  </div>
                  <small class="form-text text-muted">{{ isImportedEventDescription }}</small>
                  <Validation :validator="vuelidate$.newItem.title" feedback="Title is required." />
                </div>
                <div>
                  <div class="mb-3">
                    <label class="mb-2 form-label">Description</label>
                    <editor ref="descriptionEditor" v-model="newItem.description" :disabled="isImportedEvent"></editor>
                    <small class="form-text text-muted">{{ isImportedEventDescription }}</small>
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Audience</label>
                  <select
                    v-model="newItem.audience"
                    class="form-select"
                    :class="{ 'is-invalid': vuelidate$.newItem.audience.$dirty && vuelidate$.newItem.audience.$error }">
                    <option value="">-- Please select an option --</option>
                    <option v-for="option in audience" :key="option.value" :value="option.value">{{ option.text }}</option>
                  </select>
                  <Validation :validator="vuelidate$.newItem.audience" feedback="Audience is required." />
                </div>
              </div>

              <div v-if="heading === 'Dates'">
                <div class="row">
                  <div class="col">
                    <DateTime
                      ref="startDate"
                      label="Initial Start Date"
                      :description="isImportedEventDescription"
                      :disabled="isImportedEvent"
                      :value="newItem.startDate"
                      :min-date="currentMillis"
                      format="MM/dd/yyyy hh:mm a"
                      error-message="Initial Start Date and time must be in the future."
                      :submitted="vuelidate$.$dirty"
                      :is-invalid="vuelidate$.newItem.startDate.$dirty && vuelidate$.newItem.startDate.$error"
                      :date-required="true"
                      :time-required="true"
                      :time-requested="!newItem.allDay"
                      :start-time="nearestHour"
                      :clearable="false"
                      @update="updateStartDate" />
                  </div>
                  <div class="col">
                    <DateTime
                      ref="endDate"
                      label="Initial End Date"
                      :description="isImportedEventDescription"
                      :disabled="isImportedEvent"
                      :value="newItem.endDate"
                      :min-date="startDate"
                      format="MM/dd/yyyy hh:mm a"
                      error-message="Initial End Date must be after the Initial Start Date and current time."
                      :is-invalid="vuelidate$.newItem.endDate.$dirty && vuelidate$.newItem.endDate.$error"
                      :submitted="vuelidate$.$dirty"
                      :date-required="true"
                      :time-rquired="true"
                      :time-requested="!newItem.allDay"
                      :clearable="false"
                      @update="(val) => (newItem.endDate = val)" />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-check mb-3">
                      <input id="all-day-checkbox" v-model="newItem.allDay" class="form-check-input" type="checkbox" />
                      <label class="form-check-label" for="all-day-checkbox"> All Day Event </label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-check mb-3">
                      <input id="hide-end-checkbox" v-model="newItem.endDateHide" class="form-check-input" type="checkbox" />
                      <label class="form-check-label" for="hide-end-checkbox"> Hide End Date </label>
                    </div>
                  </div>
                </div>
                <div v-if="notificationOccurrences.length > 0" class="row">
                  <div class="col">
                    <div class="mb-3">
                      <div class="form-text">
                        Changing the dates or times will delete {{ notificationOccurrences.length }} scheduled notifications.
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="!isImportedEvent" class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label class="mb-2" for="recurrence" title="Recurring Event">Event Reccurrence</label>
                      <select v-model="recurrenceInfo.recurrence" class="form-select" :disabled="newItem.recurrenceId && !editRecurrence">
                        <option value="">-- Please select an option --</option>
                        <option v-for="option in recurrenceOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="recurrenceInfo.recurrence == 'daily'" id="recurrence-days" class="col">
                    <label class="mb-2" for="recurrence-days" title="Days that event will occur">Recurring Days</label>
                    <table>
                      <tbody>
                        <tr>
                          <td><label for="event-recur-0" class="modal-checkbox-label">M</label></td>
                          <td><label for="event-recur-1" class="modal-checkbox-label">Tu</label></td>
                          <td><label for="event-recur-2" class="modal-checkbox-label">W</label></td>
                          <td><label for="event-recur-3" class="modal-checkbox-label">Th</label></td>
                          <td><label for="event-recur-4" class="modal-checkbox-label">F</label></td>
                          <td><label for="event-recur-5" class="modal-checkbox-label">Sa</label></td>
                          <td><label for="event-recur-6" class="modal-checkbox-label">Su</label></td>
                        </tr>
                        <tr>
                          <td v-for="day in [0, 1, 2, 3, 4, 5, 6]" :key="day">
                            <div v-if="recurrenceInfo.validDays" class="form-check">
                              <input
                                :id="`event-recur-${day}`"
                                v-model="recurrenceInfo.validDays[day]"
                                class="form-check-input"
                                type="checkbox"
                                :name="`event-recur-${day}`"
                                :disabled="newItem.recurrenceId && !editRecurrence" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12">
                    <DateTime
                      v-if="recurrenceInfo.recurrence !== 'none'"
                      ref="recurrencePicker"
                      label="Last Day of Recurrence"
                      description="The last day for this event to occur. This day will be included if it is part of the recurrence."
                      format="MM/dd/yyyy"
                      :disabled="newItem.recurrenceId && newItem.recurrenceId != '' && !editRecurrence"
                      :value="recurrenceInfo.recurrenceEndDate || null"
                      :min-date="newItem.endDate"
                      :date-required="true"
                      :time-requested="false"
                      :clearable="false"
                      @timeInvalid="setTimeValid"
                      @update="updateRecurrenceEndDate" />
                  </div>
                </div>
              </div>

              <div v-if="heading === 'Location'">
                <div class="mb-3">
                  <label class="form-label">Location</label>
                  <small class="form-text text-muted">{{ isImportedEventDescription }}</small>
                  <select
                    v-model="newItem.resortLocationId"
                    class="form-select"
                    :class="{
                      'is-invalid':
                        vuelidate$.newItem.resortLocationId &&
                        vuelidate$.newItem.resortLocationId.$dirty &&
                        vuelidate$.newItem.resortLocationId.$error,
                    }"
                    :disabled="isImportedEvent">
                    <option :value="null" disabled>-- Please select an option --</option>
                    <option value="">Off-Site Location</option>
                    <option v-for="location in locations" :key="location.value" :value="location.value">{{ location.text }}</option>
                  </select>
                </div>
                <LocationSelector
                  v-if="newItem.resortLocationId == ''"
                  :data-object="newItem"
                  type="event"
                  :submitted="vuelidate$.$dirty"
                  :gps-required="false"
                  :prefill="dbContext == 'google-events-import'"
                  @locationInvalid="setLocationValid"
                  @updateLocation="updateLocation" />
                <Validation :validator="vuelidate$.newItem.resortLocationId" feedback="Location is required." />
                <Validation :validator="vuelidate$.newItem.location" feedback="Title for location is required." />
              </div>

              <div v-if="heading === 'Header Image(s)/Video'">
                <HeaderImages
                  ref="headerImages"
                  v-model="newItem.headerInfo"
                  :embedded="true"
                  :place-id="newItem.placeId"
                  :title="newItem.title"
                  :is-required="false" />
              </div>

              <div v-if="heading === 'Additional Features'">
                <div v-if="newItem.phoneNumber" class="mb-3">
                  <label class="form-label">Event Phone Number</label>
                  <input
                    v-model.trim="newItem.phone"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': phoneNumber && vuelidate$.phoneNumber.$dirty && vuelidate$.phoneNumber.$error }" />
                  <Validation :validator="vuelidate$.newItem.phone" map="phone" />
                </div>

                <div v-if="newItem.website" class="mb-3">
                  <label class="form-label">Event Website URL</label>
                  <input
                    v-model.trim="newItem.website"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': newItem.website && vuelidate$.newItem.website.$dirty && vuelidate$.newItem.website.$error }" />
                  <Validation :validator="vuelidate$.newItem.website" map="url" />
                </div>

                <div v-if="newItem.ticketing" class="mb-3">
                  <label class="form-label">Event Ticketing URL</label>
                  <input
                    v-model.trim="newItem.ticketing"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': newItem.ticketing && vuelidate$.newItem.ticketing.$dirty && vuelidate$.newItem.ticketing.$error }" />
                  <Validation :validator="vuelidate$.newItem.ticketing" map="url" />
                </div>

                <div class="row">
                  <div class="col">
                    <div class="form-check">
                      <input id="additional-cost" v-model="newItem.cost" class="form-check-input" type="checkbox" />
                      <label class="form-check-label" for="additional-cost">Additional Cost</label>
                    </div>
                  </div>
                </div>
                <div class="form-check" v-if="isRemote === false">
                  <input id="rsvp" v-model="newItem.rsvpRequest" class="form-check-input" type="checkbox" />
                  <label class="form-check-label" for="rsvp"> Request Guests to RSVP </label>
                </div>
                <div v-if="newItem.rsvpRequest && Object.keys(rsvpForms).length > 0">
                  <div class="mb-3">
                    <label class="form-label"> RSVP Form </label>
                    <select v-model="newItem.rsvpFormKey" class="form-select">
                      <option value="">-- Please select an option --</option>
                      <option v-for="rsvpForm in rsvpForms" :key="rsvpForm.value" :value="rsvpForm.value">{{ rsvpForm.text }}</option>
                    </select>
                  </div>
                </div>
                <div v-if="Object.keys(hunts).length > 0 && isRemote === false">
                  <div class="form-check">
                    <input id="hunt" v-model="hunt" class="form-check-input" type="checkbox" />
                    <label class="form-check-label" for="hunt"> Include Scavenger Hunt </label>
                  </div>
                  <div v-if="hunt" class="mb-3">
                    <label class="form-label">Scavenger Hunt</label>
                    <select v-model="newItem.huntKey" class="form-select">
                      <option value="">-- Please select an option --</option>
                      <option v-for="currentHunt in hunts" :key="currentHunt.value" :value="currentHunt.value">{{ currentHunt.text }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div v-if="heading === 'Additional Buttons'">
                <DetailButtonList :items="newItem.detailButtons" :isRemote="isRemote" />
              </div>
              <div v-if="heading === 'Associated Calendars'">
                Use this feature to add this event to multiple calendars
                <div class="mb-3">
                  <input v-model="sectionFilter" class="form-control m-2 user-filter" placeholder="Filter Sections" type="search" />
                </div>
                <div class="mb-3">
                  <div v-for="(option, index) in filteredContexts" :key="index" class="form-check ms-2">
                    <input :id="`option${index}`" v-model="newItem.contexts" class="form-check-input" type="checkbox" :value="option.value" />
                    <label class="form-check-label" :for="`option${index}`">{{ option.text }}</label>
                  </div>
                </div>
              </div>
              <div v-if="heading === 'QR Code'">
                <QRCode
                  ref="qrCode"
                  :title="`${newItem.title} QR Code`"
                  action="amcevent"
                  :action-key="params.itemId"
                  :action-params="[{ key: 'itemDetailPath', value: dbContext }]" />
              </div>
            </template>
          </b-accordion>

          <Validation :validator="vuelidate$.newItem" map="form" />

          <template v-if="dbContext === 'google-events-import'">
            <button class="btn m-1 btn-secondary" type="button" @click.stop="createEvent">Create Event</button>
            <button class="btn m-1 btn-secondary" type="button" @click.stop="denyEvent">Deny Event</button>
          </template>
          <template v-else>
            <button class="btn m-1 btn-primary" type="submit" :disabled="isArchived && $route.name !== 'detailDuplicate'">Submit</button>
          </template>
          <button class="btn m-1 btn-secondary" type="button" @click.stop="cancelUpdate">Cancel</button>
        </form>
      </div>
    </div>

    <AssistantsModal
      ref="assistants"
      title="Generate Event Ideas"
      name="event-coordinator"
      db-path="events"
      function="get_event_idea"
      :parameters="assistantParams"
      @accept="acceptAI"></AssistantsModal>
    <Overlay ref="events-overlay" :text="overlayText" />
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import editor from '../Editor';
import moment from 'moment-timezone';
import DetailButton from '../DetailButton';
import LocationSelector from '../LocationSelector';
import DateTime from '../DateTime2';
import HeaderImages from './HeaderImages';
import DetailButtonList from '../listitems/DetailButtonList';
import { required, numeric, url, minLength } from '@vuelidate/validators';
import QRCode from '../inputfields/QRCode';
import AssistantsModal from '../modals/AssistantsModal.vue';
import TextAIButton from '../inputfields/TextAIButton.vue';
import BAccordion from '../common/BAccordion.vue';
import Overlay from '@/components/common/Overlay.vue';
import md5 from 'md5';

export default {
  components: {
    editor,
    DetailButton,
    LocationSelector,
    // Attachment,
    HeaderImages,
    DateTime,
    DetailButtonList,
    QRCode,
    AssistantsModal,
    TextAIButton,
    BAccordion,
    Overlay,
  },
  data() {
    let newItem = {
      resortLocationId: null,
      audience: 'family',
      drawable: null,
      description: '',
      displayQR: false,
      type: '',
      endDate: null,
      startDate: null,
      detailButtons: [],
      allDay: false,
      endDateHide: false,
      contexts: [],
    };
    let recurrenceInfo = {
      recurrence: 'none',
      validDays: [false, false, false, false, false, false, false],
      recurrenceEndDate: null,
      eventKeys: {},
    };
    return {
      sectionFilter: null,
      filteredContexts: [],
      calendarContexts: [],
      originalContexts: [],
      editRecurrence: false,
      newItem,
      recurrenceInfo,
      origRecurrenceInfo: null,
      origEvent: {},
      recurrenceOptions: [
        { value: 'none', text: 'None' },
        { value: 'daily', text: 'Daily' },
        { value: 'weekly', text: 'Weekly' },
      ],
      audience: [],
      locations: [],
      hunts: [],
      rsvpForms: [],
      rewards: {},
      startDate: null,
      skipUpdateDate: false,
      hunt: false,
      dbRef: '',
      dbContext: 'events-resort',
      phoneNumber: null,
      locationInvalid: false,
      timeInvalid: false,
      recurrenceEndDateDisplay: false,
      detailAction: '',
      locationsObject: {},
      currentMillis: Date.now(),
      existingEvents: [],
      importedEventKey: null,
      notificationOccurrences: [],
      assistantParams: {},
      params: {},
      nearestHour: null,
      overlayText: 'Updating recurrences',
      duplicateDistanceMillis: 1000 * 60 * 60,
      isArchived: this.$route?.params?.type?.includes('Archived'),
      importCalendar: null,
      cLocationKey: '',
      isRemote: false,
      remoteInfo: {},
    };
  },
  validations() {
    const newItem = {
      title: {
        required,
      },
      startDate: {
        required,
        numeric,
      },
      endDate: {
        required,
        numeric,
        afterStartDate: function (value) {
          if (this.newItem.startDate && value) return value > this.newItem.startDate;
          return true;
        },
        afterNow: function (value) {
          if (value) return value > moment.tz(this.getTimeZone).valueOf();
          return true;
        },
      },
      audience: {
        required,
      },
      ticketing: {
        url,
      },
      website: {
        url,
      },
    };
    if (this.newItem.resortLocationId == '') {
      newItem.location = { required };
    } else {
      newItem.resortLocationId = { required };
    }
    return {
      newItem,
      phoneNumber: {
        numeric,
        minLength: minLength(10),
      },
    };
  },
  computed: {
    getContainerStyle: function () {
      return this.recurrenceEndDateDisplay ? 'top: inherit' : '';
    },
    isImportedEvent: function () {
      return this.newItem.id && this.newItem.calendarId !== 'google-events-import' && this.dbContext !== 'google-events-import' ? true : false;
    },
    isImportedEventDescription: function () {
      return this.isImportedEvent ? 'This event is imported so the field is disabled.' : '';
    },
    displayMap: function () {
      if (this.newItem.address != null || this.newItem.city != null || (this.newItem.latitude != null && this.newItem.longitude != null)) {
        return true;
      }
      if (
        this.newItem.resortLocationId &&
        this.locationsObject[this.newItem.resortLocationId] &&
        (this.locationsObject[this.newItem.resortLocationId].searchableName || this.locationsObject[this.newItem.resortLocationId].actionKey)
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    'newItem.phone': function () {
      this.phoneNumber = this.newItem.phone.replace(/\D+/g, '');
    },
    hunt: function () {
      if (!this.hunt) {
        this.$delete(this.newItem, 'huntKey');
      }
    },
    'newItem.endDate': function () {
      this.newItem.endDateDuration = this.newItem.endDate - this.newItem.startDate;
    },
    sectionFilter: function () {
      this.filteredContexts = !this.sectionFilter
        ? this.calendarContexts
        : this.calendarContexts.filter((context) => {
            const item = context.text;
            const ftr = this.sectionFilter.toLowerCase();
            return item.toLowerCase().includes(ftr);
          });
    },
  },
  async created() {
    this.cLocationKey = this.getCampgroundKey;
    //log newitem startdate to console
    this.params = { ...this.$route.params };
    this.isRemote = ['remoteEvent', 'remoteEventEdit'].includes(this.$route.name);
    this.remoteInfo = this.$store.getters.remoteInfo;
    this.dbContext = this.$route.params.dbContext;
    this.newItem.contexts = [this.getCalendarPath(this.cLocationKey, this.dbContext)];
    this.detailAction = this.$route.params.detailAction;
    const res = await this.getLocationRef().child('locations').once('value');
    this.locationsObject = res.val() || {};
    this.locations = this.getSelectKeyTitlePair(this.locationsObject, true);
    this.getFBData('audience');
    this.getFBData('hunts', true);
    this.getFBData('rewards/config');
    await this.getFBData('rsvpForms', true).then(() => {
      this.rsvpForms = this.rsvpForms || [];
      const hasStandardForm = this.rsvpForms.some((form) => form.standard);
      if (hasStandardForm) return;
      this.rsvpForms.push({ text: 'Standard RSVP Form', value: 'rsvpForm' });
    });

    this.getMenuItem(this.$route.params.itemId);
    this.getNotificationOccurrences();
    await this.getCalendarImport();

    if (!this.isRemote) await this.createAssistantParams();

    this.nearestHour = this.getNearestHourFromNow();
  },
  mounted() {
    //Fix for bootstrap not automatically starting carousel.
    setTimeout(() => {
      if (this.newItem.headerInfo && this.newItem.headerInfo.images && this.newItem.headerInfo.images.length > 1) {
        const prev = document.getElementById('carousel-control-prev');
        const next = document.getElementById('carousel-control-next');
        next?.click();
        prev?.click();
      }
    }, 5000);
  },
  methods: {
    getOriginalContext() {
      return this.dbContext.replace('-archive', '');
    },
    getCalendars: async function () {
      const isESP = (await this.getAppData('appType')) === 'esp';
      console.log('TCL: isESP', isESP);
      let cLocations = {};
      if (isESP) {
        cLocations = (await this.getAllCampgroundLocationsRef().once('value')).val();
      } else {
        cLocations[this.cLocationKey] = await this.getCampgroundLocationData(this.cLocationKey);
      }
      const promises = [];
      const cLocationKeys = Object.keys(cLocations);
      cLocationKeys.forEach((cLocationKey) => {
        const cLocationData = cLocations[cLocationKey];
        if (cLocationData) {
          const cLocationTitle = cLocationKeys.length > 1 ? `${cLocationData.title} - ` : '';
          promises.push(
            this.getContextRef('amc-events/config', cLocationKey)
              .once('value')
              .then((snapshot) => {
                const calendarConfigObject = snapshot.val() || {};
                const locationCalendarContexts = Object.keys(calendarConfigObject).reduce((accumulator, calendarKey) => {
                  return [
                    ...accumulator,
                    { value: this.getCalendarPath(cLocationKey, calendarKey), text: `${cLocationTitle}${calendarConfigObject[calendarKey].title}` },
                  ];
                }, []);
                this.calendarContexts = [...this.calendarContexts, ...locationCalendarContexts];
              })
          );
        }
      });
      await Promise.all(promises).then(() => {
        const missingContexts = !this.newItem.contexts
          ? []
          : this.newItem.contexts
              .filter((x) => this.calendarContexts.filter((y) => y.value === x).length == 0)
              .map((missingContext) => {
                const missingLocationId = missingContext.substr(0, missingContext.indexOf('/'));
                const title = cLocations[missingLocationId]?.title || missingLocationId;
                return {
                  value: missingContext,
                  text: `Main or Sub Menu Item(${title})`,
                };
              });
        this.calendarContexts = [...missingContexts, ...this.calendarContexts];
        this.filteredContexts = this.calendarContexts;
      });
      if (this.isRemote) {
        const remoteInfo = this.$store.getters.remoteInfo;
        let accessibleCalendars = [];

        if (remoteInfo.accessType === 'email') {
          const remoteHTTPS = firebase.functions().httpsCallable('remoteUpdate');
          const data = (await remoteHTTPS({ action: 'getRemoteInfo', type: 'uid', uuid: firebase.auth().currentUser?.uid })).data;
          //Get separated list of accessible calendars.
          for (let key in data) {
            if (data[key].type === 'calendar') {
              accessibleCalendars.push(`${data[key].origContext}/amc-events/calendars/${data[key].contexts[0]}`);
            }
          }
        } else if (remoteInfo.accessType === 'url') {
          accessibleCalendars.push(`${remoteInfo.origContext}/amc-events/calendars/${remoteInfo.contexts[0]}`);
        }

        //Only add authorized contexts/calendars.
        this.filteredContexts = [];
        for (let i in this.calendarContexts) {
          if (accessibleCalendars.includes(this.calendarContexts[i].value)) this.filteredContexts.push(this.calendarContexts[i]);
        }
      }
    },
    getHeaderStyles: function () {
      let image = this.getImage();
      if (image) {
        return `height:261px;background-image:url("${image}");`;
      }
      return `${this.getBackgroundColorStyle}height:50px;`;
    },
    setShowing: function (type) {
      this[type] = true;
    },
    updateStartDate: async function (newDate) {
      if (this.skipUpdateDate === false) {
        this.newItem.startDate = newDate;
        if ((this.newItem.startDate && !this.newItem.endDate) || this.newItem.startDate >= this.newItem.endDate - this.duplicateDistanceMillis) {
          console.log('end date changing');
          this.newItem.endDateDuration = this.duplicateDistanceMillis;
          this.newItem.endDate = this.newItem.startDate + this.newItem.endDateDuration;
        } else {
          this.newItem.endDateDuration = this.newItem.endDate - this.newItem.startDate;
        }
      }
    },
    updateRecurrenceEndDate: function (newDate) {
      this.recurrenceInfo.recurrenceEndDate = moment.tz(newDate, this.getTimeZone).endOf('day').unix() * 1000;
    },
    getEventDate: function () {
      const formattedStartDate = this.getFormattedDay(this.newItem.startDate);
      const formattedEndDate = this.getFormattedDay(this.newItem.endDate);
      if (formattedStartDate == formattedEndDate || !formattedEndDate) {
        return formattedStartDate;
      }
      if (this.newItem.endDateHide) {
        return `${formattedStartDate}`;
      }
      return `${this.getFormattedDay(this.newItem.startDate)} - ${this.getFormattedDay(this.newItem.endDate)}`;
    },
    getLocation: function () {
      return this.newItem.resortLocationId && this.locationsObject[this.newItem.resortLocationId]
        ? this.locationsObject[this.newItem.resortLocationId].title
        : this.newItem.location;
    },
    getImage: function () {
      if (this.newItem.drawable || this.newItem.headerInfo?.images?.[0]?.imageURL) {
        return this.newItem.headerInfo?.images?.[0]?.imageURL || this.newItem.drawable;
      }
      if (this.newItem.resortLocationId) {
        const location = this.locationsObject[this.newItem.resortLocationId];
        if (location) {
          return location.drawable;
        }
      }
    },
    updateHeaderImage: function (imageURL) {
      this.newItem.drawable = imageURL;
    },
    getMenuItem: async function (itemId) {
      this.newItem.type = ['events-resort', 'events-local'].includes(this.dbContext) ? this.dbContext.split('-')[1] : this.dbContext;

      if (itemId) {
        let dbRef = this.getContextRef(`amc-events/calendars/${this.dbContext}/events`, null, this.isArchived).child(itemId);

        const res = await dbRef.once('value');
        this.newItem = { ...this.newItem, ...res.val() };
        if (this.newItem.allDay) {
          this.newItem.endDate -= 1;
        }

        this.duplicateDistanceMillis = this.newItem.endDateDuration || 1000 * 60 * 60;

        if (this.newItem.huntKey) {
          this.hunt = true;
        }
        if (this.$route.name == 'detailDuplicate') {
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.$refs.startDate.clear();
              this.$refs.endDate.clear();
              this.skipUpdateDate = false;
            });
          });

          this.$delete(this.newItem, 'endDateDuration');
          this.$delete(this.newItem, 'recurrenceId');
          this.$delete(this.newItem, 'id');
          this.params.itemId = null;
        }
        if (this.newItem.recurrenceId) {
          const recurrenceInfo = await this.getRecurrenceDataFromFB();
          if (recurrenceInfo) {
            this.recurrenceInfo = recurrenceInfo;
            this.origRecurrenceInfo = { ...this.recurrenceInfo };
            this.origEvent = { ...this.newItem };
            if (!this.isArchived) {
              this.openRecurrenceDialog();
            }
          } else {
            this.newItem.recurrenceId = null;
          }
        }
        this.originalContexts = this.newItem.contexts ? [...this.newItem.contexts] : [`${this.getCalendarPath(this.cLocationKey, this.dbContext)}`];
      }

      if (this.dbContext === 'google-events-import') {
        const locationId = Object.keys(this.locationsObject)
          .filter((x) => this.newItem.location && this.locationsObject[x].title.toLowerCase() === this.newItem.location.toLowerCase())
          .reduce((result, x) => {
            return result || x;
          }, null);
        if (locationId) {
          this.newItem.resortLocationId = locationId;
        }
        const eventContext = ['resort', 'local'].includes(this.newItem.type) ? `events-${this.newItem.type}` : this.newItem.type;
        const fullDateTime = moment.tz(this.newItem.startDate, this.getTimeZone);
        const startTime = fullDateTime.startOf('day').unix() * 1000;
        const endTime = fullDateTime.endOf('day').unix() * 1000;
        const dbRef = this.getContextRef(eventContext).orderByKey().startAt(startTime.valueOf().toString()).endAt(endTime.valueOf().toString());
        dbRef.once('value').then((snapshot) => {
          const eventsObj = snapshot.val();
          if (eventsObj !== null)
            this.existingEvents = Object.keys(eventsObj).map((eventKey) => {
              return { title: eventsObj[eventKey].title, key: eventKey, context: eventContext };
            });
        });
      }
      this.getCalendars();
    },
    openRecurrenceDialog: function () {
      this.$message.create({
        title: 'Edit Recurrence',
        body: 'This event is part of a recurrence. Do you want to edit only this event or all events in this recurrence?',
        buttons: [
          this.$message.button('Edit Recurrence', 'btn-secondary', () => {
            this.editRecurrence = true;
            // Get the eventKey for the first event in the recurrence that starts after right now
            const futureStartDates = Object.keys(this.recurrenceInfo.eventKeys).filter(
              (key) => this.recurrenceInfo.eventKeys[key].startDate >= this.currentMillis
            );
            //Sort by start Date
            const futureStartDatesSorted = futureStartDates.sort(
              (a, b) => this.recurrenceInfo.eventKeys[a].startDate - this.recurrenceInfo.eventKeys[b].startDate
            );
            const startDate = this.recurrenceInfo.eventKeys[futureStartDatesSorted[0]].startDate;
            this.newItem = {
              ...this.newItem,
              ...this.recurrenceInfo.originalItem,
              ...{
                startDate: Number(startDate),
                endDate: Number(startDate + this.recurrenceInfo.originalItem.endDateDuration),
              },
            };
            if (this.newItem.allDay) {
              this.newItem.endDate -= 1;
            }
          }),
          this.$message.button('Edit Event', 'btn-accent', () => {
            this.editRecurrence = false;
          }),
        ],
      });
    },
    getFBData: async function (type, sortTitleAlpha) {
      const res =
        type === 'rsvpForms'
          ? (await this.getLocationRef().child('custom-forms').orderByChild('type').equalTo('rsvp').once('value')).val()
          : (await this.getLocationRef().child(type).once('value')).val();
      if (type == 'rewards/config') {
        this['rewards'] = res;
        return;
      }

      this[type] = this.getSelectKeyTitlePair(res || {}, true, sortTitleAlpha);
      if (type === 'rsvpForms') {
        this[type] = this[type].map((form) => {
          form.standard = res[form.value].standard;
          return form;
        });
      }
      if (type === 'audience') {
        this.audience.forEach((audienceItem) => {
          audienceItem.value = audienceItem.text.toLowerCase();
        });
      }
    },
    openURL: function (url) {
      window.open(url, '_blank');
    },

    openRSVP: function () {
      console.log('TCL: itemId', itemId);
      console.log('TCL: this.newItem.rsvpFormKey', this.newItem.rsvpFormKey);
      const itemId = this.$route?.params?.itemId || '';
      if (this.newItem.rsvpFormKey && this.newItem.rsvpFormKey !== 'rsvpForm' && itemId !== '') {
        this.$router.push({
          name: 'scrollerEntries',
          params: { context: 'custom-forms', action: 'view', itemId: this.newItem.rsvpFormKey },
          query: { eventId: this.$route.params.itemId },
        });
      }
    },
    updateLocation: function (locationData) {
      this.$delete(locationData, 'website');
      this.$delete(locationData, 'phone');
      this.$delete(locationData, 'hours');
      this.$delete(locationData, 'distance');
      this.$delete(locationData, 'title');
      this.newItem = { ...this.newItem, ...locationData };
    },
    setLocationValid: function (isInvalid) {
      this.locationInvalid = isInvalid;
    },
    setTimeValid: function (isInvalid) {
      this.timeInvalid = isInvalid;
    },
    isUpdateForSingleEventInRecurrence: function () {
      return !this.editRecurrence && this.recurrenceInfo.originalItem;
    },
    removeNull: function (obj) {
      if (obj instanceof Array) {
        obj = obj.map((item) => {
          return this.removeNull(item);
        });
      } else {
        Object.keys(obj).forEach((key) => {
          if (obj[key] && typeof obj[key] === 'object') this.removeNull(obj[key]);
          if (obj[key] === null) delete obj[key];
        });
      }
    },
    //Recurrence functions
    getUpdatesForSingleEventInRecurrence: function () {
      const firebaseUpdates = {};
      firebaseUpdates[this.getEventPath(this.dbContext, this.params.itemId)] = this.newItem;
      const modifiedItems = {};
      Object.keys(this.newItem).forEach((key) => {
        if (key === 'startDate') {
          if (this.newItem[key] !== this.origEvent.startDate) {
            modifiedItems[key] = this.newItem[key];
          }
        } else if (this.newItem[key] && typeof this.newItem[key] === 'object') {
          //remove all null values from object javascript recursive
          let cleanedObject = this.newItem[key] instanceof Array ? [...this.newItem[key]] : { ...this.newItem[key] };
          this.removeNull(cleanedObject);
          cleanedObject = cleanedObject.length === 0 ? {} : cleanedObject;
          if (md5(JSON.stringify(cleanedObject)) !== md5(JSON.stringify(this.recurrenceInfo.originalItem[key] || {}))) {
            modifiedItems[key] = this.newItem[key];
          }
        } else if (this.newItem[key] !== this.recurrenceInfo.originalItem[key]) {
          modifiedItems[key] = this.newItem[key];
        }
      });
      if (this.newItem.endDateDuration == this.recurrenceInfo.originalItem.endDateDuration && !modifiedItems.startDate) {
        modifiedItems.endDate = null;
      }
      firebaseUpdates[`${this.getRecurrencePath(this.cLocationKey, this.newItem.recurrenceId)}/editedEvents/${this.params.itemId}`] = modifiedItems;
      if (this.newItem.startDate !== this.origEvent.startDate) {
        firebaseUpdates[`${this.getRecurrencePath(this.cLocationKey, this.newItem.recurrenceId)}/eventKeys/${this.params.itemId}`] = {
          startDate: this.newItem.startDate,
        };
      }
      return firebaseUpdates;
    },
    getNextStartDate: function (lastStartDate, recurrenceInfo) {
      if (recurrenceInfo.recurrence == 'weekly') {
        return moment(lastStartDate).add(7, 'days').valueOf();
      }
      let tempStartDate = lastStartDate;
      do {
        tempStartDate = moment(tempStartDate).add(1, 'days').valueOf();
        const startDateIndex = moment.tz(tempStartDate, this.getTimeZone).isoWeekday() - 1;
        if (recurrenceInfo.validDays[startDateIndex]) {
          return tempStartDate;
        }
      } while (tempStartDate <= recurrenceInfo.recurrenceEndDate);
      return recurrenceInfo.recurrenceEndDate + 1;
    },
    getRecurrenceDates: function () {
      const startDates = [];
      let nextStartDate = this.newItem.startDate;
      do {
        startDates.push(nextStartDate);
        nextStartDate = this.getNextStartDate(nextStartDate, this.recurrenceInfo);
      } while (nextStartDate <= this.recurrenceInfo.recurrenceEndDate);
      return startDates;
    },
    getUpdatesForModifiedRecurringData: async function (startDates) {
      const newStartDateObject = startDates.reduce((accumulator, startDate) => {
        const dateString = new Date(startDate).toDateString();
        accumulator[dateString] = startDate;
        return accumulator;
      }, {});
      const newStartDates = [...startDates];
      console.log('TCL: newStartDates', newStartDates);
      this.recurrenceInfo.editedEvents = this.recurrenceInfo.editedEvents || {};
      const allLiveRecurreningEvents =
        (
          await this.getContextRef(`amc-events/calendars/${this.dbContext}/events`)
            .orderByChild('recurrenceId')
            .equalTo(this.newItem.recurrenceId)
            .once('value')
        ).val() || {};

      const firebaseEntries = Object.keys(allLiveRecurreningEvents).reduce((accumulator, eventKey) => {
        const currentEvent = allLiveRecurreningEvents[eventKey];
        const currentDateString = new Date(currentEvent.startDate).toDateString();
        const tempStartDate = newStartDateObject[currentDateString];
        if (!tempStartDate) {
          accumulator[this.getEventPath(this.dbContext, eventKey)] = null;
          this.recurrenceInfo.eventKeys[eventKey] = null;
          this.recurrenceInfo.editedEvents[eventKey] = null;
          return accumulator;
        }
        const editedEvent = this.recurrenceInfo?.editedEvents?.[eventKey] || {};
        const updatedEvent = {
          ...editedEvent,
          ...{ startDate: tempStartDate, endDate: tempStartDate + this.newItem.endDateDuration },
        };
        if (editedEvent.endDateDuration) {
          updatedEvent.endDate = tempStartDate + editedEvent.endDateDuration;
        }
        accumulator[this.getEventPath(this.dbContext, eventKey)] = { ...this.newItem, ...updatedEvent };
        this.recurrenceInfo.eventKeys[eventKey] = { startDate: tempStartDate };
        delete newStartDateObject[currentDateString];
        return accumulator;
      }, {});

      this.recurrenceInfo.originalItem = this.newItem;
      return { firebaseEntries, newStartDates: Object.values(newStartDateObject) };
    },

    //New Event Functions
    async updateSingleEvent(firebaseUpdates) {
      //Get key from params for editing; otherwise, create new key.
      const eventKey = this.params.itemId || this.getContextRef().push().key;
      firebaseUpdates[this.getEventPath(this.dbContext, eventKey)] = this.newItem;
    },

    submitUpdate: async function () {
      if (this.newItem.endDate - this.newItem.startDate > 86400000 && this.recurrenceInfo.recurrence !== 'none') {
        this.$message.create({
          title: 'Error',
          body: 'Recurring events cannot be longer than 24 hours.',
          buttons: [this.$message.button('OK')],
        });
        return;
      }
      this.vuelidate$.$touch();
      this.$nextTick(async () => {
        if (!this.vuelidate$.$invalid && !this.locationInvalid && !this.timeInvalid && (await this.$refs.headerImages.isValid())) {
          //await this.$refs.useImage.croppieUseImage();
          if (this.newItem.rsvpRequest && (!this.newItem.rsvpFormKey || this.newItem.rsvpFormKey === 'rsvpForm')) {
            // get rsvp form rsvpForm from formTemplates.json in the file system and add it to custom forms node
            const rsvpForm = require('@/json/formTemplates.json').rsvpStandard;
            const rsvpFormKey = this.getContextRef('custom-forms').push().key;
            this.newItem.rsvpFormKey = rsvpFormKey;
            this.getContextRef('custom-forms').child(rsvpFormKey).set(rsvpForm);
          }
          if (this.newItem.headerInfo?.images?.[0]?.imageURL) this.newItem.drawable = this.newItem.headerInfo.images[0].imageURL;
          else if (!this.newItem.headerInfo?.images || this.newItem.headerInfo.images.length === 0) this.newItem.drawable = null;
          this.dbContext = this.dbContext.replace('-archive', '');
          this.newItem.audience_type = `${this.newItem.type}_${this.newItem.audience}`;

          const userId = firebase.auth().currentUser?.uid || this.$route.params.uuid || this.remoteInfo.uuid;
          if (this.newItem.creator) {
            this.newItem.updatedBy = userId;
          } else {
            this.newItem.creator = userId;
          }

          if (this.newItem.allDay) {
            this.newItem.endDateDuration = 86400000;
            this.newItem.startDate = moment(this.newItem.startDate).startOf('day').valueOf();
            this.newItem.endDate = this.newItem.startDate + this.newItem.endDateDuration;
          }

          if (this.newItem.latitude) {
            this.newItem.latitude = Number(this.newItem.latitude);
            this.newItem.latitude = this.newItem.latitude !== 0 ? this.newItem.latitude : null;
          }
          if (this.newItem.longitude) {
            this.newItem.longitude = Number(this.newItem.longitude);
            this.newItem.longitude = this.newItem.longitude !== 0 ? this.newItem.longitude : null;
          }

          /* Cases:
            ✅1. Single New Event
            ✅2. Edit Single Existing Event
            ✅3. Single Event Update in Recurrence
            ✅4. Recurrening event moved to Single Event
            ✅5. Recurring Event New
            ✅6. Single Event moved to Recurring Event
            ✅7. Modify event other than time
            ✅8. Modify start time
            ✅9. Modify end time
            ✅10. Modify recurrence Information
          */
          let firebaseUpdates = {};
          this.$refs['events-overlay'].show();
          if (this.recurrenceInfo.recurrence == 'none') {
            // Cases 1, 2, 4
            if (this.newItem.recurrenceId) {
              // Remove recurrence data: Query for all events in recurrence and set the path null in firebaseUpdates, delete reccurenceInfo
              firebaseUpdates = Object.keys(this.recurrenceInfo.eventKeys).reduce((accumulator, eventKey) => {
                if (this.recurrenceInfo.eventKeys[eventKey].startDate > Date.now()) {
                  accumulator[this.getEventPath(this.dbContext, eventKey)] = null;
                }
                return accumulator;
              }, firebaseUpdates);
              firebaseUpdates[this.getRecurrencePath(this.cLocationKey, this.newItem.recurrenceId)] = {};
              this.newItem.recurrenceId = null;
            }
            this.updateSingleEvent(firebaseUpdates);
          } else if (this.isUpdateForSingleEventInRecurrence()) {
            // Case 3
            firebaseUpdates = this.getUpdatesForSingleEventInRecurrence();
            console.log('TCL: firebaseUpdates 1', JSON.parse(JSON.stringify(firebaseUpdates)));
          } else {
            this.newItem.recurrenceId = this.newItem.recurrenceId || this.getContextRef().push().key;
            const startDates = this.getRecurrenceDates();
            const { newStartDates, firebaseEntries } = await this.getUpdatesForModifiedRecurringData(startDates);
            // Case 7, 8, 9, 10
            firebaseUpdates = firebaseEntries;
            firebaseUpdates = newStartDates.reduce((accumulator, startDate) => {
              const pushKey = startDate === this.newItem.startDate && this.params.itemId ? this.params.itemId : this.getContextRef().push().key;
              accumulator[this.getEventPath(this.dbContext, pushKey)] = {
                ...this.newItem,
                ...{ startDate: startDate, endDate: startDate + this.newItem.endDateDuration },
              };
              this.recurrenceInfo.eventKeys[pushKey] = { startDate };
              return accumulator;
            }, firebaseUpdates);
            firebaseUpdates[this.getRecurrencePath(this.cLocationKey, this.newItem.recurrenceId)] = this.recurrenceInfo;
          }
          this.$refs['events-overlay'].hide();
          console.log('TCL: firebaseUpdates 2', JSON.parse(JSON.stringify(firebaseUpdates)));
          firebaseUpdates = this.getFirebaseUpdatesForContexts(firebaseUpdates);
          console.log('TCL: firebaseUpdates 3', JSON.parse(JSON.stringify(firebaseUpdates)));

          if (!this.isRemote) firebase.database().ref('resort-navigator').child('location-data').update(firebaseUpdates);
          else {
            const remoteHTTPS = firebase.functions().httpsCallable('remoteUpdate');
            await remoteHTTPS({ action: 'updateEvents', updates: firebaseUpdates });
          }

          if (this.importedEventKey !== null) {
            this.getContextRef('google-events-import').child(this.importedEventKey).remove();
          }

          if (this.notificationOccurrences.length > 0) {
            if (this.isTimeChanged()) this.checkTimeModal();
            else if (this.isTitleChanged() || this.isDescriptionChanged()) this.checkTitleModal();
            else this.exitEdit();
          } else {
            this.exitEdit();
          }

          const dbRef = this.getLocationRef(this.campgroundKey).child('amc-events').child('config').child(this.dbContext).child('icsBuildTime');
          dbRef.set(Date.now());
        }
      });
    },
    getFirebaseUpdatesForContexts: function (firebaseUpdates) {
      const contextsToRemove = this.originalContexts.filter((x) => !this.newItem.contexts.includes(x));
      const sectionsInContext = this.newItem.contexts.map((context) => context.split('/')[0]);
      console.log('TCL: sectionsInContext', sectionsInContext);
      const sectionsToRemove = this.originalContexts.filter((x) => !sectionsInContext.includes(x.split('/')[0]));

      console.log('TCL: firebaseUpdates', firebaseUpdates);
      // if (this.newItem.contexts.length < 2 && contextsToRemove.length === 0) return firebaseUpdates;
      return Object.keys(firebaseUpdates).reduce((accumulator, key) => {
        console.log('TCL: key', key);
        const contextKey = /*firebaseUpdates[key]?.id || */ key.split('/').pop();
        console.log('TCL: contextKey', contextKey);
        if (!key.includes('recurrenceInfo')) {
          this.newItem.contexts.forEach((context) => {
            accumulator[`${context}/events/${contextKey}`] = contextsToRemove.includes(context) ? null : firebaseUpdates[key];
            console.log("TCL: contextsToRemove.includes('context')", contextsToRemove.includes(context));
          });
          contextsToRemove.forEach((context) => {
            accumulator[`${context}/events/${contextKey}`] = null;
          });
        } else {
          accumulator[key].sections = sectionsInContext;
          /* const recurrenceInfo = { ...firebaseUpdates[key], ...{ sections: sectionsInContext } };
          console.log('TCL: recurrenceInfo', recurrenceInfo);
          if (sectionsToRemove.includes(contextKey)) {
            accumulator[key] = null;
          }
          sectionsInContext.forEach((section) => {
            accumulator[this.getRecurrencePath(section, this.newItem.recurrenceId)] = recurrenceInfo;
          });
          */
        }
        return accumulator;
      }, firebaseUpdates);
    },
    getRecurrenceDataFromFB: async function () {
      return (await this.getContextRef(`amc-events/recurrenceInfo`).child(this.newItem.recurrenceId).once('value')).val();
    },
    deleteRecurrenceData: async function () {
      const removalPaths = {};
      removalPaths[this.getRecurrencePath(this.cLocationKey, this.newItem.recurrenceId)] = null;
      const eventKeys = (await this.getRecurrenceDataFromFB()).eventKeys;
      return Object.keys(eventKeys).reduce((removalPaths, eventKey) => {
        removalPaths[this.getEventPath(this.dbContext, eventKey)] = null;
        return removalPaths;
      }, removalPaths);
    },
    getCalendarPath: function (context, key) {
      return `${context}/amc-events/calendars/${key}`;
    },
    getEventPath: function (context, key) {
      return `${this.getCalendarPath(this.cLocationKey, context)}/events/${key}`;
    },
    getRecurrencePath: function (cLocationKey, key) {
      return `${cLocationKey}/amc-events/recurrenceInfo/${key}`;
    },
    cancelUpdate: function () {
      this.exitEdit();
    },
    createEvent: async function () {
      this.dbContext = ['resort', 'local'].includes(this.newItem.type) ? `events-${this.newItem.type}` : this.newItem.type;
      this.importedEventKey = this.startDate;
      this.getContextRef('google-events-import').child(this.startDate).remove();
      this.submitUpdate();
    },
    denyEvent: function () {
      this.getContextRef('google-events-denied').child(this.startDate).set({ id: this.newItem.id });
      this.getContextRef().child(this.startDate).remove();
      this.exitEdit();
    },
    exitEdit: function () {
      this.$router.go(-1);
    },
    getNotificationOccurrences: async function () {
      if (this.params.itemId) {
        try {
          const databaseRef = firebase.database().ref('resort-navigator').child('location-data').child('system-data'); // resort-navigator/location-data/system-data/
          await databaseRef
            .child('messages-scheduled')
            .orderByChild('eventId')
            .equalTo(this.params.itemId)
            .once('value')
            .then((snapshot) => {
              snapshot.forEach((childSnapshot) => {
                const id = childSnapshot.key;
                this.notificationOccurrences.push({ snapshot: childSnapshot, id: id });
              });
            });
          console.log('Notification occurences.', this.notificationOccurrences);
        } catch (err) {
          console.log(err);
        }
      }
    },
    deleteNotifications: async function () {
      try {
        this.notificationOccurrences.forEach((notification) => {
          console.log(notification);
          notification.snapshot.ref.remove();
        });
      } catch (err) {
        console.log(err);
      }
      this.notificationOccurrences = [];
    },
    updateNotifications: async function () {
      try {
        const databaseRef = firebase.database().ref('resort-navigator').child('location-data').child('system-data').child('messages-scheduled');
        // resort-navigator/location-data/system-data/
        await this.notificationOccurrences.forEach((notification) => {
          console.info('notification object', notification.id);
          //Find the notification in the database and update the title and description.
          databaseRef
            .child(notification.id)
            .update({
              //If this title or description format/template are changed, update them in EventItems as well (createNotification).
              title: `Reminder: ${this.newItem.title}${this.getLocation(this.newItem) ? ' @' + this.getLocation(this.newItem) : ''}`,
              description: this.newItem.description?.replace(/<[^>]+>/g, '').replace(/&[^\s]*;/g, ''),
            })
            .then(() => {
              console.log(notification.id, 'notification updated with new title/description.');
            });
        });
      } catch (err) {
        console.log(err);
      }
    },
    checkTimeModal: function () {
      if (this.isTimeChanged()) {
        this.$message.create({
          title: 'Notifications Removed',
          body: `${this.notificationOccurrences.length} matching event notifications have been removed.`,
          buttons: [
            this.$message.button('OK', 'btn-accent', () => {
              this.deleteNotifications();
              this.exitEdit();
            }),
          ],
        });
      }
    },
    checkTitleModal: function () {
      this.$message.create({
        title: 'Update Notifications',
        body: "Do you want to update all notifications' titles and descriptions for this event?",
        buttons: [
          this.$message.button('No', 'btn-secondary'),
          this.$message.button('Yes', 'btn-accent', () => {
            this.updateNotifications();
            this.exitEdit();
          }),
        ],
      });
    },
    isTimeChanged: function () {
      const isNewStart = this.notificationOccurrences[0]?.snapshot?.val().effectiveTime !== this.$refs.startDate.timestamp;
      const isNewEnd = this.notificationOccurrences[0]?.snapshot?.val().endTime !== this.newItem.endDate;
      console.log(this.notificationOccurrences[0]?.snapshot?.val().effectiveTime, this.$refs.startDate.timestamp);
      console.log(this.notificationOccurrences[0]?.snapshot?.val().endTime, this.newItem.endDate);
      console.log(isNewStart, isNewEnd);
      return isNewStart || isNewEnd;
    },
    isTitleDescChanged: function () {
      return isTitleChanged() || isDescriptionChanged();
    },
    isTitleChanged: function () {
      const tempTitle = this.notificationOccurrences[0]?.snapshot
        ?.val()
        .title.replace(/Reminder: /g, '')
        .split(' @')[0];
      console.log('New title', this.notificationOccurrences[0]?.snapshot?.val().title, 'Old title', tempTitle);
      return tempTitle !== this.newItem.title;
    },
    isDescriptionChanged: function () {
      const tempDesc = this.newItem.description?.replace(/<[^>]+>/g, '').replace(/&[^\s]*;/g, '');
      console.log('New desc', this.notificationOccurrences[0]?.snapshot?.val().description, 'Old desc', tempDesc);
      return this.notificationOccurrences[0]?.snapshot?.val().description !== tempDesc;
    },

    async createAssistantParams() {
      if (!this.assistantParams.appType) {
        this.assistantParams.appType = await this.getAppType();
        if (this.assistantParams.appType === 'resort') {
          this.$refs.assistants.showAppTypeSelect = true;
        }
      }
      if (!this.assistantParams.location) {
        const location = await this.getCityState();
        this.assistantParams.location = `${location.city}, ${location.state}`;
      }
      console.log(this.assistantParams);
    },
    setDataToAI() {
      const obj = {
        title: this.newItem.title ? this.newItem.title : '',
        description: this.newItem.description ? this.newItem.description.replace(/<[^>]*>/g, '') : '',
      };
      this.$refs.assistants.createDataFromItem(obj.title, obj);
      this.$refs.assistants.showModal(true);
    },
    async openAssistants() {
      this.$refs.assistants.showModal(false);
    },
    acceptAI(data) {
      this.newItem = { ...this.newItem, ...data };
    },
    onEmojifyTitle(emojifiedTitle) {
      this.newItem.title = emojifiedTitle;
      console.log(this.newItem.title, emojifiedTitle);
    },
    async getCalendarImport() {
      if (this.newItem.calendarId) {
        const snapshot = this.getContextRef('amc-events').child('config').child(this.dbContext).child('import');
        const importConfig = await snapshot.once('value');
        this.importCalendar = importConfig.val().find((config) => config.calendarId === this.newItem.calendarId);
      }
    },
  },
};
</script>

<style scoped>
ul#items li {
  color: black;
}
</style>
