<template>
  <b-modal id="switch-page-modal" :title="title" :class="classes" @ready="ready" @hide="destroy">
    <template #body>
      <div v-for="(node, index) in nodes" :key="index">
        <a class="text-start underline link-secondary d-block" style="cursor: pointer" @click="goToPage(node)">
          {{ node.detailTitle }}
        </a>
      </div>
    </template>
    <template #footer-right>
      <!-- Loop through buttons prop -->
      <button
        v-for="(button, index) in buttons"
        :key="index"
        type="button"
        class="btn"
        :class="button.variant"
        @click="
          () => {
            if (button?.action) {
              console.log(`Clicked ${button.title} button in ${title}.`);
              button?.action();
            }
            hide();
          }
        ">
        {{ button.title }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import BModal from '../common/BModal.vue';

export default {
  name: 'PromptModal',
  components: {
    BModal,
  },
  props: {
    title: { type: String, default: 'Switch Page' },
    buttons: {
      type: Array,
      default: () => [{ title: 'Cancel', variant: 'btn-secondary', action: () => {} }],
    },
    nodes: { type: Array, default: null },
    classes: { type: String, default: '' },
  },
  data() {
    return {
      modal: null,
    };
  },
  created() {
    //Add a default variant to each button if one isn't assigned.
    this.buttons.forEach((button) => {
      if (!button.variant) button.variant = 'btn-primary';
    });
  },
  methods: {
    ready(modal) {
      this.modal = modal;
      this.$emit('ready', modal);
    },
    show() {
      if (this.modal) this.modal.show();
    },
    hide() {
      if (this.modal) this.modal.hide();
    },
    destroy() {
      this.$emit('destroy');
    },
    goToPage(node) {
      console.log('node', node);

      if (node.type === 'calendar') {
        //http://fourfieldpondtrevor.resortnavigator.test:8081/amc-calendar/remote/8ee1f65b-d420-4658-8556-d2a359b45c94
        this.$router.push({ name: 'remoteCalendar', params: { detailId: node.id, remoteInfo: node } });
      } else {
        //Default behavior is business.
        window.localStorage.setItem('REMOTE_DETAIL_LAST_EDITED', node.id);
        this.$router.replace({
          name: 'remoteUpdateDetail',
          params: { detailId: node.id, remoteInfo: node },
        });
      }

      this.hide();
    },
  },
};
</script>
