<template>
  <div class="p-2 form-container">
    <form @submit.prevent="submitUpdate" novalidate>
      <b-accordion id="mapConfig" :headings="['General Information']">
        <template #body="{ heading }">
          <div v-if="heading === 'General Information'">
            <!-- text input box v-modal title -->
            <div class="mb-3">
              <label class="form-label">Map Title</label>
              <input
                type="text"
                class="form-control"
                v-model="title"
                :class="{ 'is-invalid': vuelidate$.title.$dirty && vuelidate$.title.$error }"
                trim />
              <Validation :validator="vuelidate$.title" feedback="Calendar title is required." />
            </div>
          </div>
        </template>
      </b-accordion>

      <Validation :validator="vuelidate$.title" map="form" />

      <button class="btn m-1 btn-primary" type="submit">Submit</button>
      <button class="btn m-1 btn-secondary" type="button" @click.stop="cancelUpdate">Cancel</button>
    </form>
  </div>
</template>

<script>
import BAccordion from '@/components/common/BAccordion.vue';
import { required } from '@vuelidate/validators';
import Validation from '@/components/common/Validation.vue';

export default {
  components: { BAccordion, Validation },
  data() {
    return {
      title: '',
    };
  },
  validations() {
    return {
      title: {
        required,
      },
    };
  },

  methods: {
    createMap() {
      const dbRef = this.getLocationRef().child('searchablemap-detail');
      const key = dbRef.push().key;
      console.log('key', key);

      dbRef.child(key).child('title').set(this.title);
    },

    submitUpdate() {
      this.vuelidate$.$touch();

      if (!this.vuelidate$.$invalid) {
        this.createMap();
        this.$emit('action', 'submit');
      }
    },
    cancelUpdate() {
      this.$emit('action', 'cancel');
    },
  },
};
</script>

<style scoped></style>
