<template>
  <b-modal id="assistants-modal" ref="modal" :title="title" size="lg" classes="amc-modal">
    <template #body>
      <div class="flex-container">
        <div class="input-container">
          <input class="form-control" id="prompt-selection" v-model="text" type="text" placeholder="Enter prompt..." @keyup.enter="generate()" />
        </div>
        <select class="form-select" style="margin-right: 10px; width: 100px" v-if="showAppTypeSelect" v-model="locationSelect">
          <option>Resort</option>
          <option>Local Area</option>
        </select>
        <button class="btn btn-accent" id="chat-button" @click.stop="generate()" :disabled="this.text.length == 0 || this.assistant === null">
          <i class="material-icons" style="margin-right: 16px">send</i>Search
        </button>
      </div>
      <div style="display: flex">
        <div style="flex-grow: 1">
          <div class="prompts-container" v-for="(promptValue, promptKey) in this.ui" :key="promptKey">
            <div class="prompt-header" data-bs-toggle="collapse" :data-bs-target="'#collapse-prompt' + promptKey" :title="promptValue.prompt">
              {{ getPromptHeader(promptValue) }}
            </div>
            <div
              class="collapse"
              style="padding: 0.75rem 1rem 0.75rem 1rem"
              :id="'collapse-prompt' + promptKey"
              :class="{ show: promptValue.isOpen === true }">
              <div v-if="promptValue.spinner" class="spinner-container">
                <div class="spinner-border chat-spinner" role="status"></div>
                <div class="spinner-text">Thinking...</div>
              </div>
              <div v-for="(responseValue, responseKey) in promptValue.children" :key="responseKey" style="display: flex">
                <div class="history-container">
                  <!-- History header -->
                  <div class="history-header" data-bs-toggle="collapse" :data-bs-target="'#collapse-response' + responseKey">
                    <div v-if="responseValue.spinner" class="spinner-container">
                      <div class="spinner-border chat-spinner" role="status"></div>
                      <div class="spinner-text">Thinking...</div>
                    </div>
                    <div v-else style="display: flex">
                      <div style="flex-grow: 1">{{ getResponseHeader(responseValue.value.properties) }}</div>
                      <div>
                        <i class="material-icons" v-if="responseValue.isOpen == false" style="margin-left: auto; margin-right: 10px"> expand_more </i>
                        <i class="material-icons" v-if="responseValue.isOpen == true" style="margin-left: auto; margin-right: 10px"> expand_less </i>
                      </div>
                    </div>
                  </div>
                  <!-- Collapsible history body -->
                  <div class="collapse" :id="'collapse-response' + responseKey" :class="{ show: responseValue.isOpen === true }">
                    <div v-if="!responseValue.spinner">
                      <div
                        class="history-element"
                        v-for="(prop, propIndex) in responseValue.value.properties"
                        :key="propIndex"
                        :style="{ display: getMappedProperty(prop.id).divStyle }">
                        <!-- Property section (id and text) -->
                        <div class="history-property" style="display: flex">
                          <div class="history-property-title" v-html="getPropertyDisplay(prop.id)" />
                          <div style="flex-grow: 1" />
                          <ActionButtons
                            v-if="getMappedProperty(prop.id).allowActions"
                            :ref="`${promptKey}-${responseKey}-${prop.id}`"
                            :promptKey="promptKey"
                            :responseKey="responseKey"
                            :property="prop"
                            :originalPrompt="ui[promptKey].prompt"
                            :map="propertyMap"
                            @action="receivedAction"
                            @actionRegenerate="receivedActionRegenerate"
                            @update="forceUpdate" />
                        </div>
                        <div :class="{ 'fade-loop': prop.spinner }">
                          <input
                            v-if="getMappedProperty(prop.id).input === 'text'"
                            class="form-control"
                            v-model="prop.text"
                            :disabled="prop.spinner" />
                          <textarea
                            v-else-if="getMappedProperty(prop.id).input === 'textarea'"
                            class="form-control"
                            v-model="prop.text"
                            :disabled="prop.spinner"></textarea>
                          <div v-else v-html="prop.text" />
                        </div>
                      </div>
                    </div>
                    <!-- Bottom area for buttons below properties -->
                    <div style="background-color: rgb(240, 240, 240); height: 40px">
                      <button
                        v-if="responseValue.value.properties.length > 1"
                        class="btn btn-accent history-button-bottom"
                        @click.stop="accept(responseValue, promptValue.prompt)"
                        v-tooltip-bottom="'Uses each text item.'"
                        :disabled="responseValue.spinner">
                        <i class="material-icons"> done </i> Use
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer-right>
      <!-- <b-button v-if="isEditOnly == false" variant="danger" @click="deleteAll()" v-bind:style="{ 'margin-right': '16px' }">
                Delete All
              </b-button> -->
      <button class="btn btn-secondary" @click="hideModal()" style="margin-right: 16px">
        {{ isEditOnly ? 'Cancel' : 'Close' }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import OpenAIMixin from '../mixins/OpenAIMixin';
import ActionButtons from './ActionAIButtons.vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import BModal from '@/components/common/BModal.vue';

export default {
  components: {
    ActionButtons,
    BModal,
  },
  mixins: [OpenAIMixin],
  props: ['title', 'name', 'dbPath', 'function', 'parameters'],
  data() {
    return {
      isEditOnly: false,
      db: null,
      uid: null,
      text: '',
      assistant: null,
      promptsOld: [],
      prompts: {},
      ui: {},
      propertyMap: [
        { id: 'title', display: 'Title', input: 'text', divStyle: 'block', allowActions: true, options: 'One title only. Use title case.' },
        {
          id: 'description',
          display: 'Description',
          input: 'textarea',
          divStyle: 'block',
          allowActions: true,
          options: 'Several sentences long.',
        },
      ],
      locationSelect: 'Resort',
      showAppTypeSelect: false,
      isRemote: false,
      remoteInfo: {},
      remoteHTTPS: null,
    };
  },
  computed: {},
  watch: {
    locationSelect: function (val) {
      if (val === 'Resort') this.parameters.appType = 'resort';
      if (val === 'Local Area') this.parameters.appType = 'city';
    },
  },
  async created() {
    const remoteInfo = this.$store.getters.remoteInfo;
    this.uid = firebase.auth().currentUser?.uid || this.$route.params.uuid || remoteInfo.uuid;
    this.isRemote = ['remoteEvent', 'remoteEventEdit'].includes(this.$route.name);
    if (this.isRemote === true) this.remoteHTTPS = firebase.functions().httpsCallable('remoteUpdate');

    console.log(`UserID: ${this.uid}`);
    this.db = await firebase
      .database()
      .ref('resort-navigator')
      .child('users')
      .child(this.uid)
      .child('campground-locations')
      .child(this.getCampgroundKey)
      .child('ai');
  },
  methods: {
    async initialize() {
      this.loadPrompts();
      if (this.assistant === null) {
        this.assistant = await this.getAssistant(this.assistantMap(this.name));
      }
    },

    async generate() {
      //Creates a new prompt, or returns an existing if 'text' matches 'prompt' in database. { key: ..., value: ... }
      const prompt = await this.createPrompt(this.text);
      this.refreshUI();
      this.ui[prompt.key].spinner = true;
      this.ui[prompt.key].isOpen = true;

      const message = { role: 'user', content: `${this.function}(${this.buildParameters()})` };
      this.text = '';
      console.log('Prompting with message:', message.content);

      const thread = await this.createThread();
      await this.createMessage(thread.data.id, message);
      const response = await this.runAssistant(this.assistant.data.id, thread.data.id, '');
      await this.checkStatus(prompt, thread, response);
    },
    buildParameters() {
      let result = `prompt: "${this.text}", `;
      for (let property in this.parameters) {
        if (this.parameters[property] && this.parameters[property].length > 0) {
          result += `${property}: "${this.parameters[property]}", `;
        }
      }
      return result.substring(0, result.length - 2);
    },
    async checkStatus(prompt, thread, run) {
      const interval = setInterval(async () => {
        const status = await this.runStatus(thread.data.id, run.data.id);
        if (status.data.status === 'requires_action') {
          clearInterval(interval);
          console.log('Run status completed: ', status);

          //Parses the JSON response and creates a response with properties.
          this.readJSON(prompt.key, this.getJSONOutput(status));
          this.refreshUI();
          this.ui[prompt.key].isOpen = true;
          if (Object.keys(this.ui[prompt.key].children).length === 1) {
            const responseKey = Object.keys(this.ui[prompt.key].children)[0];
            this.ui[prompt.key].children[responseKey].isOpen = true;
          }

          console.log('Final prompts', this.prompts);
          this.ui[prompt.key].spinner = false;

          await this.runCancel(thread.data.id, run.data.id);
        } else if (status.data.status === 'failed') {
          clearInterval(interval);
          delete this.prompts[prompt.key];
          delete this.ui[prompt.key];

          console.log('run status failed', status);
          await this.runCancel(thread.data.id, run.data.id);
        }
      }, 2500);
    },

    async createPrompt(text) {
      let existing = await this.getPrompt(text);
      if (existing == null) {
        const ref = this.db.child(this.dbPath).push();
        this.prompts[ref.key] = this.promptTemplate(text);
        console.log('Created new prompt path:', ref.key);
        return { value: this.prompts[ref.key], key: ref.key };
      } else {
        console.log('Found existing prompt by text:', existing.key, existing.value.prompt);
        this.prompts[existing.key] = existing.value;
        return existing;
      }
    },
    async getPrompt(text) {
      let result = null;

      if (this.isRemote === false) {
        await this.db.child(this.dbPath).once('value', (snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((child) => {
              if (child.val().prompt === text) {
                result = { key: child.key, value: child.val() };
                console.log('Matched prompt to key:', result);
              }
            });
          }
        });
      } else {
        const data = (
          await this.remoteHTTPS({ action: 'getAssistantHistory', uid: this.uid, campgroundKey: this.getCampgroundKey, path: this.dbPath })
        ).data;
        if (data) {
          console.log('getPrompt', data);
          const kvp = Object.keys(data).map((key) => {
            return { key, value: data[key] };
          });
          console.log(kvp);

          kvp.forEach((kvp) => {
            if (kvp.value.prompt === text) {
              result = kvp;
              console.log('Matched prompt to key:', result);
            }
          });
        }
      }

      return result;
    },
    createResponse(promptKey) {
      const ref = this.db.child(this.dbPath).child(promptKey).push();
      if (!this.prompts[promptKey].responses) {
        this.prompts[promptKey].responses = [];
      }

      this.prompts[promptKey].responses[ref.key] = this.responseTemplate();
      return { value: this.prompts[promptKey].responses[ref.key], key: ref.key };
    },
    createProperty(response, id, text) {
      response.properties.push(this.propertyTemplate(id, text));
    },
    readJSON(promptKey, json) {
      console.log('Reading json:', json);
      json.list.forEach((item) => {
        const response = this.createResponse(promptKey);
        //Loop through each property in the item.
        for (let property in item) {
          this.createProperty(response.value, property, item[property]);
        }
        this.prompts[promptKey][response.key] = response.value;
        this.savePrompt(promptKey, this.prompts[promptKey], response.key, response.value);
      });
    },

    //Firebase database saving/loading functions
    savePrompt(promptKey, prompt, responseKey, response) {
      if (this.isEditOnly == false) {
        console.log(`Attempting to save data to ../${this.dbPath}/${promptKey}/${responseKey}.`, prompt, response);
        if (this.isRemote === false) {
          this.db.child(this.dbPath).child(promptKey).update({ prompt: prompt.prompt, date: prompt.date });
          this.db.child(this.dbPath).child(promptKey).child(responseKey).set(response);
        } else {
          this.remoteHTTPS({
            action: 'setAssistantHistory',
            subAction: 'update',
            uid: this.uid,
            campgroundKey: this.getCampgroundKey,
            path: `${this.dbPath}/${promptKey}`,
            history: { prompt: prompt.prompt, date: prompt.date },
          });
          this.remoteHTTPS({
            action: 'setAssistantHistory',
            subAction: 'set',
            uid: this.uid,
            campgroundKey: this.getCampgroundKey,
            path: `${this.dbPath}/${promptKey}/${responseKey}`,
            history: response,
          });
        }
      }
    },
    async loadPrompts() {
      if (this.isEditOnly == false) {
        //Load data from this.db.child(this.dbPath) into this.prompts.
        this.ui = {};
        if (this.isRemote === false) {
          this.db
            .child(this.dbPath)
            .once('value', (snapshot) => {
              if (snapshot.exists()) {
                this.prompts = snapshot.val();
                this.prompts = Object.fromEntries(Object.entries(this.prompts).sort(([, a], [, b]) => b.date - a.date));

                this.refreshUI();
                console.log(`Loaded prompt data for ${this.dbPath}:`, this.prompts);
              } else {
                console.log(`No prompt data found for ${this.dbPath}.`);
              }
            })
            .catch((error) => {
              console.log(`Error loading prompt data for ${this.dbPath}: ${error}`);
            });
        } else {
          const data = (
            await this.remoteHTTPS({ action: 'getAssistantHistory', uid: this.uid, campgroundKey: this.getCampgroundKey, path: this.dbPath })
          ).data;
          if (data) {
            this.prompts = data;
            this.prompts = Object.fromEntries(Object.entries(this.prompts).sort(([, a], [, b]) => b.date - a.date));

            this.refreshUI();
            console.log(`Loaded prompt data for ${this.dbPath}:`, this.prompts);
          } else {
            console.log(`No prompt data found for ${this.dbPath}.`);
          }
        }
      }
    },
    createDataFromItem(promptText, properties) {
      this.prompts = {};
      this.ui = {};

      this.prompts['-ValidFirebasePrompt'] = this.promptTemplate(promptText);
      this.prompts['-ValidFirebasePrompt']['-ValidFirebaseRespon'] = this.responseTemplate();
      const response = this.prompts['-ValidFirebasePrompt']['-ValidFirebaseRespon'];

      for (let property in properties) {
        response.properties.push(this.propertyTemplate(property, properties[property]));
      }

      this.refreshUI();
      this.ui['-ValidFirebasePrompt'].isOpen = true;
      this.ui['-ValidFirebasePrompt'].children['-ValidFirebaseRespon'].isOpen = true;
      this.$forceUpdate();
    },

    //UI functions

    getPromptHeader(prompt) {
      //Convert prompt.timestamp to a MM/DD/YYYY format.
      const date = new Date(prompt.date);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      const title = prompt.prompt.length > 50 ? prompt.prompt.substring(0, 65) + ' ...' : prompt.prompt;

      return `${month}/${day}/${year} - ${title}`;
    },
    getResponseHeader(properties) {
      switch (this.name) {
        case 'event-coordinator':
          const event = properties.find((element) => element.id === 'title');
          return `${event.text}`;
        case 'hunt-coordinator':
          const hunt = properties.find((element) => element.id === 'title');
          return `${hunt.text}`;
        default:
          return 'Click to expand';
      }
    },
    getPropertyDisplay(id) {
      const property = this.getMappedProperty(id);
      return property ? property.display : id;
    },
    getPropertyOptions(id) {
      const property = this.getMappedProperty(id);
      if (property.options.length > 0) {
        return ` [${property.options}]`;
      }
      return '';
    },
    getMappedProperty(id) {
      return this.propertyMap.find((element) => element.id === id);
    },
    refreshUI() {
      //Clears the UI object and recreates it from the prompts2 object. Should have a low performance cost.
      //After a new response is created, the UI object is recreated.
      //A UI object looks like { key, isOpen, spinner }. The children is another UI object under the a prompt.
      Object.keys(this.prompts).forEach((promptKey) => {
        if (promptKey in this.ui == false) {
          this.ui[promptKey] = this.uiTemplate(promptKey);
          this.ui[promptKey].prompt = this.prompts[promptKey].prompt;
          this.ui[promptKey].date = this.prompts[promptKey].date;
        }

        Object.keys(this.prompts[promptKey]).forEach((responseKey) => {
          if (this.isValidFirebaseKey(responseKey)) {
            if (this.ui[promptKey].children == null) {
              this.ui[promptKey].children = {};
            }
            if (responseKey in this.ui[promptKey].children == false) {
              //Assign the response key as a child of prompt key.
              this.ui[promptKey].children[responseKey] = this.uiTemplate(responseKey, this.prompts[promptKey][responseKey]);
              this.ui[promptKey].children[responseKey].value.properties.forEach((prop) => {
                prop.spinner = false;
              });
            }
          }
        });

        if (this.ui[promptKey]?.children && Object.keys(this.ui[promptKey].children).length === 1) {
          //Select the only object in this.ui[promptKey] and set isOpen to true.
          const responseKey = Object.keys(this.ui[promptKey].children)[0];
          this.ui[promptKey].children[responseKey].isOpen = true;
        }
      });
      console.log(`Reconstructed UI object:`, this.ui);
      this.ui = Object.fromEntries(Object.entries(this.ui).sort(([, a], [, b]) => b.date - a.date));

      this.$nextTick(() => this.$forceUpdate());
    },
    isValidFirebaseKey(key) {
      return /^[a-zA-Z0-9_-]{20}$/.test(key);
    },

    //Data templates. Used when creating new prompts.
    promptTemplate(text) {
      return { prompt: text, date: new Date().getTime(), responses: {} };
    },
    responseTemplate() {
      return { properties: [] };
    },
    propertyTemplate(id, text) {
      return { id, text, history: [text] };
    },
    uiTemplate(key, value) {
      const obj = { key, isOpen: false, spinner: false };
      if (value) obj.value = value;
      return obj;
    },

    //Button functions
    accept(response, promptText) {
      const obj = {};
      for (let property in response.value.properties) {
        obj[response.value.properties[property].id] = response.value.properties[property].text;
      }

      console.log('accepted all', obj);
      this.$emit('accept', obj, { prompt: promptText, locationType: this.locationSelect });
      this.hideModal();
    },
    acceptOne(property) {
      const obj = {};
      obj[property.id] = property.text;

      console.log(`accepted ${property.id}`, obj);
      this.$emit('accept', obj);
      this.hideModal();
    },

    receivedAction(promptKey, responseKey) {
      const prompt = this.prompts[promptKey];
      const response = this.prompts[promptKey][responseKey];
      this.savePrompt(promptKey, prompt, responseKey, response);
      this.$forceUpdate();
    },
    receivedActionRegenerate(promptKey, responseKey, property) {
      if (property.id === 'title') {
        this.$refs[`${promptKey}-${responseKey}-description`][0].buttonPrompt(`Create a description based off of this title: '${property.text}'.`);
      }
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    deletePrompt(promptKey, responseKey) {
      //Delete response from database, prompts, and ui
      this.db.child(this.dbPath).child(promptKey).child(responseKey).remove();
      delete this.prompts[promptKey][responseKey];
      delete this.ui[promptKey].children[responseKey];

      //Delete prompt from database, prompts, and ui if no responses are left.
      if (Object.keys(this.ui[promptKey].children).length === 0) {
        this.db.child(this.dbPath).child(promptKey).remove();
        delete this.prompts[promptKey];
        delete this.ui[promptKey];
      }

      this.$forceUpdate();
    },
    deleteAll() {
      // this.$bvModal
      //   .msgBoxConfirm('This will permanently delete all of the listed prompts.', {
      //     title: 'Delete Prompts',
      //     okTitle: 'Delete',
      //     cancelTitle: 'Keep',
      //     footerClass: 'p-2',
      //     hideHeaderClose: false,
      //     centered: true,
      //   })
      //   .then((value) => {
      //     if (value) {
      //       this.db.child(this.dbPath).remove();
      //       this.prompts = {};
      //       this.ui = {};
      //       this.$forceUpdate();
      //     }
      //   })
      //   .catch((err) => {
      //     console.log('err', err);
      //     // An error occurred
      //   });
    },
    maximize(promptKey, responseKey) {
      if (this.ui[promptKey] && this.ui[promptKey].children[responseKey]) {
        this.ui[promptKey].children[responseKey].isOpen = true;
      }
    },
    maximizeAll(promptKey) {
      if (this.ui[promptKey]) {
        for (let responseKey in this.ui[promptKey].children) {
          this.maximize(promptKey, responseKey);
        }
      }
    },
    minimize(promptKey, responseKey) {
      if (this.ui[promptKey] && this.ui[promptKey].children[responseKey]) {
        this.ui[promptKey].children[responseKey].isOpen = false;
      }
    },
    minimizeAll(promptKey) {
      if (this.ui[promptKey]) {
        for (let responseKey in this.ui[promptKey].children) {
          this.minimize(promptKey, responseKey);
        }
      }
    },

    //Modal management/visuals
    async showModal(isEditOnly) {
      this.isEditOnly = isEditOnly;
      this.$refs.modal.show();
      this.initialize();

      if (this.isEditOnly) {
        console.log('edit only hit', this.ui);

        const properties = this.ui['-ValidFirebasePrompt'].children['-ValidFirebaseRespon'].value.properties;
        const title = properties.find((element) => element.id === 'title');
        const description = properties.find((element) => element.id === 'description');

        if (description.text.length === 0) {
          const message = `Create a ${description.id} based off of this title: '${title.text}'.`;
          const result = await this.promptGPT(message, 'gpt-3.5-turbo-1106', (val) => {
            description.spinner = val;
            this.$forceUpdate();
          });
          description.text = this.getPromptText(result);
        }
      }
    },
    hideModal() {
      this.$refs.modal.hide();
    },
    cancel() {},
  },
};
</script>

<style scoped>
.amc-modal {
  min-height: 500px;
}

.button {
  border: none;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-content: center;
}

.flex-container {
  display: flex;
  margin: 10px 0px 0px 0px;
  justify-content: left;
}
.input-container {
  display: flex;
  margin: 0px 10px 10px 0px;
  flex-grow: 1;
}

.prompt-header {
  background-color: #1da9f3 !important;
  color: white !important;
  border: 0px solid white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0px 0px 0px 0px !important;
  padding: 0.5rem;
  height: 40px;
  cursor: pointer;
}
.prompts-container {
  margin: 0px 0px 10px 0px;
  background-color: white;
  border: 0px solid white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: black;
  width: 100%;
  height: auto;
}

.history-header {
  background-color: rgb(230, 230, 230) !important;
  color: black !important;
  border: 0px solid white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0px 0px 0px 0px !important;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  height: 30px;
  align-content: center;
  cursor: pointer;
}
.history-container {
  margin: 0px 0px 10px 0px;
  background-color: white;
  border: 0px solid white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: black;
  width: 100%;
  height: auto;
}
.history-element {
  display: flex;
  padding: 0.5rem;
  background-color: rgb(240, 240, 240);
  border: 0px solid white;
  border-top: #ccc 1px solid;
}
.history-property {
  text-align: justify;
  padding: 4px 10px 10px 4px;
}
.history-property-title {
  font-size: smaller;
  font-weight: bold;
}
@keyframes promptfade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.history-property-body {
}
.fade-loop {
  animation: promptfade 1s infinite;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-self: space-between;
  max-width: 120px;
  max-height: 115px;
  margin: 0px 0px 0px 5px;
}
.history-button {
  display: flex;
  align-items: left;
  justify-content: left;
  margin: 4px;
  padding: 4px !important;
  width: 120px;
  height: 30px;
}
.history-button i.material-icons {
  margin-right: 5px;
}
.history-button-bottom {
  display: flex;
  align-items: left;
  justify-content: left;
  margin: 0px 0px 10px 10px;
  padding: 0px 4px 0px 4px !important;
  height: 25px;
}

.spinner-container {
  display: flex;
  align-items: center;
  height: 30px;
}
.chat-spinner {
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 10px;
}
.spinner-text {
  margin-left: 10px;
}

#prompt-selection {
  margin-right: 0px;
  overflow: auto;
  resize: vertical;
}
#prompt-text {
  margin: 0px 0px 10px 0px;
}
#chat-button {
  height: 38px;
  width: 115px;
}
</style>
