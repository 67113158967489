<template>
  <div class="two-column no-content-column">
    <div id="center-column" :style="getBackgroundColorStyle">
      <div id="item-header" :style="getHeaderStyles()">
        <iframe
          v-if="newItem.headerInfo && newItem.headerInfo.videoURL"
          width="490"
          height="261"
          :src="`https://www.youtube.com/embed/${newItem.headerInfo.videoURL}`"
          title="YouTube video player"
          frameborder="0"
          allow="mute; autoplay; allowfullscreen"></iframe>
        <div
          id="listBuilderCarousel"
          class="carousel slide"
          data-bs-ride="carousel"
          v-if="this.newItem.headerInfo && this.newItem.headerInfo.images && this.newItem.headerInfo.images.length > 0">
          <div class="carousel-inner">
            <div
              class="carousel-item"
              v-for="(src, index) in this.newItem.headerInfo.images"
              :data-bs-interval="newItem.headerInfo.delaySeconds * 1000 || 5000"
              :class="{ active: index === 0 }"
              :key="index">
              <img :src="src.imageURL" class="d-block w-100" :alt="src.caption" style="height: 261px" />
              <div class="carousel-caption d-none d-md-block">
                <p>{{ src.caption }}</p>
              </div>
            </div>
          </div>
          <ol class="carousel-indicators" style="list-style-type: none">
            <li
              v-for="(src, index) in newItem.headerInfo.images"
              :key="index"
              data-bs-target="#listBuilderCarousel"
              :data-bs-slide-to="index"
              :class="{ active: index === 0 }" />
          </ol>
          <button
            id="carousel-control-prev"
            v-if="this.newItem.headerInfo.images && this.newItem.headerInfo.images.length > 1"
            class="carousel-control-prev"
            type="button"
            data-bs-target="#listBuilderCarousel"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            id="carousel-control-next"
            v-if="this.newItem.headerInfo.images && this.newItem.headerInfo.images.length > 1"
            class="carousel-control-next"
            type="button"
            data-bs-target="#listBuilderCarousel"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <h1 v-show="!this.newItem.headerInfo || !this.newItem.headerInfo.hideTitle">{{ newItem.title }}</h1>
      </div>
      <div class="row buttonRow" v-if="newItem.allowFavorite || newItem.dollarsActive">
        <div class="col" v-if="newItem.allowFavorite">
          <DetailButton buttonText="Messages" buttonIcon="notifications" @click="openURL(newItem.website)" />
        </div>
        <div class="col" v-if="newItem.allowFavorite">
          <DetailButton buttonText="Add Favorite" buttonIcon="star" />
        </div>
        <div class="col" v-if="newItem.dollarsActive">
          <DetailButton buttonText="Pay with..." buttonIcon="menu_book" />
        </div>
      </div>
      <div class="row buttonRow">
        <div class="col" v-if="newItem.phone">
          <DetailButton buttonText="Call" buttonIcon="call" />
        </div>
        <div class="col" v-if="displayMap">
          <DetailButton buttonText="Map" buttonIcon="map" />
        </div>
        <div class="col" v-if="newItem.website">
          <DetailButton buttonText="Website" buttonIcon="link" @click="openURL(newItem.website)" />
        </div>
        <div class="col" v-if="newItem.menu">
          <DetailButton buttonText="Menu" buttonIcon="link" @click="openURL(newItem.menu)" />
        </div>
        <div class="col" v-if="newItem.hasSpecial">
          <DetailButton buttonText="Special" buttonIcon="new_releases" @click="openURL(newItem.menu)" />
        </div>
        <div class="col" v-for="(button, index) in newItem.detailButtons" v-bind:key="index">
          <DetailButton
            :buttonText="button.title"
            :buttonIcon="button.icon"
            :svg="button.iconData ? button.iconData.svg : null"
            :action="button.action"
            :actionKey="button.actionKey" />
        </div>
      </div>
      <div class="row m-1">
        <div class="col" v-if="newItem.always">Open 24/7</div>
        <div class="col" v-else-if="!noHours">
          <div class="d-inline-flex" v-html="daysText" />
          <div class="d-inline-flex m-2" v-html="hoursText" />
        </div>
      </div>
      <div class="row m-1 mt-2">
        <div class="col">
          <div>
            {{ newItem.address }}
          </div>
          <div>{{ newItem.city }}{{ newItem.city && newItem.state ? ',' : '' }} {{ newItem.state }} {{ newItem.zip }}</div>
        </div>
      </div>
      <div class="row m-1 mt-2">
        <div class="col">
          <span v-html="newItem.description"></span>
        </div>
      </div>
    </div>
    <div id="right-column" class="p-2">
      <div class="p-2 form-container">
        <form @submit.prevent="submitUpdate" novalidate>
          <b-accordion
            id="hunts"
            :headings="[
              'Title/Location',
              'Description',
              'Dollars',
              'Header Image(s)/Video',
              'Hours',
              'Buttons',
              'Remote Administration',
              'Associated Listing Sections',
              'QR Code(s)',
            ]"
            :hidden="[
              isDollarsEnabled ? '' : 'Dollars',
              !isRemote && (isAdminForLocation || isResortAdmin) ? '' : 'Remote Administration',
              !isRemote && (isAdminForLocation || isResortAdmin) ? '' : 'Associated Listing Sections',
            ]">
            <template #body="{ heading }">
              <div v-if="heading === 'Title/Location'">
                <template v-if="dbContext !== 'business-detail' && !isRemote && Object.keys(listIcons).length > 0">
                  <div class="mb-3">
                    <label class="form-label">Listing Icon(s) - Select all that apply.</label>
                    <div class="form-check mb-3" v-for="(icon, key) in listIcons" :key="key">
                      <input class="form-check-input" type="checkbox" :value="key" v-model="newItem.listIcons" :id="'checkbox-' + key" />
                      <label class="form-check-label d-flex align-items-center" :for="'checkbox-' + key">
                        <img :src="icon.url" style="width: 30%; height: auto; max-width: 60px" class="me-2" />
                        <div style="width: 32px; height: 32px">
                          <svg v-if="!icon.active" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                            <g transform="translate(-421.71-531.79)" fill="#da4453">
                              <path d="m13.293 2l-11.293 11.293c0 0 .716.702.707.707l11.293-11.293" transform="translate(421.71 531.79)" />
                              <path d="m424.42 533.79l11.293 11.293c0 0-.716.702-.707.707l-11.293-11.293z" />
                            </g>
                          </svg>
                        </div>
                      </label>
                    </div>
                    <div class="form-text">A red X denotes a currently inactive icon, so it will not be shown in app</div>
                  </div>
                  <hr class="w-100" />
                </template>

                <div class="mb-3" v-if="emptyBusinessMap">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="noLocation" id="noLocationCheck" />
                    <label class="form-check-label" for="noLocationCheck"> Item Does Not Have A Location </label>
                  </div>
                </div>

                <LocationSelector
                  v-if="!noLocation"
                  :dataObject="newItem"
                  type="business"
                  @updateLocation="updateLocation"
                  :submitted="vuelidate$.$dirty"
                  @locationInvalid="setLocationValid" />

                <div class="mb-3" v-if="noLocation">
                  <label class="form-label">Title</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newItem.title"
                    :class="{ 'is-invalid': vuelidate$.newItem.title.$dirty && vuelidate$.newItem.title.$error }" />
                  <Validation :validator="vuelidate$.newItem.title" feedback="Title is required." />
                </div>
              </div>

              <div v-if="heading === 'Description'">
                <div class="mb-3">
                  <label class="form-label">Description</label>
                  <editor v-model="newItem.description"></editor>
                </div>
              </div>

              <div v-if="heading === 'Dollars'">
                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="newItem.dollarsActive" id="dollarsActiveCheck" />
                    <label class="form-check-label" for="dollarsActiveCheck"> Dollars Active </label>
                  </div>
                </div>
                <DollarsDisplay v-if="displayDollarsHistory" :dollarsId="this.newItem.id" type="participant-dollars" />
              </div>

              <div v-if="heading === 'Header Image(s)/Video'">
                <HeaderImages
                  ref="headerImages"
                  v-if="currentCommunityKey"
                  v-model="newItem.headerInfo"
                  :embedded="true"
                  :placeId="newItem.placeId"
                  :title="newItem.title"
                  @remove-image="removeImage" />
              </div>

              <div v-if="heading === 'Hours'">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="noHours" id="noHoursCheck" />
                  <label class="form-check-label" for="noHoursCheck"> No Hours </label>
                </div>
                <div class="form-check" v-if="!noHours">
                  <input class="form-check-input" type="checkbox" v-model="newItem.always" id="alwaysCheck" />
                  <label class="form-check-label" for="alwaysCheck"> Open 24/7 </label>
                </div>
                <Hours
                  v-show="!newItem.always && !noHours"
                  ref="business-hours"
                  @update:hours="newItem.hours"
                  @update:text="
                    (text) => {
                      daysText = text.days;
                      hoursText = text.hours;
                      noHours = text.noHours;
                    }
                  " />
              </div>

              <div v-if="heading === 'Buttons'">
                <div class="mb-3">
                  <label class="form-label">Phone Number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newItem.phone"
                    :class="{ 'is-invalid': vuelidate$.phoneNumber.$dirty && vuelidate$.phoneNumber.$error }"
                    trim />
                  <Validation :validator="vuelidate$.phoneNumber" map="phone" />
                </div>

                <div class="mb-3">
                  <label class="form-label">Website URL</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newItem.website"
                    :class="{ 'is-invalid': vuelidate$.newItem.website.$dirty && vuelidate$.newItem.website.$error }"
                    trim />
                  <Validation :validator="vuelidate$.newItem.website" map="url" />
                </div>

                <div class="mb-3" v-if="this.dbContext && this.dbContext.includes('dining')">
                  <label class="form-label">Menu URL</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newItem.menu"
                    :class="{ 'is-invalid': vuelidate$.newItem.menu.$dirty && vuelidate$.newItem.menu.$error }"
                    trim />
                  <Validation :validator="vuelidate$.newItem.menu" map="url" />
                </div>

                <div v-if="isDollarsEnabled === true">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="newItem.dollarsActive" id="dollarsActiveCheck" />
                    <label class="form-check-label" for="dollarsActiveCheck"> Dollars Active </label>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="newItem.hasSpecial" id="hasSpecialCheck" />
                    <label class="form-check-label" for="hasSpecialCheck"> Special Offered </label>
                  </div>
                </div>
                <div v-if="newItem.hasSpecial">
                  <div class="mb-3">
                    <label class="form-label">Popup Behavior</label>
                    <select class="form-select" v-model.number="newItem.specialPopupBehavior">
                      <option value="0">Never Popup Special</option>
                      <option value="1">Popup On First View</option>
                      <option value="2">Popup On All Views</option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Popup Detail</label>
                    <editor v-model="newItem.specialDescription" background-color="white" textColor="black"></editor>
                  </div>
                </div>
                <hr class="w-100" />
                <SocialMediaList :items="newItem.socialMedia" />
                <hr v-if="!isRemote || remoteInfo.addButtons" class="w-100" />
                <div class="mb-3" v-if="!isRemote || remoteInfo.addButtons">
                  <label class="form-label">Additional Custom Buttons</label>
                  <DetailButtonList :items="newItem.detailButtons" :isRemote="isRemote" />
                </div>
              </div>

              <div v-if="heading === 'Remote Administration'">
                <p>In this section you can grant individuals, outside of app admins, access to update this detail page.</p>
                <p>This is useful for businesses, clubs, committees, etc.</p>
                <p>When enabled, anyone with the URL(s) created below will have access to modify this specific detail page.</p>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="newItem.remotelyManaged" id="remoteManagementCheck" />
                  <label class="form-check-label" for="remoteManagementCheck"> Allow Remote Management </label>
                </div>
                <div class="form-check" v-if="newItem.remotelyManaged">
                  <input class="form-check-input" type="checkbox" v-model="newItem.allowFavorite" id="allowFavoriteCheck" />
                  <label class="form-check-label" for="allowFavoriteCheck"> Allow Favoriting/Notifications </label>
                </div>
                <Sortable
                  v-if="newItem.remotelyManaged"
                  :list="remoteInfo"
                  :item-key="
                    (item) => {
                      return item;
                    }
                  "
                  tag="ul"
                  class="editable-list"
                  :options="sortableOptions"
                  @end="(evt) => moveItems(evt, remoteInfo)">
                  <template #item="{ element, index }">
                    <li v-if="element != null" class="editable-list-item">
                      <i class="item-remove material-icons" @click="removeRemoteLink(index)">delete</i>
                      <div class="mb-3" v-if="!isDollarsEnabled">
                        <label class="form-label">Grant Access By:</label>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="element.accessType"
                            :name="`accessType${index}`"
                            value="email"
                            :id="`emailLoginRadio-${index}`" />
                          <label class="form-check-label" :for="`emailLoginRadio-${index}`">Email Login</label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="element.accessType"
                            :name="`accessType${index}`"
                            value="url"
                            :id="`urlLoginRadio-${index}`" />
                          <label class="form-check-label" :for="`urlLoginRadio-${index}`">URL(No Login Required)</label>
                        </div>
                      </div>
                      <div v-if="element.accessType === 'url' || !element.accessType">
                        <div class="mb-3">
                          <label class="form-label">Relationship</label>
                          <input type="text" class="form-control" v-model="element.title" placeholder="e.g. Owner, Manager, Staff" />
                        </div>
                        <button :id="'copy' + index" class="btn m-1 btn-primary" type="button" @click="copyRemoteURL(index)">
                          Copy Link for Remote Management
                        </button>
                      </div>
                      <div v-else>
                        <div class="mb-3">
                          <label class="form-label">User's Name</label>
                          <input type="text" class="form-control" v-model="element.name" />
                        </div>
                        <div class="mb-3">
                          <label class="form-label">Email Address</label>
                          <input type="text" class="form-control" v-model="element.email" />
                        </div>
                      </div>
                      <div class="form-check ms-2">
                        <input class="form-check-input" type="checkbox" v-model="element.editInfo" id="editInfoCheck" />
                        <label class="form-check-label" for="editInfoCheck">Edit Information</label>
                      </div>
                      <div class="form-check ms-2">
                        <input class="form-check-input" type="checkbox" v-model="element.addButtons" id="addButtonsCheck" />
                        <label class="form-check-label" for="addButtonsCheck">Add Buttons</label>
                      </div>
                      <div class="form-check ms-2">
                        <input class="form-check-input" type="checkbox" v-model="element.sendNotifications" id="sendNotificationsCheck" />
                        <label class="form-check-label" for="sendNotificationsCheck">Send Notifications</label>
                      </div>
                    </li>
                  </template>
                </Sortable>
                <button v-if="newItem.remotelyManaged" class="btn m-1 btn-secondary" type="button" @click="addRemoteUUID">
                  Add Additional Access
                </button>
              </div>

              <div v-if="heading === 'Associated Listing Sections'">
                This is only used if you would like this item to show up in other sections of your app
                <div class="mb-3">
                  <input class="form-control m-2 user-filter" placeholder="Filter Sections" v-model="sectionFilter" type="search" />
                </div>
                <div class="mb-3">
                  <div class="form-check ms-2" v-for="(option, index) in filteredContexts" :key="index">
                    <input class="form-check-input" type="checkbox" :value="option.value" v-model="newItem.contexts" :id="`option${index}`" />
                    <label class="form-check-label" :for="`option${index}`">{{ option.text }}</label>
                  </div>
                </div>
              </div>

              <div v-if="heading === 'QR Code(s)'">
                <QRCode
                  ref="qrCode"
                  :title="`${newItem.title} QR Code`"
                  action="businessscan"
                  :action-key="newItem.id"
                  :action-params="actionParams" />
              </div>
            </template>
          </b-accordion>

          <Validation :validator="vuelidate$.newItem" map="form" />

          <button class="btn m-1 btn-primary" type="submit">Submit</button>
          <button v-if="!isRemote" class="btn m-1 btn-secondary" type="button" @click.stop="cancelUpdate">Cancel</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import editor from '../Editor';
import DetailButton from '../DetailButton';
import LocationSelector from '../LocationSelector';
import Hours from '../Hours';
import DetailButtonList from '../listitems/DetailButtonList';
import HeaderImages from './HeaderImages';
import DollarsDisplay from './DollarsDisplay';
import { numeric, url, minLength, required } from '@vuelidate/validators';
import uuidv4 from 'uuid/v4';
import { Sortable } from 'sortablejs-vue3';
import SocialMediaList from '../listitems/SocialMediaList';
import axios from 'axios';

import Vue from 'vue';
import ClipboardJS from 'clipboard';
import BAccordion from '../common/BAccordion';
import QRCode from '../inputfields/QRCode.vue';

export default {
  components: {
    editor,
    DetailButton,
    LocationSelector,
    Hours,
    DetailButtonList,
    Sortable,
    // eslint-disable-next-line vue/no-unused-components
    HeaderImages,
    SocialMediaList,
    DollarsDisplay,
    BAccordion,
    QRCode,
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name === 'remoteUpdateDetail' && from.name === to.name) {
      this.newItem = { ...this.emptyItem };
      this.itemId = to.params.detailId;
      next();
      this.setupBusiness();
    } else {
      next();
    }
  },
  data() {
    let newItem = {
      hasSpecial: false,
      drawable: null,
      distance: null,
      always: false,
      description: '',
      title: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      latitude: null,
      longitude: null,
      detailButtons: [],
      remotelyManaged: false,
      hours: {
        Fri: [{ close: '?', open: '?' }],
        Mon: [{ close: '?', open: '?' }],
        Sat: [{ close: '?', open: '?' }],
        Sun: [{ close: '?', open: '?' }],
        Thu: [{ close: '?', open: '?' }],
        Tue: [{ close: '?', open: '?' }],
        Wed: [{ close: '?', open: '?' }],
      },
      allowFavorite: false,
      listIcons: [],
      contexts: [],
      headerInfo: {},
      socialMedia: [],
      dollarsActive: null,
    };
    return {
      sectionFilter: null,
      filteredContexts: [],
      businessContexts: [],
      originalContexts: [],
      newItem,
      emptyItem: { ...newItem },
      listIcons: {},
      hunt: false,
      noHours: true,
      dbRef: null,
      remoteRef: null,
      daysText: '',
      hoursText: '',
      phoneNumber: null,
      locationInvalid: false,
      emptyBusinessMap: false,
      noLocation: false,
      isRemote: true,
      isDev: process.env.NODE_ENV == 'development',
      remoteUpdates: {},
      remoteInfo: {},
      dbContext: '',
      itemId: '',
      isAdminForLocation: false,
      isResortAdmin: false,
      currentCommunityKey: null,
      origRemoteIds: [],
      displayDollarsHistory: false,
      copyImageQueryURL: null,
      clipboards: {},
      actionParams: [],
    };
  },
  computed: {
    displayMap: function () {
      return this.newItem.address || this.newItem.city || (this.newItem.latitude && this.newItem.longitude);
    },
    sortableOptions() {
      return {
        animation: 150,
        easing: 'cubic-bezier(1, 0, 0, 1)',
      };
    },
  },
  watch: {
    'newItem.phone': function () {
      this.phoneNumber = this.newItem.phone ? this.newItem.phone.replace(/\D+/g, '') : null;
    },
    'newItem.remotelyManaged': function () {
      if (this.newItem.remotelyManaged && Object.keys(this.remoteInfo).length === 0) {
        this.remoteInfo = {};
        this.addRemoteUUID();
        this.matchClipboards();
        console.log('Remote Info:', this.remoteInfo, this.newItem.remotelyManaged);
      }
    },
    sectionFilter: function () {
      this.filteredContexts = !this.sectionFilter
        ? this.businessContexts
        : this.businessContexts.filter((context) => {
            const item = context.text;
            const ftr = this.sectionFilter.toLowerCase();
            return item.toLowerCase().includes(ftr);
          });
    },
    '$store.community.colorBackground': function () {
      this.newItem.headerInfo = { ...this.newItem.headerInfo };
    },
  },
  validations() {
    return {
      newItem: {
        website: {
          url,
        },
        menu: {
          url,
        },
        title: {
          required,
        },
      },
      phoneNumber: {
        numeric,
        minLength: minLength(10),
      },
    };
  },
  async created() {
    this.currentCommunityKey = this.getCampgroundKey;
    this.isResortAdmin =
      window.localStorage.getItem(`RESORT_SYSTEM_ADMIN`) == true || window.localStorage.getItem('RESORT_SYSTEM_ADMIN') == 'true' ? true : false;
    this.isAdminForLocation = (JSON.parse(window.localStorage.getItem(`ADMIN_LOCATIONS`)) || []).includes(this.currentCommunityKey);
    this.emptyBusinessMap = this.getFeatureAvailability('emptyBusinessMap');
    this.dbRef = firebase.database().ref('resort-navigator');
    this.remoteRef = this.dbRef.child('remote-management');

    if (this.currentCommunityKey) {
      this.getContextRef('config/business/listIcons', this.currentCommunityKey)
        .once('value')
        .then((snap) => {
          this.listIcons = snap.val() ?? {};
        });
    }
    this.setupBusiness();
  },
  mounted() {
    //Fix for bootstrap not automatically starting carousel.
    setTimeout(() => {
      if (this.newItem.headerInfo && this.newItem.headerInfo.images && this.newItem.headerInfo.images.length > 1) {
        const prev = document.getElementById('carousel-control-prev');
        const next = document.getElementById('carousel-control-next');
        next?.click();
        prev?.click();
      }
    }, 2000);
  },
  methods: {
    matchClipboards() {
      //this.destroyClipboards();
      console.log('remote info', this.remoteInfo);

      Object.entries(this.remoteInfo).forEach(([key, remote]) => {
        console.log('Creating clipboard for', key, remote);

        this.clipboards[key] = new ClipboardJS(`#copy${key}`, {
          text: () => {
            console.log('copied', '#copy' + key);

            return `${this.$hostname}/detail/remote/${key}`;
          },
        });
      });
      console.log('clipboard created', this.clipboards);
    },
    destroyClipboards() {
      for (const key in this.clipboards) {
        if (Object.hasOwnProperty.call(this.clipboards, key)) {
          const clipboard = this.clipboards[key];
          clipboard.destroy();
        }
      }
    },
    copyRemoteURL(remoteKey) {
      this.$copyText(`${this.$hostname}/detail/remote/${remoteKey}`);
    },
    setupBusiness: async function () {
      this.itemId = this.$route.params.itemId || this.itemId;
      this.dbContext = this.$route.params.dbContext || this.dbContext;
      if (this.$route.params.communityKey) {
        this.setCampgroundLocationData(this.$route.params.communityKey);
      }
      if (this.$route.name === 'remoteUpdateDetail') {
        await this.setupRemoteUpdateDetail();
      } else if (this.$route.name === 'remoteUpdateNoKey') {
        const remoteBusinessUpdate = firebase.functions().httpsCallable('remoteBusinessUpdate');
        const businessInfo = (await remoteBusinessUpdate({ uuid: this.$route.params.uuid, action: 'getId' })).data;
        if (businessInfo != null) {
          this.isRemote = true;
          this.itemId = Object.keys(businessInfo.item)[0];
          const firstContext = businessInfo.remoteInfo.contexts[0];
          this.currentCommunityKey = firstContext.split('/')[0];

          this.dbContext = businessInfo.remoteInfo.contexts[0].replace(`${this.currentCommunityKey}/`, '');
          this.dbRef = this.getContextRef(this.dbContext, this.currentCommunityKey);
          if (businessInfo.communityInfo) {
            this.setCampgroundLocationData(this.currentCommunityKey, businessInfo.communityInfo);
          } else {
            console.warn('Business:523 at setupBusiness/remote. Community not set as communityInfo is null.');
          }
          this.updateRemoteInfo(businessInfo.remoteInfo);
          this.$store.commit('updateUUID', this.$route.params.uuid);
          this.updateFromItem(businessInfo.item[this.itemId]);
        }
      } else {
        this.isRemote = false;
        this.dbRef = this.getLocationRef(this.currentCommunityKey);
        if (this.itemId) {
          this.getMenuItem(this.dbContext, this.itemId, true);
        } else if (this.$route.params.detailId) {
          this.getMenuItem(this.dbContext, this.$route.params.detailId, false);
        } else {
          this.getBusinessLocations();
        }
      }
    },

    updateRemoteInfo: function (remoteInfo) {
      this.remoteInfo = remoteInfo;
      this.$store.commit('updateRemoteInfo', this.remoteInfo);
    },
    setupRemoteUpdateDetail: async function () {
      this.isRemote = true;
      const remoteInfo =
        this.$route.params.remoteInfo ||
        (
          await firebase
            .database()
            .ref('resort-navigator')
            .child('remote-management')
            .child('uid')
            .child(firebase.auth().currentUser.uid)
            .child(this.$route.params.detailId)
            .once('value')
        ).val();
      this.updateRemoteInfo(remoteInfo);

      this.currentCommunityKey = remoteInfo.origContext;
      this.setCampgroundLocationData(this.currentCommunityKey);
      this.dbContext = remoteInfo.contexts[0];

      const detailData = (
        await this.dbRef.child('location-data').child(this.dbContext).orderByChild('id').equalTo(this.$route.params.detailId).once('value')
      ).val();
      this.itemId = Object.keys(detailData)[0];
      this.updateFromItem(detailData[this.itemId]);
    },
    addAccess: function () {},
    getBusinessLocations: async function () {
      const cLocations = (await firebase.database().ref('resort-navigator').child('campground-locations').once('value')).val();
      const espArray = [
        'eat',
        'shop',
        'play',
        'stay',
        'visit-the-parkland',
        'community',
        'live-work',
        'services',
        'venues',
        'discover',
        'saint-theodore',
        'st-theodore-school',
        'events',
      ];
      const locationArray = espArray.includes(this.currentCommunityKey) ? espArray : [this.currentCommunityKey];
      const promises = [];
      locationArray.forEach((locationId) => {
        const cLocationData = cLocations[locationId];
        if (cLocationData) {
          promises.push(
            this.getLocationRef(locationId)
              .once('value')
              .then((snapshot) => {
                this.businessContexts = [
                  ...this.businessContexts,
                  ...this.getBusinessesFromMenu(snapshot, 'main-menu', locationId, cLocationData.title),
                ];
              })
          );
        }
      });
      Promise.all(promises).then(() => {
        const missingContexts = this.newItem.contexts
          .filter((x) => this.businessContexts.filter((y) => y.value === x).length == 0)
          .map((missingContext) => {
            const missingLocationId = missingContext.substr(0, missingContext.indexOf('/'));
            const title = cLocations[missingLocationId]?.title || missingLocationId;
            return {
              value: missingContext,
              text: `Main or Sub Menu Item(${title})`,
            };
          });
        this.businessContexts = [...missingContexts, ...this.businessContexts];
        this.filteredContexts = this.businessContexts;
      });
      if (this.dbContext === 'business-detail') {
        this.businessContexts.push({ value: `${this.currentCommunityKey}/${this.dbContext}`, text: this.newItem.title, disabled: true });
        this.addCurrentContext();
      }
    },
    addCurrentContext: function () {
      if (!this.newItem.contexts.includes(`${this.currentCommunityKey}/${this.dbContext}`)) {
        if (this.newItem.contexts) {
          this.newItem.contexts.push(`${this.currentCommunityKey}/${this.dbContext}`);
        } else {
          this.newItem.contexts = [`${this.currentCommunityKey}/${this.dbContext}`];
        }
      }
    },
    getBusinessesFromMenu: function (snapshot, menuId, context, textContext) {
      let businesses = [];
      snapshot.child(menuId).forEach((menuItemSS) => {
        const menuItem = menuItemSS.val();
        const newTextContext = textContext === null ? menuItem.name : `${textContext} -> ${menuItem.name}`;
        if (menuItem.type === 'categorygrid') {
          businesses = [...businesses, ...this.getBusinessesFromMenu(snapshot, menuItem.uniqueId, context, newTextContext)];
        } else if (menuItem.type === 'business') {
          const newContext = `${context}/${menuItem.uniqueId}`;
          businesses.push({ value: newContext, text: newTextContext });
        }
      });
      return businesses;
    },
    getHeaderStyles: function () {
      let image = this.getImage();
      if (this.newItem.headerInfo && this.newItem.headerInfo.videoURL) {
        return `height:261px;`;
      } else if (image) {
        return `height:261px;background-image:url("${image}");`;
      }
      return `${this.getBackgroundColorStyle}height:50px;`;
    },
    getImage: function () {
      if (this.newItem.headerInfo && this.newItem.headerInfo.images && this.newItem.headerInfo.images.length > 0)
        return this.newItem.headerInfo.images[0].imageURL || null;
      if (this.newItem.drawable) return this.newItem.drawable;
    },
    getMenuItem: async function (dbContext, id, isItem) {
      if (id) {
        let detailInfo = null;
        if (isItem) {
          detailInfo = (await this.dbRef.child(dbContext).child(id).once('value')).val();
        } else {
          const res = (await this.dbRef.child(dbContext).orderByChild('id').equalTo(id).once('value')).val();
          this.itemId = Object.keys(res)[0];
          detailInfo = res[this.itemId];
        }
        this.updateFromItem(detailInfo);
      } else {
        this.getBusinessLocations();
        this.newItem.contexts.push(`${this.currentCommunityKey}/${this.dbContext}`);
      }
    },
    updateFromItem: function (pulledItem) {
      this.newItem = { ...this.newItem, ...pulledItem };
      this.noLocation = this.newItem.title && this.emptyBusinessMap && !this.displayMap;
      this.$refs['business-hours'].load(this.newItem.hours);
      if (this.newItem.id && !this.isRemote) {
        this.getRemoteInfo();
      }
      this.originalContexts = this.newItem.contexts ? [...this.newItem.contexts] : [`${this.currentCommunityKey}/${this.dbContext}`];
      this.getBusinessLocations();
      this.$store.commit('updateDetailId', this.newItem.id);
      this.actionParams = [
        { key: 'businessTitle', value: this.newItem.title },
        { key: 'itemDetailPath', value: `${this.dbContext}` },
      ];
    },
    getRemoteInfo: function () {
      this.remoteRef
        .child('detailId')
        .child(this.newItem.id)
        .once('value')
        .then((uuidSnap) => {
          this.remoteInfo = uuidSnap.val() || {};
          this.origRemoteIds = Object.keys(this.remoteInfo);
          const uids = this.origRemoteIds.filter((x) => this.remoteInfo[x].uid).map((x) => this.remoteInfo[x].uid);
          const getUserData = firebase.functions().httpsCallable('getUserData');
          getUserData({ uids }).then((results) => {
            this.origRemoteIds.forEach((id) => {
              if (this.remoteInfo[id].uid) {
                Vue.set(this.remoteInfo[id], 'name', results.data[id].displayName);
                Vue.set(this.remoteInfo[id], 'email', results.data[id].email);
              }
            });
          });
        })
        .catch(() => null);
    },
    addRemoteUUID: function () {
      this.newItem.id = this.getDetailId();
      this.newItem.contexts = this.newItem.contexts || [`${this.currentCommunityKey}/${this.dbContext}`];
      const uuid = uuidv4();
      Vue.set(this.remoteInfo, uuid, {
        contexts: this.newItem.contexts,
        title: '',
        editInfo: true,
        accessType: this.isDollarsEnabled ? 'email' : 'url',
        origContext: this.currentCommunityKey,
      });
    },
    getDetailId: function () {
      return this.newItem.id || this.dbRef.child(this.dbContext).push().key;
    },
    // doCopy: function (uuid) {
    //   this.$copyText(`${this.$hostname}/detail/remote/${uuid}`);
    // },
    updateHeaderImage: function (imageURL) {
      this.newItem.drawable = imageURL;
    },
    openURL: function (url) {
      window.open(url, '_blank');
    },
    updateLocation: function (locationData) {
      if (locationData.latitude) locationData.latitude = locationData.latitude.toString();
      if (locationData.longitude) locationData.longitude = locationData.longitude.toString();

      this.newItem = { ...this.newItem, ...locationData };
      this.$refs['business-hours'].convertFromLocation(this.newItem.hours);
      if (
        locationData.photos &&
        locationData.photos.length > 0 &&
        (!this.newItem.headerInfo || ((!this.newItem.headerInfo.images || this.newItem.headerInfo.images == 0) && !this.newItem.headerInfo.videoURL))
      ) {
        const imageURL = locationData.photos[0].getUrl();
        if (imageURL !== this.copyImageQueryURL) {
          const url = `${this.$apihostname}/api/copyImage`;
          this.copyImageQueryURL = imageURL;
          axios.post(url, { url: imageURL, communityKey: this.currentCommunityKey || this.getCampgroundKey, bucket: this.bucket }).then((result) => {
            this.newItem.headerInfo = this.newItem.headerInfo || {};
            this.newItem.headerInfo.images = [{ imageURL: result.data.url }];
            this.copyImageQueryURL = null;
          });
        }
      }

      this.$delete(this.newItem, 'photos');
    },
    setLocationValid: function (isInvalid) {
      this.locationInvalid = isInvalid;
    },
    removeRemoteLink: function (key) {
      Vue.delete(this.remoteInfo, key);
    },
    submitUpdate: async function () {
      this.vuelidate$.$touch();

      this.$nextTick(async () => {
        if (!this.vuelidate$.$invalid && !this.locationInvalid && (await this.$refs.headerImages.isValid())) {
          this.addCurrentContext();
          this.newItem.id = this.getDetailId();
          let submitItem = JSON.parse(JSON.stringify(this.newItem));
          let allHoursEmpty = true;
          if (submitItem.hours) {
            const keys = Object.keys(submitItem.hours);
            if (keys.length < 7) {
              allHoursEmpty = false;
            }
            for (const key of keys) {
              if (submitItem.hours[key]) {
                submitItem.hours[key].forEach((hoursElement, index) => {
                  if (hoursElement.open || hoursElement.close) {
                    allHoursEmpty = false;
                  }
                  submitItem.hours[key][index].open = hoursElement.open == '' ? '?' : hoursElement.open;
                  submitItem.hours[key][index].close = hoursElement.close == '' ? '?' : hoursElement.close;
                });
              }
            }
          }
          submitItem.hours = allHoursEmpty || this.noHours ? null : submitItem.hours;
          submitItem = !this.noLocation
            ? submitItem
            : {
                ...submitItem,
                ...{
                  address: '',
                  city: '',
                  state: '',
                  zip: '',
                  latitude: '',
                  longitude: '',
                  distance: null,
                },
              };
          if (this.isRemote) {
            const remoteBusinessUpdate = await firebase.functions().httpsCallable('remoteBusinessUpdate');
            remoteBusinessUpdate({ uuid: this.$route.params.uuid, action: 'updateBusiness', submitItem: submitItem });
            this.$message.create({
              title: 'Save Complete',
              body: 'Your updates have been saved. You can continue to update or leave this page.',
              buttons: [this.$message.button('OK', 'btn-secondary', null)],
            });
          } else if (this.$route.params.itemId || this.$route.params.detailId) {
            this.dbRef.child(this.dbContext).child(this.itemId).set(submitItem);
            if (submitItem.contexts) {
              const locationDataRef = firebase.database().ref('resort-navigator').child('location-data');
              submitItem.contexts.forEach((currentContext) => {
                locationDataRef
                  .child(currentContext)
                  .orderByChild('id')
                  .equalTo(submitItem.id)
                  .once('value')
                  .then((snap) => {
                    snap.forEach((childSnapshot) => {
                      if (currentContext !== this.dbContext || this.itemId !== childSnapshot.key) {
                        locationDataRef.child(currentContext).child(childSnapshot.key).set(submitItem);
                      }
                    });
                    if (!snap.hasChildren()) {
                      locationDataRef.child(currentContext).push(submitItem);
                    }
                  });
              });
              const contextsToRemove = this.originalContexts.filter((x) => !submitItem.contexts.includes(x));
              contextsToRemove.forEach((contextToRemove) => {
                if (contextToRemove.includes('business-detail')) {
                  locationDataRef
                    .child(contextToRemove)
                    .orderByChild('id')
                    .equalTo(submitItem.id)
                    .once('value')
                    .then((snap) => {
                      snap.forEach((childSnapshot) => {
                        locationDataRef.child(contextToRemove).child(childSnapshot.key).child('contexts').set([]);
                      });
                    });
                } else {
                  locationDataRef
                    .child(contextToRemove)
                    .orderByChild('id')
                    .equalTo(submitItem.id)
                    .once('value')
                    .then((snap) => {
                      snap.forEach((childSnapshot) => {
                        locationDataRef.child(contextToRemove).child(childSnapshot.key).remove();
                      });
                    });
                }
              });
            }
          } else {
            if (submitItem.contexts) {
              const locationDataRef = firebase.database().ref('resort-navigator').child('location-data');
              submitItem.contexts.forEach((currentContext) => {
                locationDataRef.child(currentContext).push(submitItem);
              });
            } else {
              this.dbRef.child(this.dbContext).push(submitItem);
            }
          }

          if (!this.isRemote) {
            await this.updateRemoteMgmt();
            this.$router.go(-1);
          }
        }
      });
    },
    updateRemoteUpdatesForUID: function (remoteUpdates, uid, value) {
      remoteUpdates[`uid/${uid}/${this.newItem.id}`] = value;
      remoteUpdates[`detailId/${this.newItem.id}/${uid}`] = value;
    },
    updateRemoteMgmt: async function () {
      const remoteUpdates = {};
      try {
        this.origRemoteIds.forEach((uid) => this.updateRemoteUpdatesForUID(remoteUpdates, uid, null));

        const itemKeys = Object.keys(this.remoteInfo);
        for (let index = 0; index < itemKeys.length; index++) {
          var itemId = itemKeys[index];
          const remoteInfo = { ...this.remoteInfo[itemId], contexts: this.newItem.contexts, detailTitle: this.newItem.title };
          if (remoteInfo.email && !remoteInfo.uid) {
            const getUserData = firebase.functions().httpsCallable('getUserData');
            itemId = (
              await getUserData({
                type: 'uid',
                email: remoteInfo.email,
                name: remoteInfo.name || '',
                template: 'sendEmailLinkDetailAdmin',
                detailTitle: this.newItem.title,
                vueHost: this.$vueHost,
              })
            ).data;
            remoteInfo.uid = itemId;
          }
          delete remoteInfo.email;
          delete remoteInfo.name;
          this.updateRemoteUpdatesForUID(remoteUpdates, itemId, remoteInfo);
        }
        if (Object.keys(remoteUpdates).length > 0) {
          this.remoteRef.update(remoteUpdates).catch((err) => {
            console.log('🚀 ~ file: Business.vue ~ line 896 ~ updateRemoteMgmt:function ~ err', err);
          });

          console.log('🚀 ~ file: Business.vue ~ line 893 ~ updateRemoteMgmt:function ~ remoteUpdates', remoteUpdates);
        }
      } catch (e) {
        console.log(e);
      }
      /*
      const remoteUpdates = Object.keys(this.remoteInfo).reduce(async (acc, item) => {
        const remoteInfo = { ...this.remoteInfo[item], contexts: this.newItem.contexts };
        var itemId = item;
        if (remoteInfo.email && !remoteInfo.uid) {
          const getUserData = firebase.functions().httpsCallable('getUserData');
          itemId = (await getUserData({ type: 'uid', email: remoteInfo.email, name: remoteInfo.name || '' })).data;
          remoteInfo.uid = itemId;
        }
        console.log('🚀 ~ file: Business.vue ~ line 887 ~ remoteUpdates ~ remoteInfo', remoteInfo);
        acc[`uid/${itemId}/${this.newItem.id}`] = remoteInfo;
        acc[`detailId/${this.newItem.id}/${itemId}`] = remoteInfo;
        console.log('🚀 ~ file: Business.vue ~ line 891 ~ remoteUpdates ~ acc', acc);
        return acc;
      }, {});
      */
    },
    cancelUpdate: function () {
      this.$router.go(-1);
    },
    moveItems(evt, array) {
      const item = array.splice(evt.oldIndex, 1)[0];
      array.splice(evt.newIndex, 0, item);
    },
    removeImage(index) {
      this.newItem.headerInfo.images.splice(index, 1);
    },
  },
};
</script>

<style>
#center-column {
  color: white;
}
#item-header {
  position: relative;
  background-size: cover;
  background-position: center center;
}
#item-header h1 {
  background-color: #1b1b1bcc;
  position: absolute;
  width: 100%;
  bottom: 0px;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-icon-group {
  display: inline;
  height: 60px;
}
.list-icon-group img {
  width: 60px;
}
.list-icon-group svg {
  width: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

#listBuilderCarousel .carousel-indicators li {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  height: 4px;
  margin: 0 8px 0;
  opacity: 1;
  padding: 3px;
  position: relative;
  width: 4px;
}

#listBuilderCarousel .carousel-indicators li::after {
  border: none;
  /*background-color: transparent;*/
  /*border-radius: 50%;*/
  bottom: -7px;
  content: '';
  left: -7px;
  padding: 10px;
  position: absolute;
  right: -7px;
  top: -7px;
}

#listBuilderCarousel .carousel-indicators li.active {
  background-color: white;
}

#listBuilderCarousel .carousel-caption {
  background-color: #1b1b1bcc;
  padding: 0;
  bottom: -16px;
}

#listBuilderCarousel .carousel-inner {
  height: 261px;
}

#listBuilderCarousel .carousel-item img {
  object-fit: cover;
  height: 261px;
}
</style>
