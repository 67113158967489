<template>
  <div class="p-2 form-container">
    <form novalidate @submit.prevent="submitUpdate">
      <b-accordion
        id="eventConfig"
        :headings="['General', 'Audiences', 'User Created Events', 'Calendar Import Config', 'Remote Management']"
        :open-index="0">
        <template #body="{ heading }">
          <div v-if="heading === 'General'">
            <label for="title">Calendar Title</label>
            <input id="title" class="form-control" v-model="eventConfig.title" />

            <label class="form-label">Prior Notification Time</label>
            <div style="width: 100px">
              <input type="number" class="form-control" v-model.number="eventConfig.priorNotificationTime" trim />
            </div>
          </div>

          <div v-if="heading === 'Audiences'">
            <GenericItemList :items="audiences" title="New Audience" button-title="Add Audience" />
          </div>

          <div v-if="heading === 'User Created Events'">
            <div class="card-body">
              <div class="mb-3">
                <div class="form-text">Allow end users the ability to submit events for inclusion in this calendar</div>
                <div class="form-check">
                  <input id="allowUserCreatedCheckbox" v-model="eventConfig.allowUserCreated" class="form-check-input" type="checkbox" />
                  <label class="form-check-label" for="allowUserCreatedCheckbox"> Allow Calendar Event User Submission </label>
                </div>
              </div>
              <div v-if="eventConfig.allowUserCreated">
                <div class="mb-3">
                  <div class="form-text">End user event requests will need approval by staff before being added to the calendar</div>
                  <div class="form-check">
                    <input
                      id="userCreatedRequiresApprovalCheckbox"
                      v-model="eventConfig.userCreatedRequiresApproval"
                      class="form-check-input"
                      type="checkbox" />
                    <label class="form-check-label" for="userCreatedRequiresApprovalCheckbox"> Event Submission Requires Approval </label>
                  </div>
                  <Validation
                    :validator="vuelidate$.eventConfig.userCreatedEmails"
                    feedback="You must include at least one email address to have approval." />
                </div>
                <div class="mb-3">
                  <div class="form-text">
                    Enter email address(es) that will receive approval requests and notifications for guest submitted events
                  </div>
                  <GenericItemList :items="eventConfig.userCreatedEmails" title="Staff Email Address" button-title="Add Email" />
                </div>
              </div>
            </div>
          </div>

          <div v-if="heading === 'Calendar Import Config'">
            <ul class="list-unstyled">
              <li v-for="(calendar, index) in eventConfig.import" :key="index" class="card mb-1">
                <div class="card-header calendar-import">
                  <div>Calendar {{ index + 1 }}</div>
                  <i class="material-icons show pointer" @click.stop="deleteCalendar(index)">delete</i>
                </div>
                <div class="card-body">
                  <div class="mb-3">
                    <label class="form-label">Calendar Name</label>
                    <input v-model="calendar.title" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Calendar Type</label>
                    <select v-model="calendar.type" class="form-select" @change="updateType($event, index)">
                      <option :value="null">No Import</option>
                      <option value="google">Google Calendar</option>
                      <option value="iCal">iCal/ICS Feed</option>
                      <option value="tribe">The Events Calendar(Wordpress Plugin)</option>
                    </select>
                  </div>
                  <div v-if="calendar.type">
                    <div class="mb-3">
                      <label class="form-label">Calendar ID/URL</label>
                      <input v-model="calendar.calendarId" class="form-control" />
                      <div class="form-text">{{ getCalendarIDDescription(calendar.type) }}</div>
                      <Validation
                        v-if="!calendar.calendarId"
                        :validator="vuelidate$.eventConfig.import"
                        feedback="If you add a calendar ID you must include all selects below." />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Default Location</label>
                      <select v-model="calendar.defaultLocation" class="form-select">
                        <option :value="null || undefined" disabled>-- Select Default Location --</option>
                        <option value="">No Default Location</option>
                        <option v-for="(location, index) in locations" :key="index" :value="location.title">{{ location.title }}</option>
                      </select>
                      <div class="form-text">The location to use when there is no location.</div>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Audience</label>
                      <div class="form-text">The audience that will be applied to all imported events. This can be modified after the import.</div>
                      <select v-model="calendar.audience" class="form-select">
                        <option :value="null || undefined" disabled>-- Select Audience --</option>
                        <option v-for="(option, index) in audienceOptions" :key="index" :value="option.value">{{ option.text }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </li>
              <div class="mb-3">
                <button class="btn btn-secondary" type="button" @click="addCalendar">Add Another Calendar to Import</button>
              </div>
            </ul>
          </div>

          <div v-if="heading === 'Remote Management'">
            <remote-management
              ref="remoteManagement"
              type="calendar"
              :remote-key="this.eventConfig.remoteKey"
              :dbContext="this.$route.params.context"
              path="/amc-calendar/remote/"
              :title="eventConfig.title"
              @update:remoteKey="setRemoteKey">
              <template #description>
                <p>In this section you can grant individuals, outside of app admins, access to update this calendar.</p>
                <p>This is useful for businesses, clubs, committees, etc.</p>
                <p>When enabled, anyone with the URL(s) created below will have access to modify this specific detail page.</p>
              </template>
            </remote-management>
          </div>
        </template>
      </b-accordion>
      <button class="btn m-1 btn-primary" type="submit">Submit</button>
      <button class="btn m-1 btn-secondary" type="button" @click.stop="cancelUpdate">Cancel</button>
    </form>
  </div>
</template>

<script>
import { required, requiredIf, helpers } from '@vuelidate/validators';
import GenericItemList from '../listitems/GenericItemList';

import { NonDetailFormMixin } from '../mixins/NonDetailFormMixin.js';
import BAccordion from '../common/BAccordion.vue';
import RemoteManagement from '../inputfields/RemoteManagement.vue';

export default {
  components: {
    GenericItemList,
    BAccordion,
    RemoteManagement,
  },
  mixins: [NonDetailFormMixin],
  props: {
    dbContext: {
      type: String,
      required: true,
    },
    locations: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      userCreated: false,
      eventConfig: {
        import: [{}],
        allowUserCreated: false,
        userCreatedRequiresApproval: false,
        userCreatedEmails: [],
        remoteKey: null,
      },
      importCalendarsToRemove: [],
      audiences: [],
      configRef: null,
    };
  },
  validations() {
    // TODO: Fix validation for import array to work all the way
    const validateConfig = {
      eventConfig: {},
    };
    validateConfig.eventConfig.userCreatedEmails = {
      requiredIf: requiredIf(() => this.eventConfig.userCreatedRequiresApproval),
      minLength: 1,
    };
    validateConfig.eventConfig.import = {
      $each: helpers.forEach({
        audience: {
          required,
        },
        eventContext: {
          required,
        },
        calendarId: {
          required,
        },
      }),
    };

    return validateConfig;
  },
  computed: {
    audienceOptions: function () {
      return this.audiences.map((x) => {
        return { text: x, value: x.toLowerCase() };
      });
    },
  },
  created: async function () {
    this.getAudiences();
    this.configRef = this.getLocationRef(this.campgroundKey).child('amc-events').child('config').child(this.$route.params.context);
    const configSnapShot = await this.configRef.once('value');
    this.eventConfig = { ...this.eventConfig, ...configSnapShot.val() };
    console.log('this.eventconfig', this.eventConfig);
  },
  methods: {
    getAudiences: function () {
      this.getContextRef('audience')
        .once('value')
        .then((audienceSnapshot) => {
          this.audiences = audienceSnapshot.val().map((x) => x.title);
        });
    },
    updateType(event, index) {
      if (!event) {
        this.eventConfig.googleImport[index] = {};
      }
    },
    addCalendar() {
      this.eventConfig.import.push({});
    },
    deleteCalendar(index) {
      this.$message.create({
        title: 'Remove Calendar Import',
        body: 'Do you want to stop syncing or stop syncing and delete all events imported form this calendar?',
        buttons: [
          this.$message.button('Cancel', 'btn-secondary', null),
          this.$message.button('Stop Syncing', 'btn-danger', () => {
            this.eventConfig.import.splice(index, 1);
          }),
          this.$message.button('Stop and Delete All Events', 'btn-danger', () => {
            this.importCalendarsToRemove.push(this.eventConfig.import[index].calendarId);
            this.eventConfig.import.splice(index, 1);
          }),
        ],
      });
    },
    getCalendarIDDescription: function (type) {
      let description = 'The URL for the calendar feed';
      if (type == 'google') {
        description = 'Your Google Calendar ID (src) will look something like an email address(*****@gmail.com or *****@group.calendar.google.com)';
      }
      return description;
    },
    setRemoteKey(key) {
      console.log('key', key);
      this.eventConfig.remoteKey = key;
      this.configRef.child('remoteKey').set(key);
    },
    submitUpdate: async function () {
      this.vuelidate$.$touch();
      if (!this.vuelidate$.$invalid) {
        const audiences = this.audiences.map((x) => {
          return { title: x };
        });
        if (!this.eventConfig.allowUserCreated) {
          this.eventConfig.userCreatedRequiresApproval = false;
          this.eventConfig.userCreatedEmails = null;
        }
        const eventRef = this.getLocationRef(this.campgroundKey)
          .child('amc-events')
          .child('calendars')
          .child(this.$route.params.context)
          .child('events');
        const eventsToDelete = {};
        const allCalendarIds = this.eventConfig.import.map((x) => x.calendarId);
        for (const calendarId of this.importCalendarsToRemove) {
          if (!allCalendarIds.includes(calendarId)) {
            const snapshot = await eventRef.orderByChild('calendarId').equalTo(calendarId).once('value');
            snapshot.forEach((childSnapshot) => {
              eventsToDelete[childSnapshot.key] = null;
            });
          }
        }
        await eventRef.update(eventsToDelete);
        this.$refs.remoteManagement.update();
        this.getContextRef('audience').set(audiences);
        this.configRef.update(this.eventConfig);
        this.exitEdit('submit');
      }
    },
  },
};
</script>

<style scoped>
.card {
  background-color: rgba(245, 245, 245, 0) !important;
}
.card-header.calendar-import {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
