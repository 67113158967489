<template>
  <div class="p-2 form-container">
    <MenuTypeModal id="menuTypeModal" label="Menu Type Modal" ref="menuTypeModal" :mainMenuTypes="mainMenuTypes" @updateType="updateType" />
    <event-select-modal
      id="eventSelectModal"
      :calendar-key="existingCalendar"
      :event-key="existingEvent"
      @ready="(modal) => (eventSelectModal = modal)"
      @selected="
        (i) => {
          existingEvent = i.key;
          eventMessage = i.val.title;
        }
      " />

    <form novalidate @submit.prevent="submitUpdate">
      <h2>{{ getTitle }}</h2>
      <b-accordion
        id="mainmenu"
        :headings="['Information', 'QR Code']"
        :hidden="[hasAdjust && ['categorygrid', 'business', 'amc-events'].includes(newItem.type) ? '' : 'QR Code']">
        <template #body="{ heading }">
          <div v-if="heading === 'Information'">
            <div class="form-group mb-3">
              <!---
        <label class="form-label">Type</label>
        <div v-if="rightColumnKey === `` || updatedAction == 'add' || !hasContent">
          <select
            v-model="newItem.type"
            class="form-select"
            :class="{ 'select-placeholder': !newItem.type, 'is-invalid': vuelidate$.newItem.type.$error }"
            @change="updateType(newItem.type)">
            <option v-for="option in mainMenuTypesDropDown" :value="option.id" :key="option.id">{{ option.buttonName }}</option>
            <option :value="-1">More Options...</option>
          </select>
          <Validation :validator="vuelidate$.newItem.type" feedback="Type is required." />
        </div>
        <div v-else>
          -->
              <div>
                <input v-model="newItem.type" class="form-control" type="hidden" />
                Type: {{ getStaticTypeName }}
                <a v-if="rightColumnKey === `` || updatedAction == 'add' || !hasContent" class="btn btn-link" @click="openTypeModal()">Change Type</a>
              </div>
              <div class="mb-3">
                <label for="title" class="form-label">Title</label>
                <input
                  id="title"
                  v-model="newItem.name"
                  :disabled="getItemDisabled"
                  class="form-control"
                  :class="{ 'is-invalid': vuelidate$.newItem.name.$dirty && vuelidate$.newItem.name.$error }"
                  trim />
                <Validation :validator="vuelidate$.newItem.name" feedback="Title is required." />
                <div class="form-text">Title displayed for menu item. Database location: "{{ newItem.uniqueId }}"</div>
              </div>
            </div>
            <div class="container" v-if="Object.keys(newItem).includes(`tabs`) && newItem.tabs.length > 0">
              <div class="row">
                <div v-for="(tab, key) in newItem.tabs" v-bind:key="key" class="form-group col-sm">
                  <label class="form-label" for="tab">Tab {{ key + 1 }}</label>
                  <input class="form-control" type="text" v-model="newItem.tabs[key].title" />
                </div>
              </div>
            </div>
            <div class="mb-3" v-if="newItem.type === `website` && !isFile">
              <label for="url" class="form-label">URL</label>
              <input
                id="url"
                v-model="newItem.url"
                class="form-control"
                :class="{ 'is-invalid': vuelidate$.newItem.url.$dirty && vuelidate$.newItem.url.$error }"
                placeholder="http://www.google.com" />
              <Validation :validator="vuelidate$.newItem.url" map="url" />
              <div class="form-check mt-3">
                <input class="form-check-input" type="checkbox" id="use-web-view" v-model="newItem.useWebView" />
                <label class="form-check-label" for="use-web-view"> Use Web View </label>
              </div>
              <small class="form-text text-muted">
                Use a web view to make the website open up while looking like it is still inside the app. Otherwise the website will open in
                Safari/Chrome.
              </small>
            </div>
            <SelectList
              v-if="newItem.type == 'form'"
              v-model="newItem.uniqueId"
              dbContext="custom-forms"
              label="Form"
              :state="vuelidate$.newItem.uniqueId.$dirty ? !vuelidate$.newItem.uniqueId.$error : true"
              orderBy="title"
              @select="(val) => (newItem.uniqueId = val)" />
            <SelectList
              v-if="newItem.type === `hunts` && newItem.uniqueId != `hunts`"
              v-model="newItem.uniqueId"
              dbContext="hunts"
              label="Scavenger Hunt"
              :state="vuelidate$.newItem.uniqueId.$dirty ? !vuelidate$.newItem.uniqueId.$error : true"
              keyName="key"
              orderBy="title"
              @select="(val) => (newItem.uniqueId = val)" />
            <SelectList
              v-if="newItem.type === `store-detail`"
              v-model="newItem.uniqueId"
              dbContext="store"
              label="Store Item"
              :state="vuelidate$.newItem.uniqueId.$dirty ? !vuelidate$.newItem.uniqueId.$error : true"
              keyName="key"
              orderBy="title"
              @select="(val) => (newItem.uniqueId = val)" />
            <SelectList
              v-if="newItem.type == 'booking'"
              v-model="newItem.uniqueId"
              dbContext="bookings"
              label="Booking Item"
              :state="vuelidate$.newItem.uniqueId.$dirty ? !vuelidate$.newItem.uniqueId.$error : true"
              orderBy="name"
              @select="(val) => (newItem.uniqueId = val)" />
            <FileList v-if="isFile" v-model="newItem.url" :state="vuelidate$.newItem.url.$dirty ? !vuelidate$.newItem.url.$error : true" />
            <div v-if="newItem.type === 'amc-events'" class="mb-3">
              <div class="form-check mb-3">
                <input id="existingCalendar" v-model="useExistingCalendar" class="form-check-input" type="checkbox" />
                <label class="form-check-label" for="existingCalendar">Use Existing Calendar</label>
              </div>
              <SelectList
                v-if="useExistingCalendar"
                v-model="existingCalendar"
                dbContext="amc-events/config"
                label="Calendar"
                orderBy="title"
                :state="vuelidate$.existingCalendar.$dirty ? !vuelidate$.existingCalendar.$error : true"
                @select="(val) => (existingCalendar = val)" />

              <div v-if="useExistingCalendar && existingCalendar" class="mb-3 mt-3">
                <div>{{ eventMessage }}</div>
                <a class="btn-link" style="cursor: pointer" @click="eventSelectModal.show()">{{
                  newItem.eventId ? 'Update Linked Event' : 'Link to Specific Event'
                }}</a>
              </div>
            </div>
            <div class="mb-3 mt-3" v-if="isFile">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="use-web-view" v-model="newItem.useWebView" />
                <label class="form-check-label" for="use-web-view">Use Web View</label>
              </div>
              <small class="form-label text-muted"
                >Use a web view to make the file open up inside the app. Otherwise the file will open in Safari/Chrome.</small
              >
            </div>
            <div id="icon" class="form-group">
              <label class="form-label" for="icon" required>Icon</label>
              <!-- <cool-select
          v-if="!getIconSet().startsWith('svg')"
          :disabled="getItemDisabled"
          v-model="newItem.icon"
          :items="filteredIcons"
          :placeholder="newItem.icon ? '' : 'Select Icon'"
          item-value="icon"
          item-text="icon"
          :error-message="vuelidate$.newItem.icon.$error ? 'Icon is required.' : null"
          :successful="vuelidate$.newItem.icon.$dirty ? !vuelidate$.newItem.icon.$error : null"
          :inputStyles="getInputStyles"
          disable-filtering-by-search
          @search="onIconSearch"
          @blur="onIconBlur">
          <template #item="{ item: icon }">
            <div :style="getBackgroundColorStyle">
              <img :src="getIconSrc(icon.icon)" class="select-icon p-2" />
            </div>
          </template>
          <template #selection="{ item: icon }">
            <div :style="getBackgroundColorStyle">
              <img :src="getIconSrc(icon.icon)" class="select-icon p-1" />
            </div>
          </template>
          <template #input-end>
            <span v-if="vuelidate$.newItem.icon.$error" style="margin-right: 8px"
              ><img
                style="width: calc(0.75em + 0.375rem); height: calc(0.75em + 0.375rem)"
                src="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'><path stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/><circle r='.5'/><circle cx='3' r='.5'/><circle cy='3' r='.5'/><circle cx='3' cy='3' r='.5'/></svg>"
            /></span>
          </template>
        </cool-select> -->
              <IconList ref="iconList" v-model="newItem.iconData" :disabled="getItemDisabled" :item-id="rightColumnKey" :title="newItem.name" />
              <Validation :validator="vuelidate$.newItem.iconData.svg" feedback="Icon is required." />
            </div>
            <div class="mb-3 mt-3">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="restricted-item" v-model="newItem.isRestricted" />
                <label class="form-check-label" for="restricted-item"> Restricted Item </label>
              </div>
              <div class="form-text">Check this box to limit access to certain user groups.</div>
            </div>
            <div class="mb-3" v-if="newItem.isRestricted">
              <label for="user-groups" class="form-label">Select User Group(s) to Receive Access</label>
              <Multiselect
                id="user-groups"
                v-model="userGroupsValue"
                :options="userGroups"
                mode="tags"
                value="value"
                placeholder="Select Group(s)"
                track-by="value"
                label="text"
                :close-on-select="true"
                :clear-on-select="false"
                hide-selected
                :class="{ 'is-invalid': vuelidate$.newItem.auth.$dirty && vuelidate$.newItem.auth.$error }"
                @input="groupsChanged" />
              <Validation :validator="vuelidate$.newItem.auth" feedback="User group is required when item is restricted." />
            </div>

            <Validation :validator="vuelidate$.newItem" map="form" />
          </div>
          <div v-if="heading === 'QR Code'">
            <QRCode
              :title="`${newItem.name} QR Code`"
              :action="newItem.type"
              :action-key="newItem.dbContext"
              :action-params="[{ key: 'title', value: newItem.name }]" />
          </div>
        </template>
      </b-accordion>
      <button class="btn m-1 btn-primary" type="submit">Submit</button>
      <button class="btn m-1 btn-secondary" type="button" @click.stop="cancelUpdate">Cancel</button>
    </form>
  </div>
</template>

<script>
import icons from '../../json/icons.json';
import { mainMenuTemplates, mainMenuTypes } from '../../json/menuTemplates.json';
// import useVuelidate from '@vuelidate/core';
import { required, requiredIf, url } from '@vuelidate/validators';
import { NonDetailFormMixin } from '../mixins/NonDetailFormMixin.js';
import SelectList from '../inputfields/SelectList';
import FileList from '../inputfields/FileList';
import IconList from '../inputfields/IconList';
import MenuTypeModal from '@/components/modals/MenuTypeModal.vue';
import Multiselect from '@vueform/multiselect';
import QRCode from '../inputfields/QRCode.vue';
import BAccordion from '../common/BAccordion.vue';
import EventSelectModal from '../modals/EventSelectModal.vue';

export default {
  components: {
    SelectList,
    FileList,
    IconList,
    Multiselect,
    MenuTypeModal,
    QRCode,
    BAccordion,
    EventSelectModal,
  },
  mixins: [NonDetailFormMixin],
  props: ['item', 'itemsList', 'rightColumnKey', 'dbContext', 'action'],
  data() {
    const menuDBContext = this.dbContext || window.localStorage.getItem('MENU_SECTION') || 'main-menu';
    let iconList = icons.filter((item) => !item.communities || item.communities.includes(this.$fbProject));
    return {
      mainMenuTypes: mainMenuTypes,
      allMenuTypes: [],
      filteredIcons: iconList,
      icons: iconList,
      filesList: [],
      menuDBContext,
      isMultipage: false,

      newItem: {
        name: '',
        icon: '',
        type: '',
        url: '',
        uniqueId: '',
        fromFile: false,
        iconData: {},
        auth: [],
        isRestricted: false,
      },
      userGroupsValue: [],
      userGroups: [],
      updatedAction: this.action.startsWith('add-') ? 'add' : this.action,
      hasContent: this.action.startsWith('add') ? false : true,
      lastName: '',
      lastId: '',
      hasAdjust: false,
      useExistingCalendar: false,
      existingCalendar: '',
      eventSelectModal: null,
      existingEvent: null,
      eventMessage: 'No event linked',
    };
  },
  computed: {
    isFile: function () {
      return this.newItem.type === 'file' || (this.newItem.fromFile && this.newItem.type === 'website');
    },
    getInputStyles: function () {
      return { 'background-color': this.getBackgroundColor };
    },
    getTitle: function () {
      return this.rightColumnKey === '' ? 'Add Menu Item' : 'Edit Menu Item';
    },
    getItemDisabled: function () {
      return (
        this.menuDBContext == 'main-menu' &&
        this.updatedAction !== 'add' &&
        this.rightColumnKey < 6 &&
        (this.$fbProject == 'campinginontario' || this.$fbProject == 'campon') &&
        !this.getResortAdminStatus()
      );
    },
    getStaticTypeName() {
      const type = this.newItem.type || this.newItem.id;
      return this.allMenuTypes.find((item) => item.id === type)?.buttonName;
    },
  },
  watch: {
    'newItem.name': function (value) {
      if (this.action === 'edit' && !this.solidTypes.includes(this.newItem.type)) {
        if (this.newItem.name === this.lastName) {
          //Restore last id if name matches initial name
          this.newItem.uniqueId = this.lastId;
        } else if (!this.hasContent) {
          //If there is no content, update the uniqueId
          this.updateType(this.newItem.type);
        }
      }
    },
  },
  validations() {
    return {
      newItem: {
        type: {
          required,
        },
        name: {
          required,
        },
        icon: {
          required: requiredIf(function () {
            return !this.getIconSet().startsWith('svg');
          }),
        },
        iconData: {
          required: requiredIf(function () {
            return this.getIconSet().startsWith('svg');
          }),
          svg: { required },
        },
        auth: {
          required: requiredIf(function () {
            return this.newItem.isRestricted;
          }),
        },
        url: {
          required: requiredIf(function () {
            return this.newItem.type == 'website' || this.newItem.type == 'file';
          }),
        },
        uniqueId: {
          required: requiredIf(function () {
            return ['business-detail', 'searchablemap-detail', 'form', 'hunts', 'store-detail', 'booking'].includes(this.newItem.type);
          }),
        },
      },
      existingCalendar: {
        required: requiredIf(function () {
          return this.useExistingCalendar;
        }),
      },
    };
  },
  created: function () {
    this.changeNewItem(true);
    this.getOriginalItem();
    this.getUserGroups();
    this.checkForContentOnEdit();
    //this.updateType(this.newItem.type);

    this.preventAIOnEdit();

    this.lastName = this.newItem.name;
    this.lastId = this.newItem.uniqueId;
    this.allMenuTypes = mainMenuTypes.reduce((acc, item) => {
      acc.push(...item.buttons);
      return acc;
    }, []);
    this.getAdjustToken().then((token) => (this.hasAdjust = token && Object.keys(token).length > 0));
  },
  mounted() {
    if (this.action === 'edit') {
      this.vuelidate$.$touch();
    } else if (this.action === 'add') {
      this.$nextTick(() => {
        this.$refs.menuTypeModal.show();
      });
    }

    if (this.action === 'edit' && this.newItem.type == 'amc-events') {
      this.useExistingCalendar = true;
      this.existingCalendar = this.newItem.dbContext;
      this.existingEvent = this.newItem.eventId;

      this.linkedEventStatus();
    }
  },
  methods: {
    openTypeModal() {
      this.$refs.menuTypeModal.show();
    },
    updateType(value) {
      if (value === null) return;
      this.newItem.uniqueId = null;
      const menuItem = mainMenuTypes.reduce((acc, item) => {
        return Object.keys(acc).length !== 0 ? acc : item.buttons.find((button) => button.id === value) || {};
      }, {});
      // If the users selects file, default to using the web view.
      const iconData = this.newItem.iconData;
      const mainMenuTemplate = {
        ...{ tabs: [], auth: [], isRestricted: false },
        ...(mainMenuTemplates.find((template) => template.id === menuItem.id) || {}),
      };

      this.newItem = {
        ...this.newItem,
        ...mainMenuTemplate,
      };

      if (iconData.title && this.action === 'edit') this.newItem.iconData = iconData;
      this.newItem.type = menuItem.id;
      if (menuItem.id == 'file') {
        this.newItem.useWebView = this.newItem.type != menuItem.id ? true : this.newItem.useWebView || false;
        this.newItem.fromFile = true;
      } else if (menuItem.id === 'website') {
        this.newItem.fromFile = false;
      } else {
        this.newItem.fromFile = null;
        this.newItem.url = null;
        this.newItem.useWebView = null;
      }
      if (menuItem.id == 'business-detail' || menuItem.id == 'searchablemap-detail') {
        this.newItem.uniqueId = this.getLocationRef().child(this.newItem.type).push().key;
      }
      this.setOrginalUserGroups();
    },
    checkForContentOnEdit() {
      if (this.updatedAction == 'add') return;
      this.hasContent = true;
      if (['file', 'form', 'hunts', 'store', 'booking', 'website'].includes(this.newItem.type)) {
        this.hasContent = false;
        return;
      }
      if (!this.newItem.dbContext) return;
      const dbRef =
        this.newItem.type === 'business-detail'
          ? this.getContextRef('business-detail').child(this.newItem.dbContext)
          : this.getContextRef(this.newItem.dbContext);
      dbRef.once('value', (snapshot) => {
        this.hasContent = snapshot.val() ? true : false;
      });
    },
    onIconSearch: function (searchText) {
      if (searchText) {
        this.filteredIcons = this.icons.filter(
          (item) => item.icon.includes(searchText) || item.synonyms.filter((syn) => syn.includes(searchText)).length
        );
      } else {
        this.filteredIcons = this.icons;
      }
    },
    onIconBlur: function () {
      this.filteredIcons = this.icons;
    },
    getUserGroups: function () {
      const loadTopics = (path) => this.getLocationRef(path).child('messages-config/topics').orderByChild('type').equalTo('user-group').once('value');
      const processTopics = (snapshot) => {
        const topics = snapshot?.val() || {};
        return Object.keys(topics).map((topicKey) => ({
          value: topicKey,
          text: topics[topicKey].title,
        }));
      };

      const userGroupBase = [
        { value: 'isEditor', text: 'App Editor' },
        { value: 'canScan', text: 'Can Scan Rewards' },
        { value: 'canNotify', text: 'Can Send Notifications' },
        { value: 'isLoggedIn', text: 'User is logged in' },
        { value: 'isLoggedOut', text: 'User is NOT logged in' },
      ];

      Promise.all([loadTopics(), loadTopics('system-data')]).then(([snapshot, snapshotSystem]) => {
        this.userGroups = [
          { value: 'isAdmin', text: 'App Admin' },
          ...processTopics(snapshot),
          ...processTopics(snapshotSystem).map((topic) => ({ ...topic, text: `${topic.text} (Systemwide Group)` })),
          ...userGroupBase,
        ];
        this.setOrginalUserGroups();
      });
    },
    groupsChanged: function (value) {
      this.$set(this.newItem, 'auth', value);
    },
    setOrginalUserGroups: function () {
      if (this.newItem.auth && this.userGroups) this.userGroupsValue = this.newItem.auth;
    },
    getOriginalItem() {
      if (this.itemsList[this.rightColumnKey]) {
        this.newItem = { ...this.itemsList[this.rightColumnKey] };
      }
      if (!this.newItem.type) {
        const trayId = this.$store.getters.params.trayId || this.action; //this.$route.params.trayId || this.action;
        this.newItem.type = trayId && trayId !== 'add-button' && trayId.startsWith('add-') ? trayId.substring(4) : this.newItem.id || null;
      }
      this.fixIdAndTypeOdditiesOnEdit();
      this.setOrginalUserGroups();
    },
    fixIdAndTypeOdditiesOnEdit() {
      if (this.newItem.type === 'hunts' && this.uniqueId === 'hunts-admin') {
        this.newItem.type = 'hunts-admin';
      }
      if (this.newItem.type === 'website' && this.newItem.fromFile) {
        this.newItem.type = 'file';
      }
      if (this.newItem.type === 'rewards-scan') {
        this.newItem.type = this.newItem.uniqueId === 'store' ? 'rewards-scan-store' : 'rewards-scan-reservations';
      }
    },
    fixIdAndTypeOdditiesOnSubmit() {
      if (this.newItem.type === 'file') {
        this.newItem.type = 'website';
      } else if (this.newItem.type.startsWith('rewards-scan')) {
        this.newItem.uniqueId = this.newItem.type === 'rewards-scan-store' ? 'store' : 'reservation';
        this.newItem.type = 'rewards-scan';
      } else if (this.newItem.type === 'form') {
        this.newItem.dbContext = this.newItem.uniqueId;
      } else if (this.newItem.type === 'amc-events') {
        this.newItem.uniqueId = this.newItem.dbContext;
      }
    },
    submitUpdate: async function () {
      this.vuelidate$.$touch();
      console.log('🚀 ~ this.vuelidate$:', this.vuelidate$);
      if (!this.vuelidate$.$invalid) {
        this.newItem.auth = this.newItem.isRestricted ? this.newItem.auth : [];
        this.newItem.icon = this.newItem.icon || 'update_app';
        this.fixIdAndTypeOdditiesOnSubmit();
        if (this.updatedAction === `add` || !this.hasContent) {
          /*if (this.newItem.type == 'business-detail' || this.newItem.type == 'searchablemap-detail') {
            this.newItem.uniqueId = this.getLocationRef().child(this.newItem.type).push().key;
          }
          */
          let newUniqueId = this.newItem.uniqueId || this.newItem.name.toLowerCase().replace(/[ /.,]/gu, '-');
          if (newUniqueId !== this.newItem.uniqueId && this.item?.type === this.newItem.type) {
            const uniqueIds = this.getGridArray();
            if (
              uniqueIds.includes(newUniqueId) ||
              [
                'ads',
                'audience',
                'business-detail',
                'config',
                'custom-forms',
                'event-printout',
                'hunts',
                'main-menu',
                'searchablemap-detail',
                'messages',
                'messages-config',
                'rewards',
                // 'staff-menu',
                'store',
              ].includes(newUniqueId)
            ) {
              newUniqueId = `${newUniqueId}-${Date.now()}`;
            }
          }
          this.newItem = {
            ...this.newItem,
            ...{
              uniqueId: newUniqueId,
              dbContext: newUniqueId,
              grid: true,
              drawer: true,
              id: 'attractions',
              activity: 'com.armstrongsoft.resortnavigator.attractions.AttractionsActivity',
            },
          };
        }
        if (this.newItem.uniqueId) {
          this.updateDB(this.getLocationRef().child(this.menuDBContext));
        } else {
          this.$message.create({
            title: 'Error',
            body: 'Missing Unique ID, please contact support.',
            classes: 'amc-modal',
            buttons: [this.$message.button('OK')],
          });
        }

        if (this.newItem.type == 'amc-events') {
          if (!this.useExistingCalendar) {
            if (this.action !== 'edit') await this.createCalendarData();
          } else {
            this.newItem.dbContext = this.existingCalendar;
            this.newItem.uniqueId = this.newItem.dbContext;

            if (this.existingEvent) {
              this.newItem.eventId = this.existingEvent;
            }
          }
        }
      } else if (this.vuelidate$.newItem.type.$error) {
        this.openTypeModal();
      }
    },
    getGridArray: function () {
      return !this.itemsList
        ? []
        : Object.keys(this.itemsList).map((item) => {
            return this.itemsList[item].uniqueId;
          });
    },
    onClick: function (evt, item) {
      if (!item.disabled) return;
      evt.stopPropagation();
    },
    getSelectItemClass: function (item) {
      return `select__item ${item.disabled ? 'disabled' : ''} ms-${item.level * 2}`;
    },
    preventAIOnEdit() {
      this.$nextTick(() => {
        if (this.action === 'edit') this.$refs.iconList.preventAISearch = true;
      });
    },
    setIdOnAdd() {
      if (['add', 'add-categorygrid', 'add-business', 'add-business-detail'].includes(this.action)) {
        this.updateType(this.newItem.type);
      }
    },

    async createCalendarData() {
      const configObj = {
        title: this.newItem.name,
        allowUserCreated: false,
        priorNotificationTime: 15,
        import: [],
        printout: {},
        'header-info': {
          images: [],
          hideTitle: false,
          delaySeconds: 5,
        },
      };

      const config = this.getLocationRef(this.campgroundKey).child('amc-events').child('config').child(this.newItem.dbContext);

      const snapshot = await config.once('value');
      if (!snapshot.exists()) {
        await config.set(configObj);
      }
    },
    async linkedEventStatus() {
      if (this.action === 'edit' && this.newItem.eventId) {
        const eventRef = await this.getLocationRef()
          .child('amc-events')
          .child('calendars')
          .child(this.newItem.dbContext)
          .child('events')
          .child(this.newItem.eventId)
          .once('value');

        if (eventRef.exists()) {
          const event = eventRef.val();
          this.eventMessage = event.title;
        } else {
          this.eventMessage = 'Event expired or deleted';
        }
      }
    },
  },
};
</script>

<style>
#icon .IZ-select__menu-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}
#icon .IZ-select__menu-items > div:first-child {
  display: none;
}
#icon .IZ-select__menu-items .IZ-select__item:hover {
  background-color: initial;
}
#icon .IZ-select__menu-items .IZ-select__item {
  padding: 0px;
}
#icon .select-icon {
  width: 100px;
  height: 100px;
  display: block;
}
#icon .select-icon.p-2:hover {
  padding: 0.2rem !important;
}

#icon .select-placeholder {
  color: #636c72;
}

#file .select__item.disabled {
  cursor: not-allowed !important;
  color: grey;
}
#file .IZ-select__item {
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
}
</style>
