<template>
  <div @click="openCalendar">
    <div style="display: flex; align-items: baseline">
      <div class="simple-title list-item" data-bs-toggle="collapse" :data-bs-target="'#' + getCollapseId">
        {{ item.title }} &nbsp;
        <i class="material-icons expand"></i>
        <!-- <i class="material-icons show" @click.stop="editItem">edit</i> -->
        <i class="material-icons show" @click.stop="openPrintout">print</i>
        <i class="material-icons show" @click.stop="openDeleteModal">delete</i>
      </div>
    </div>
    <div :id="getCollapseId" class="collapse mt-2" :class="{ show: isEditMode }">
      <div class="row">
        <div class="col"><span v-html="item.htmlDescription || item.description"></span></div>
      </div>
      <template v-if="item.detailButtons">
        <div class="row buttonRow">
          <div class="col" v-for="(button, index) in item.detailButtons" v-bind:key="index">
            <DetailButton
              :buttonText="button.title"
              :buttonIcon="button.icon"
              :svg="button.iconData ? button.iconData.svg : null"
              :action="button.action"
              :actionKey="button.actionKey" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import DetailButton from '../DetailButton.vue';
export default {
  components: { DetailButton },
  props: {
    item: Object,
    itemId: String,
    dbContext: String,
    selectedItem: String,
    view: String,
    isArchived: Boolean,
    isSystemWide: {
      type: Boolean,
      default: false,
    },
    bulkSelected: Array,
  },
  data() {
    return {
      bulkCheck: false,
    };
  },
  computed: {
    getCollapseId: function () {
      return `collapse-${this.itemId}`;
    },
    isEditMode: function () {
      return this.itemId == this.selectedItem;
    },
  },
  methods: {
    openCalendar: function () {
      this.$router.push({ name: 'amc-calendar', params: { context: this.itemId, view: 'active' } });
    },
    editItem: function () {
      this.$emit('editItem', this.itemId);
    },
    openPrintout: function () {
      this.$router.push({ name: 'activitiesPrintout', params: { calendarId: this.itemId } });
    },
    async openDeleteModal() {
      this.$message.create({
        title: 'Delete Item',
        body: 'Are you certain you want to delete this calendar and all its events?',
        buttons: [
          this.$message.button('Cancel', 'btn-secondary'),
          this.$message.button('Delete', 'btn-danger', async () => {
            const ref = this.getLocationRef().child('amc-events');
            await ref.child('calendars').child(this.itemId).remove();
            await ref.child('config').child(this.itemId).remove();

            this.$emit('deleteItem', this.itemId);
          }),
        ],
      });
    },
  },
};
</script>

<style>
.simple-title {
  flex: 0 0 97%;
}
input[type='checkbox'].bulk-select {
  width: 20px !important;
  min-width: 20px;
}
.show {
  display: inline !important;
}
.message-passed {
  background-color: red;
}
</style>
