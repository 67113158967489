<template>
  <div>
    <div>
      <img id="amc-logo" src="/amc_logo.png" alt="App My Community Logo" @click="goHome()" />
      <i class="material-icons logo-icons" @click="goHome()">home</i>
    </div>
    <div class="position-relative">
      <div class="bg-light d-flex align-items-center justify-content-center">
        <div class="two-column">
          <div id="calendar-column">
            <div class="calendar-container">
              <iframe
                v-if="params.selectedTab"
                id="printf"
                :src="getCalendarUrl"
                width="100%"
                :height="defaultHeight"
                allowfullscreen=""
                frameborder="0" />
              <div v-else><h2 class="text-center">Please select a calendar on the right.</h2></div>
            </div>
          </div>
          <div id="right-column" class="p-2">
            <div class="p-2 form-container">
              <b-accordion id="hunts" :headings="['General Information']">
                <template #body="{ heading }">
                  <div v-if="heading === 'General Information'">
                    <div class="form-group mb-3">
                      <label class="form-label">Calendar</label>
                      <select v-model="params.selectedTab" class="form-select">
                        <option value="">Select a Calendar</option>
                        <option v-for="context in contexts" :key="context.key" :value="context.value">{{ context.key }}</option>
                      </select>
                    </div>
                    <div v-if="params.selectedTab">
                      <div class="form-group">
                        <label class="form-label">Calendar Type</label>
                      </div>
                      <div class="row mb-3">
                        <div class="col">
                          <input
                            id="calendar-type-agenda"
                            v-model="params.defaultView"
                            class="form-check-input"
                            type="radio"
                            name="header-type"
                            value="listMonth" />
                          <label class="form-check-label" for="calendar-type-agenda">Agenda</label>
                        </div>
                        <div class="col">
                          <input
                            id="calendar-type-monthly"
                            v-model="params.defaultView"
                            class="form-check-input"
                            type="radio"
                            name="header-type"
                            value="dayGridMonth" />
                          <label class="form-check-label" for="calendar-type-monthly">Monthly</label>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col">
                          <label class="form-label">Background Color</label>
                          <div class="color-selector">
                            <label class="btn btn-light" @click.stop="editColorsBackground = true">
                              <div :style="`background-color:${params.colorBackground}`">&nbsp;</div>
                            </label>
                          </div>
                          <chrome-picker
                            v-show="editColorsBackground"
                            id="editColorsBackground"
                            v-model="colorBackground"
                            v-click-outside="onColorClose"
                            :data-edit="editColorsBackground" />
                          <small class="form-text text-muted">Color used for calendar header/background</small>
                        </div>
                        <div class="col">
                          <label class="form-label">Button Color</label>
                          <div class="color-selector">
                            <label class="btn btn-light" @click.stop="editColorsaccent = true">
                              <div :style="`background-color:${params.colorAccent}`">&nbsp;</div>
                            </label>
                          </div>
                          <chrome-picker v-show="editColorsaccent" id="editColorsaccent" v-model="colorAccent" v-click-outside="onColorClose" />
                          <small class="form-text text-muted">Color used for arrows</small>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col">
                          <label class="form-label">Text Color</label>
                          <div class="color-selector">
                            <label class="btn btn-light" @click.stop="editColorstext = true">
                              <div :style="`background-color:${params.colorText}`">&nbsp;</div>
                            </label>
                          </div>
                          <chrome-picker v-show="editColorstext" id="editColorstext" v-model="colorText" v-click-outside="onColorClose" />
                          <small class="form-text text-muted">Color used for event text</small>
                        </div>
                        <div v-if="params.defaultView === 'listMonth'" class="col">
                          <label class="form-label">Event Color</label>
                          <div class="color-selector">
                            <label class="btn btn-light" @click.stop="editColorsprimary = true">
                              <div :style="`background-color:${params.colorPrimary}`">&nbsp;</div>
                            </label>
                          </div>
                          <chrome-picker v-show="editColorsprimary" id="editColorsprimary" v-model="colorPrimary" v-click-outside="onColorClose" />
                          <small class="form-text text-muted">Color used for event title background</small>
                        </div>
                        <div v-else class="col">
                          <div class="mb-3">
                            <label class="form-label">Date Color</label>
                            <div class="color-selector">
                              <label class="btn btn-light" @click.stop="editColorsdate = true">
                                <div :style="`background-color:${params.colorDate}`">&nbsp;</div>
                              </label>
                            </div>
                            <chrome-picker v-show="editColorsdate" id="editColorsdate" v-model="colorDate" v-click-outside="onColorClose" />
                            <small class="form-text text-muted">Color used for date numbering</small>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col">
                          <label class="form-label">Calendar Height</label>
                          <input v-model="params.defaultHeight" type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col">
                          <div class="form-check">
                            <input id="hide-arrows" v-model="params.hideArrows" class="form-check-input" type="checkbox" />
                            <label class="form-check-label" for="hide-arrows">Hide Navigation Arrows</label>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div v-if="params.defaultView === 'listMonth'" class="col">
                          <div class="form-check">
                            <input id="expand" v-model="params.expand" class="form-check-input" type="checkbox" />
                            <label class="form-check-label" for="expand">Expand All Events to Display Descriptions</label>
                          </div>
                        </div>
                        <div v-if="params.expand" class="col">
                          <div class="form-check">
                            <input id="expand" v-model="params.isCondensed" class="form-check-input" type="checkbox" />
                            <label class="form-check-label" for="expand">Condense Descriptions to Show More Events</label>
                          </div>
                        </div>
                      </div>

                      <button class="btn m-1 btn-primary" type="submit" @click.prevent="printPage">Print Calendar</button>
                      <!---div class="row mt-3">
                <div class="col">
                  <button class="btn btn-block btn-secondary w-100" style="z-index: -99" @click="save">SAVE CONFIG</button>
                </div>
              </div--->
                      <div class="row mt-3">
                        <div class="col">
                          <nav>
                            <div class="nav nav-pills" id="nav-tab" role="tablist">
                              <button
                                class="nav-link active"
                                id="nav-embed-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-embed"
                                type="button"
                                role="tab"
                                aria-controls="nav-embed"
                                aria-selected="true">
                                Embed Code
                              </button>
                              <button
                                class="nav-link"
                                id="nav-screen-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-screen"
                                type="button"
                                role="tab"
                                aria-controls="nav-screen"
                                aria-selected="false">
                                Screen URL
                              </button>
                              <button
                                v-if="iCalURL"
                                class="nav-link"
                                id="nav-ical-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-ical"
                                type="button"
                                role="tab"
                                aria-controls="nav-ical"
                                aria-selected="false">
                                iCal URL
                              </button>
                            </div>
                          </nav>
                          <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-embed" role="tabpanel" aria-labelledby="nav-embed-tab">
                              <label class="form-label">Embed Code</label
                              ><sl-copy-button class="copy-button" error-label="URL Copied" @click="copyText(getCalendarHTML)">
                                <sl-icon slot="success-icon" library="material" name="check_round" />
                                <sl-icon slot="error-icon" library="material" name="check_round" />
                              </sl-copy-button>
                              <div>
                                <code id="embed-text"> {{ getCalendarHTML }} </code>
                              </div>
                            </div>
                            <div class="tab-pane fade" id="nav-screen" role="tabpanel" aria-labelledby="nav-screen-tab">
                              <label class="form-label">Screen URL</label
                              ><sl-copy-button class="copy-button" error-label="URL Copied" @click="copyText(getCalendarUrl)">
                                <sl-icon slot="success-icon" library="material" name="check_round" />
                                <sl-icon slot="error-icon" library="material" name="check_round" />
                              </sl-copy-button>
                              <div>
                                <code id="embed-url"> {{ getCalendarUrl }} </code>
                              </div>
                            </div>
                            <div class="tab-pane fade" id="nav-ical" role="tabpanel" aria-labelledby="nav-ical-tab">
                              <label class="form-label">iCal URL</label>
                              <sl-copy-button class="copy-button" error-label="URL Copied" @click="copyText(iCalURL)">
                                <sl-icon slot="success-icon" library="material" name="check_round" />
                                <sl-icon slot="error-icon" library="material" name="check_round" />
                              </sl-copy-button>
                              <div>
                                <code id="embed-ical"> {{ iCalURL }} </code>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </b-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SlCopyButton } from '@shoelace-style/shoelace';
import { Chrome } from 'vue-color';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { LoginMixin } from '@/components/mixins/LoginMixin';
import BAccordion from './common/BAccordion.vue';

export default {
  name: 'CalendarBuilder',
  components: {
    'chrome-picker': Chrome,
    BAccordion,
  },
  mixins: [LoginMixin],
  data() {
    return {
      editColorsBackground: false,
      editColorsaccent: false,
      editColorsprimary: false,
      editColorstext: false,
      editColorsdate: false,
      colorBackground: null,
      colorAccent: null,
      colorPrimary: null,
      colorDate: '#0d6efd',
      colorText: '#ffffff',
      defaultHeight: '898px',
      contexts: [],
      calendarType: 'listMonth',
      iCalURL: '',
      pageSize: '11in 8.5in',
      params: {
        clocation: '',
        defaultHeight: '800px',
        defaultView: 'listMonth',
        colorBackground: '',
        colorAccent: '',
        colorPrimary: '',
        colorText: '#ffffff',
        colorDate: '#0d6efd',
        selectedTab: '',
        hideArrows: false,
        expand: false,
        isCondensed: false,
      },
    };
  },
  computed: {
    getCalendarUrl() {
      return `${this.$hostname}/calendar?${new URLSearchParams(this.params).toString()}`;
    },
    getCalendarHTML() {
      return `<iframe src="${this.getCalendarUrl}" width="100%" height="${this.params.defaultHeight}" allowfullscreen="" frameborder="0"></iframe>`;
    },
  },
  watch: {
    colorBackground: function () {
      this.params.colorBackground = this.colorBackground.hex || this.params.colorBackground;
    },
    colorPrimary: function () {
      this.params.colorPrimary = this.colorPrimary.hex || this.params.colorPrimary;
    },
    colorAccent: function () {
      this.params.colorAccent = this.colorAccent.hex || this.params.colorAccent;
    },
    colorText: function () {
      this.params.colorText = this.colorText.hex || this.params.colorText;
    },
    colorDate: function () {
      this.params.colorDate = this.colorDate.hex || this.params.colorDate;
    },
    'params.selectedTab': async function () {
      let storageRef = firebase.storage().ref(this.getCampgroundKey).child('amc-events').child(`${this.params.selectedTab}-calendar.ics`);
      try {
        this.iCalURL = await storageRef.getDownloadURL();
      } catch (error) {
        this.iCalURL = '';
      }
    },
  },
  created() {
    this.params.clocation = this.getCampgroundKey;
    this.colorBackground = this.getBackgroundColor;
    this.colorAccent = this.getAccentColor;
    this.colorPrimary = this.getPrimaryColor;
    this.params.colorBackground = this.getBackgroundColor;
    this.params.colorAccent = this.getAccentColor;
    this.params.colorPrimary = this.getPrimaryColor;
    const promises = [];
    promises.push(this.getContextRef('events-resort').once('value'));
    promises.push(this.getContextRef('events-local').once('value'));
    promises.push(this.getContextRef('amc-events').child('config').orderByChild('title').once('value'));
    Promise.all(promises).then((snapshots) => {
      const [eventsResortSnap, eventsLocalSnap, amcEventsSnap] = snapshots;
      if (amcEventsSnap.hasChildren()) {
        amcEventsSnap.forEach((childSnap) => {
          this.contexts.push({ key: childSnap.val().title, value: childSnap.key });
        });
      }
      const legacyEventsDescriptor = this.contexts.length === 0 ? 'Activities' : 'Legacy Events';

      if (eventsResortSnap.hasChildren()) {
        this.contexts.push({ key: `${legacyEventsDescriptor} Tab 1`, value: 'events-resort' });
      }
      if (eventsLocalSnap.hasChildren()) {
        this.contexts.push({ key: `${legacyEventsDescriptor} Tab 2`, value: 'events-local' });
      }
    });
  },
  methods: {
    onColorClose: function () {
      const editColors = ['editColorsBackground', 'editColorsaccent', 'editColorsprimary', 'editColorstext', 'editColorsdate'];
      editColors.forEach((key) => {
        this[key] = false;
      });
    },
    copyText: function (text) {
      this.$copyText(text);
    },
    printPage: function () {
      let frm = document.getElementById('printf').contentWindow;
      frm.focus(); // focus on contentWindow is needed on some ie versions
      frm.print();
    },
    save() {
      console.log(this.pageData);
    },
  },
};
</script>
<style scoped>
@media print {
  @page {
    size: v-bind(pageSize);
  }
}
div.two-column {
  width: 100%;
  display: grid;
  grid-template-columns: 0.75fr 0.25fr;
}
.color-selector div {
  width: 20px;
  height: 20px;
}
.color-selector .btn {
  padding: 0.25rem 0.25rem;
}
.vc-chrome {
  width: 175px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}
</style>
